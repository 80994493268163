import React from 'react'
import PropTypes from 'prop-types'
// import Aza from '../../assets/images/logos/azamara-logo-2.png'
import Royal from '../../assets/images/logos/rccl-logo.png'
import Celeb from '../../assets/images/logos/celebrity-logo.png'
import brands from '../../configuration/brands'
import '../../styles/scss/structure/logos.scss'

function renderHeaderImages(activeBrandData) {
  if (!activeBrandData) {
    return (
      <>
        {/*<img src={Aza} alt="azamara" className="tri-brand-image" />*/}
        <img src={Celeb} alt="celebrity" className="tri-brand-image" />
        <img src={Royal} alt="Royal" className="tri-brand-image" />
      </>
    )
  }

  return activeBrandData &&
    brands[activeBrandData.brand_code] &&
    brands[activeBrandData.brand_code].logo ? (
    <img
      className={activeBrandData.website && 'brand-link'}
      src={brands[activeBrandData.brand_code].logo}
      alt={activeBrandData.name}
      onClick={
        activeBrandData.website
          ? () => window.open(activeBrandData.website)
          : null
      }
    />
  ) : null
}

const Logos = ({ activeBrand, placeAbsolute }) => (
  <div
    className={`logos-container u-flex u-flex-justify-end ${
      placeAbsolute ? 'absolute' : ''
    }`}
  >
    {renderHeaderImages(activeBrand)}
  </div>
)

Logos.defaultProps = {
  activeBrandData: null
}

Logos.propTypes = {
  activeBrandData: PropTypes.shape({
    brand_code: PropTypes.string,
    name: PropTypes.string,
    website: PropTypes.string
  })
}

export default Logos

export default {
  date: 'YYYY-MM-DD',
  displayDateCondensed: 'DDMMMYYYY',
  displayDateShort: 'DD MMM YYYY',
  displayDateMedium: 'DD-MMM YYYY',
  datePickerDisplayDate: 'MM/DD/YYYY',
  spanishDatePickerDisplayDate: 'DD/MM/YYYY',
  itineraryDisplayDate: 'ddd D-MMM YYYY',
  guestLabels: ['One', 'Two', 'Three', 'Four'],
  preferenceLabels: ['First', 'Second', 'Third']
}
/*
 * Sorry about this, they just kept adding and changing what display dates they wanted.
 * */

import { getBrands } from '../../api/api'
import formatBrands from '../../utilities/formatBrands'

export const REQUEST_BRANDS = 'REQUEST_BRANDS'
export const RECEIVE_BRANDS = 'RECEIVE_BRANDS'
export const BRANDS_ERROR = 'BRANDS_ERROR'

export const fetchBrands = () => dispatch => {
  dispatch({ type: REQUEST_BRANDS })

  getBrands()
    .then(res => {
      dispatch({
        type: RECEIVE_BRANDS,
        payload: formatBrands(res.data)
      })
    })
    .catch(() => {
      dispatch({ type: BRANDS_ERROR })
    })
}

export const appDataIsLoading = state =>
  state.brands.loading ||
  state.ships.loading ||
  state.ssoData.loading ||
  state.config.loading

import formatImages from './formatImages'

export default function(ships) {
  return ships
    .sort(function(a, b) {
      return a.name.localeCompare(b.name)
    })
    .map(ship => {
      return {
        ...ship,
        image: formatImages(ship.image, ['details-hero', 'gallery-views'])
      }
    })
}

import React from 'react'
import Modal from '../elements/Modal'
import '../../styles/scss/components/forfeit-modal.scss'

const ForfeitModal = props => {
  const { isOpen, cancel, accept } = props

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => {}}
      hideCloseIcon
      className="forfeit-modal"
    >
      <div className="forfeit-modal-content">
        <div className="forfeit-modal-text">
          <h3 className="u-text-center u-base-font">
            DECLINE 125% FUTURE CRUISE CREDIT for CRUISE FARE REFUND
          </h3>
          <div>
            <p>
              By submitting this form, you understand and agree that, on behalf
              of yourself and all of the other guests in the reservation
              (collectively, “You” or “Your”), You are declining the 125% Future
              Cruise Credit offered and requesting a refund of Your cruise fare
              instead.
            </p>
            <p>
              Additionally, You acknowledge that the offer of a 125% Future
              Cruise Credit is available only to those guests who were booked on
              a cruise we cancelled due to global suspension of cruising, and it
              is not available to You if You: (i) cancelled Your reservation
              before we cancelled the cruise (whether you cancelled under
              “Cruise with Confidence” or not); or (ii) requested a Future
              Cruise Credit, or to “Lift and Shift” Your reservation, under the
              ‘Cruise with Confidence’ program.
            </p>
            <p>
              You also acknowledge that this refund offer is available only to
              guests who were booked on a cruise we cancelled due to
              global suspension of cruising. It is not available to You if You:
              (i) cancelled Your reservation before we cancelled the cruise,
              whether you cancelled under “Cruise with Confidence” or not
              (depending on when you cancelled, an exception may apply for
              cancelled cruises originally scheduled to embark between November
              1, 2020 and December 31, 2022; or (ii) requested to “Lift and
              Shift” Your reservation, under the ‘Cruise with Confidence’
              program.
            </p>
          </div>
        </div>
        <div className="forfeit-modal-buttons-container">
          <div className={`forfeit-modal-button-container`}>
            <button
              className="btn btn-primary"
              onClick={accept}
              data-testid="forfeit-modal-accept-button"
            >
              I AGREE
            </button>
            <p>Continue to process the refund request.</p>
          </div>
          <div className="forfeit-modal-button-container">
            <button className="btn btn-secondary" onClick={cancel}>
              CANCEL
            </button>
            <p>
              I’ve changed my mind and would like to accept the 125% Future
              Cruise Credit offer.
            </p>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ForfeitModal

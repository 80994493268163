import {
  FETCH_CANCELLATION_TYPE,
  RECEIVE_CANCELLATION_TYPE,
} from './fetchCancellationType'
import { getAgencyId } from '../reducers/ssoData'
import { getFccData } from '../../api/api'

export const fetchFccData = ({ fccNumber, firstName, lastName }) => (
  dispatch,
  getState,
) => {
  dispatch({
    type: FETCH_CANCELLATION_TYPE,
  })

  const params = new URLSearchParams()

  params.append('sessionId', getState().sessionId)
  params.append('agencyId', getAgencyId(getState()))
  params.append('issuedFirstName', firstName)
  params.append('issuedLastName', lastName)

  return getFccData({ id: fccNumber, paramString: params.toString() })
    .then(res => {
      const { data } = res
      if (!data?.issuedReservationId) {
        return null
      }
      dispatch({
        type: RECEIVE_CANCELLATION_TYPE,
        payload: data,
      })

      return data
    })
    .catch(err => {
      throw new Error(
        err?.response?.data?.['hydra:description'] ||
          err?.message ||
          'Unable to fetch fcc data',
      )
    })
}

export default {
  brandData: {
    name: 'Pullmantur Cruises',
    brand_code: 'P',
    website: 'https://www.pullmantur.travel/'
  },
  ships: [
    {
      name: 'Monarch',
      ship_code: 'MO',
      brand_code: 'P'
    },
    {
      name: 'Sovereign',
      ship_code: 'SO',
      brand_code: 'P'
    },
    {
      name: 'Horizon',
      ship_code: 'HR',
      brand_code: 'P'
    },
    {
      name: 'Other',
      ship_code: 'PG',
      brand_code: 'P'
    }
    // {
    //   name: 'Zenith',
    //   ship_code: 'ZE',
    //   brand_code: 'P',
    // }
  ]
}

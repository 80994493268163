import React from 'react'
import SelectionCardContainer from '../../components/selectionCards/SelectionCardContainer'
import CancelFooter from '../../components/structure/CancelFooter'

import '../../styles/scss/views/program-selection-view.scss'

import rightArrow from '../../assets/icons/arrow-right.svg'

const ProgramSelection = ({
  handleRouteChange,
  isActive,
  resetFlow,
  isAuthenticated,
}) => (
  <>
    <div
      className={`program-selection-view u-no-print ${
        isActive ? 'active' : ''
      }`}
    >
      <div className="program-selection-header">
        <h1>PARTNER EXPRESS</h1>

        {isAuthenticated ? (
          <>
            <p>
              Looking to give your clients flexibility in their plans? We get
              it. Whether they want to take advantage of a better rate for their
              upcoming sailing or purchase an onboard credit, we have developed
              tools to assist you in helping with all of their needs.
            </p>
            <p className="u-bold">
              Review the various options below and choose the one that provides
              the flexibility they're looking for.
            </p>
          </>
        ) : (
          <>
            <p>
              Looking for flexibility in your travel plans? We get it. Whether
              you need to cancel your cruise, take advantage of our COVID
              Assistance policy, or transfer your Future Cruise Credit to
              someone else, we have implemented programs to assist you with all
              of your needs.
            </p>
            <p className="u-bold">
              Review the various options below, and choose the program that
              provides the flexibility you're looking for.
            </p>
          </>
        )}

        <div className="step-indicator">
          <p>Choose an Option</p>
        </div>
      </div>
      <SelectionCardContainer handleRouteChange={handleRouteChange} />
      <CancelFooter />
    </div>
    {!isActive && (
      <div className="program-selection-reset u-no-print">
        <button onClick={resetFlow} className="btn">
          <div className="u-flex choose-box">
            <p>Choose an Option</p>
            <img src={rightArrow} alt="Choose a Program" />
          </div>
        </button>
      </div>
    )}
  </>
)

export default ProgramSelection

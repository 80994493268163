import React from 'react'
import { useSelector } from 'react-redux'
import { getActiveShipsData } from '../../redux/reducers/activeSearchFilters'
import generateFormData from './utils'
import ReservationForm from './ReservationForm'

const DirectReservationForm = ({ submitForm, disabled, selectedProgram }) => {
  const shipsData = useSelector(state => getActiveShipsData(state))
  const {
    initialValues,
    fields,
    schema,
    shouldDisableSubmit,
  } = generateFormData({
    selectedProgram,
    isAuthenticated: false,
    options: {
      ships: shipsData,
    },
  })

  function handleSubmit(values) {
    submitForm(values, 'direct')
  }

  return (
    <ReservationForm
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      schema={schema}
      shouldDisableSubmit={shouldDisableSubmit}
      selectedProgram={selectedProgram}
      testId="direct-form"
      fields={fields}
      disabled={disabled}
    />
  )
}

export default DirectReservationForm

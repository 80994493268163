export const SET_ACTIVE_SEARCH_BRAND = 'SET_ACTIVE_SEARCH_BRAND'
export const SET_ACTIVE_CANCELLATION_TYPE = 'SET_ACTIVE_CANCELLATION_TYPE'
export const SET_ACTIVE_LANGUAGE = 'SET_ACTIVE_LANGUAGE'
export const SET_PRE_SELECTED_PROGRAM = 'SET_PRE_SELECTED_PROGRAM'
export const setActiveBrand = brand => dispatch => {
  dispatch({
    type: SET_ACTIVE_SEARCH_BRAND,
    payload: brand
  })
}

export const setPreSelectedProgram = payload => dispatch => {
  dispatch({
    type: SET_PRE_SELECTED_PROGRAM,
    payload
  })
}

export const setActiveSearchFilters = queryParams => dispatch => {
  const { brand, lang } = queryParams

  if (
    brand &&
    (brand === 'Z' || brand === 'C' || brand === 'R' || brand === 'P')
  ) {
    dispatch(setActiveBrand(brand.toUpperCase()))
    if (brand === 'P') {
      dispatch(setActiveLanguage(lang ? lang : 'es'))
    }
  }
}

export const setActiveLanguage = payload => dispatch => {
  dispatch({
    type: SET_ACTIVE_LANGUAGE,
    payload
  })
}

import {
  FETCH_CANCELLATION_TYPE,
  RECEIVE_CANCELLATION_TYPE,
} from './fetchCancellationType'

import { getObcBooking } from '../../api/api'
import { getAgencyId } from '../reducers/ssoData'
import formatGuestName from '../../utilities/formatGuestName'
import releaseActiveBooking from './releaseActiveBooking'

export const fetchObcBooking = ({ id }) => (dispatch, getState) => {
  dispatch({
    type: FETCH_CANCELLATION_TYPE,
  })

  const params = new URLSearchParams()

  params.append('sessionId', getState().sessionId)
  params.append('agencyId', getAgencyId(getState()))
  return getObcBooking({ id, params })
    .then(res => {
      const data = res?.data

      const errorMessage = res?.data?.['hydra:member']?.[0]?.messages?.[0]?.text

      if (errorMessage || !data?.applicableOnBoardCredit) {
        throw new Error(errorMessage || 'Error retrieving booking')
      }

      if (!data?.applicableOnBoardCredit?.length) {
        dispatch(releaseActiveBooking(id))
        throw new Error('No applicable On Board Credits found')
      }

      const result = {
        ...data,
        guests:
          data?.guests?.map(guest => {
            const currentActiveCredits =
              data?.appliedOnBoardCreditsOnBooking?.filter(
                purchse =>
                  purchse.guestID === guest.id && purchse.optionStatus !== 'X',
              ) || []
            const currentActiveCreditTotal = currentActiveCredits.reduce(
              (num, purchase) => {
                return num + purchase.obcAmountInBookingCurrency || 0
              },
              0,
            )
            return {
              ...guest,
              displayName: formatGuestName(guest),
              currentActiveCredits,
              currentActiveCreditTotal,
            }
          }) || [],
        id,
        applicableOnBoardCredit: data?.applicableOnBoardCredit?.[0],
        appliedOnBoardCreditsOnBooking:
          data?.appliedOnBoardCreditsOnBooking?.map((purchase, i) => ({
            ...purchase,
            key: `${purchase.guestID}-${purchase.transactionReferenceNumber}-${i}`,
          })) || [],
      }

      dispatch({
        type: RECEIVE_CANCELLATION_TYPE,
        payload: result,
      })

      return result
    })
    .catch(err => {
      const message =
        err?.response?.data?.['hydra:description'] ||
        err?.message ||
        'Unable to fetch fcc data'
      if (message?.includes('BK')) {
        dispatch(releaseActiveBooking(id))
      }
      throw new Error(message)
    })
}

import axios from 'axios'
// import { generateMockResponse } from './__mocks__/dummyResponses/mockCancelationResponses'
// import dummyFCCResponse from './__mocks__/dummyResponses/dummyFCCResponse'

const endpoints = {
  brands: process.env.REACT_APP_CONTENT_ENDPOINT + 'brands',
  config: process.env.REACT_APP_BASE_ENDPOINT_URL + 'config',
  labels: process.env.REACT_APP_CONTENT_ENDPOINT + 'labels/eQuote',
  ships: process.env.REACT_APP_CONTENT_ENDPOINT + 'ships',
  sso: process.env.REACT_APP_SSO_ENDPOINT + 'agent/',
  getCancellationType: process.env.REACT_APP_SSO_ENDPOINT + 'cancellation/',
  covidCancellations:
    process.env.REACT_APP_SSO_ENDPOINT + 'covid-cancellations',
  cancellation: process.env.REACT_APP_SSO_ENDPOINT + 'cancellation',
  itinerary: process.env.REACT_APP_SSO_ENDPOINT + 'cruise/',
  liftAndShiftOptions:
    process.env.REACT_APP_SSO_ENDPOINT + 'cancellation-sailings/',
  ffc: `${process.env.REACT_APP_SSO_ENDPOINT}fccs/`,
  fccTransfer: `${process.env.REACT_APP_SSO_ENDPOINT}fccs/transfer`,
  obc: `${process.env.REACT_APP_SSO_ENDPOINT}obc`,
}

export const configuration = () => {
  return axios.get(endpoints.config)
}

export const getBrands = () => {
  return axios.get(endpoints.brands)
}
export const getShips = () => {
  return axios.get(endpoints.ships)
}
export const getLabels = () => {
  return axios.get(endpoints.labels)
}

export const sso = url => {
  return axios.get(url)
}

// used for the original 4 programs. cancel, bpg, lift and shift (retired), request a refund (retired)
export const getCancellationType = url => {
  return axios.get(endpoints.getCancellationType + url)
}

// used for the original 4 programs. cancel, bpg, lift and shift (retired), request a refund (retired)
export const postCancellation = params => {
  return axios.post(endpoints.cancellation, { createParameters: params })
}

// cancel - covid
export const getCovidCancellation = url => {
  return axios.get(`${endpoints.covidCancellations}/${url}`)
}
// cancel - covid
export const postCovidCancellation = params => {
  return axios.post(endpoints.covidCancellations, params, {
    headers: {
      'content-type': 'application/ld+json',
    },
  })
}

export const getLiftAndShiftOptions = (id, params) => {
  return axios.get(`${endpoints.liftAndShiftOptions}${id}?${params}`)
}

// used on bpg
export const releaseBooking = params => {
  axios.get(endpoints.cancellation + params)
}

// lift and shift
export const getItinerary = params => {
  return axios.get(endpoints.itinerary + params)
}

export const getFccData = ({ id, paramString }) =>
  axios.get(`${endpoints.ffc}${id}?${paramString}`)

export const getObcBooking = ({ id, params }) =>
  axios.get(`${endpoints.obc}/booking/${id}?${params.toString()}`)

export const postObcPurchase = params =>
  axios.post(`${endpoints.obc}/purchase`, params, {
    headers: {
      'Content-Type': 'application/ld+json',
      Accept: 'application/ld+json',
    },
  })

export const postFccTransfer = ({
  id,
  issuedFirstName,
  issuedLastName,
  transferredFirstName,
  transferredLastName,
  agentEmail,
  bookingId,
  issuedEmailAddress,
}) =>
  axios.post(
    endpoints.fccTransfer,
    {
      id,
      issuedFirstName,
      issuedLastName,
      transferredFirstName,
      transferredLastName,
      agentEmail,
      bookingId,
      issuedEmailAddress,
    },
    {
      headers: {
        'content-type': 'application/ld+json',
      },
    },
  )

export default endpoints

import React from 'react'
import arrowRight from '../../assets/icons/arrow-right.svg'

const SelectionCard = ({ option, submit }) => {
  const {
    title,
    subTitle,
    description,
    icon,
    value,
    pageTitle,
    pageSubTitle,
  } = option

  const paragraphs = description?.length ? description.split('\n') : []

  return (
    <div className="selection-card" data-testid={option.testId || ''}>
      <div className={`selection-card-icon-container`}>
        <img src={icon} alt={title} />
      </div>

      <div className={`selection-card-body`}>
        <span className={`selection-card-title`}>
          <span className="u-text-white card-title u-uppercase">{title}</span>
          {subTitle ? (
            <>
              <br />
              <span className="u-text-white selection-card-subtitle u-uppercase">
                {subTitle}
              </span>
            </>
          ) : null}
          <span className="selection-card-popup-container">
            <button type="button" className="question-icon" />
            <div className="selection-card-tooltip">
              <p className="tooltip-title u-brand-blue">{pageTitle || title}</p>
              {pageSubTitle || subTitle ? (
                <p className="tooltip-title u-brand-blue">
                  {pageSubTitle || subTitle}
                </p>
              ) : null}
              <br />
              {paragraphs.map(paragraph => (
                <p key={paragraph} className="tooltip-description">
                  {paragraph}
                </p>
              ))}
            </div>
          </span>
        </span>
      </div>
      <div className="selection-card-button">
        <button
          className="btn selection-card-btn"
          onClick={() => submit(option)}
          data-testid={`${value}-selection-card-submit`}
        >
          <div className="selection-card-btn--inner">
            <p className="u-bold select-card-text">Select</p>
            <img src={arrowRight} alt="Choose a Program" />
          </div>
        </button>
      </div>
    </div>
  )
}

export default SelectionCard

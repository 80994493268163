import React from 'react'
import { useSelector } from 'react-redux'

import peopleIcon from '../../assets/icons/people-icon.svg'
import { ListItem } from './ReviewItemsList'
import { getIsSpanish } from '../../redux/reducers/activeSearchFilters'

export const guestCount = ['One', 'Two', 'Three', 'Four']
export const spanishGuestCount = ['Primer', 'Segundo', 'Tercer', 'Cuarto']

const GuestDetails = ({ guests }) => {
  const isSpanish = useSelector(state => getIsSpanish(state))

  if (!guests) return null

  return (
    <div className="review-reservation-details-detail-section">
      <div className="u-icon-label">
        <img src={peopleIcon} alt="Guest Information" />
        <p className="u-bold u-brand-blue">
          {isSpanish ? 'INFORMACIÓN DE LOS HUÉSPEDES' : 'Guest Information'}
        </p>
      </div>

      {guests && guests.length
        ? guests.map((guest, i) => (
            <div key={`passenger-details-${i + 1}-${guest.givenName}`}>
              <ListItem
                item={{
                  label: isSpanish
                    ? `${spanishGuestCount[i]} huésped:`
                    : `Guest ${guestCount[i]}`,
                  value: `${guest.givenName} ${guest.surname}`
                }}
                borderBottom={Boolean(i + 1 < guests.length)}
                blue
              />
            </div>
          ))
        : null}
    </div>
  )
}

export default GuestDetails

import React from 'react'
import PropTypes from 'prop-types'

import routes from '../../configuration/routes'

import SelectionCard from './SelectionCard'

import '../../styles/scss/components/selection-cards.scss'

const SelectionCards = ({ setSelectedProgram, options, handleRouteChange }) => {
  const submit = option => {
    setSelectedProgram(option)
    handleRouteChange(routes.cancellationForm)
  }

  return (
    <div className={`selection-cards `} data-testid="selection-cards-container">
      {options.map(option => {
        if (option.hidden) {
          return null
        }

        return (
          <SelectionCard
            key={option.value}
            submit={() => submit(option)}
            option={option}
          />
        )
      })}
    </div>
  )
}

SelectionCards.propTypes = {
  setSelectedProgram: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      icon: PropTypes.string,
      labelText: PropTypes.string,
      selectedLabelText: PropTypes.string,
      disabled: PropTypes.bool,
    }),
  ),
  currentBrandCode: PropTypes.string,
}

export default SelectionCards

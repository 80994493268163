import React from 'react'
import PropTypes from 'prop-types'
import '../../styles/scss/components/lift-confirmation.scss'
import reviewIcon from '../../assets/icons/clipboard.svg'
import { ListItem } from '../reservationReview/ReviewItemsList'
import LiftAndShiftPreferences from '../liftAndShiftComponents/LiftAndShiftPrefrences'
import { useSelector } from 'react-redux'
import ConfirmationCTAs from './ConfirmationCTAs'

const LiftConfirmation = ({
  isAuthenticated,
  cancellationData,
  resetFlow,
  activeBrandCode
}) => {
  const liftAndShiftSelections = useSelector(
    state => state.liftAndShiftSelections
  )

  let stateRoomName
  if (cancellationData && cancellationData.booking) {
    stateRoomName = cancellationData.booking.berthedCategoryName
      ? cancellationData.booking.berthedCategoryName
      : cancellationData.booking.berthedCategoryCode
  }
  const id = cancellationData ? cancellationData.id : null
  return (
    <div
      className="u-max-medium-container lift-confirmation u-background-white"
      data-testid="lift-confirmation"
    >
      <h1 className="u-bold u-text-center">You're All Set!</h1>
      <div className="lift-confirmation-copy">
        {isAuthenticated ? (
          <>
            <p>
              {id ? (
                <span>
                  Your Lift and Shift request has been submitted for{' '}
                  <span className="u-bold">
                    Booking ID:
                    {id}
                  </span>
                  .
                </span>
              ) : (
                ''
              )}{' '}
              No additional action is needed on your part! No need to call our
              contact centers, we've got you covered! You'll receive an e-mailed
              copy of your request as a confirmation of receipt shortly.
            </p>
            <p>
              After reviewing the sailing preferences you've selected, we will
              Lift and Shift your clients’ current reservation and send a
              confirmation of the new sailing details within 72 hours. Please
              note, Taxes & Fees will be adjusted to reflect the amount
              associated with the new Lift & Shift sailing. Taxes & Fees from
              the original sailing will not be protected.
            </p>
            <p>
              We look forward to welcoming your clients onboard in the near
              future! Thank you for your support and loyalty.
            </p>
          </>
        ) : activeBrandCode && activeBrandCode === 'C' ? (
          <>
            <p>
              {id ? (
                <span>
                  Your Lift and Shift request has been submitted for{' '}
                  <span className="u-bold">
                    Booking ID:
                    {id}
                  </span>
                  .
                </span>
              ) : (
                ''
              )}{' '}
              No additional action is needed on your part. No need to call our
              contact centers, we've got you covered. You'll receive an email
              copy of your request as a confirmation of receipt shortly.
            </p>
            <p>
              After reviewing the sailing preferences you've selected, we will
              Lift and Shift your current reservation and send a confirmation of
              the new sailing details within 72 hours. Please note, Taxes & Fees
              will be adjusted to reflect the amount associated with the new
              Lift & Shift sailing. Taxes & Fees from the original sailing will
              not be protected.
            </p>
            <p>
              We look forward to welcoming you onboard in the near future. Thank
              you for your support and loyalty.
            </p>
          </>
        ) : (
          <>
            <p>
              {id ? (
                <span>
                  Your Lift and Shift request has been submitted for{' '}
                  <span className="u-bold">
                    Booking ID:
                    {id}
                  </span>
                  .
                </span>
              ) : (
                ''
              )}{' '}
              No additional action is needed on your part! No need to call our
              contact centers, we've got you covered! You'll receive an e-mailed
              copy of your request as a confirmation of receipt shortly.
            </p>
            <p>
              After reviewing the sailing preferences you've selected, we will
              Lift and Shift your current reservation and send a confirmation of
              the new sailing details within 72 hours. Please note, Taxes & Fees
              will be adjusted to reflect the amount associated with the new
              Lift & Shift sailing. Taxes & Fees from the original sailing will
              not be protected.
            </p>
            <p>
              We look forward to welcoming you onboard in the near future! Thank
              you for your support and loyalty.
            </p>
          </>
        )}
      </div>
      <div className="u-max-text-container ">
        <div className="u-border u-background-white lift-confirmation-card">
          <div className="lift-confirmation-card-details">
            <div className="u-icon-label">
              <img src={reviewIcon} alt="Itinerary Details" />
              <p className="u-brand-blue u-bold">Itinerary Details</p>
            </div>
            {stateRoomName && (
              <ListItem
                borderBottom
                item={{ label: 'Category', value: stateRoomName }}
              />
            )}

            <ListItem
              item={{
                label: 'Stateroom',
                value: 'Best Available*'
              }}
              subText={
                '*We will select the “Best Available” stateroom based on your booked category.'
              }
            />

            <div className="u-background-light u-border lift-confirmation-preferences-list">
              <LiftAndShiftPreferences
                disabled={true}
                disableModal={true}
                bookingData={cancellationData}
                selections={liftAndShiftSelections.reduce(
                  (obj, selection, i) => {
                    obj[i + 1] = {
                      ship: selection.shipCode,
                      date: selection.startDate,
                      package: selection.packageCode
                    }
                    return obj
                  },
                  {}
                )}
              />
            </div>
          </div>
          <ConfirmationCTAs resetFlow={resetFlow} />
        </div>
      </div>
    </div>
  )
}

LiftConfirmation.defaultProps = {
  isAuthenticated: false
}

LiftConfirmation.propTypes = {
  isAuthenticated: PropTypes.bool,
  cancellationData: PropTypes.shape({
    id: PropTypes.string,
    booking: PropTypes.shape({
      berthedCategoryName: PropTypes.string
    })
  }),
  resetFlow: PropTypes.func.isRequired,
  activeBrandCode: PropTypes.string
}

export default LiftConfirmation

import React from 'react'

const CovidCelebrityPolicy = () => (
  <>
    <div>
      <p className="u-margin-bottom-32">
        The following information applies to residents of North America who book
        passage on Celebrity Cruises (“Celebrity” or the “cruise line”) sailings
        scheduled during the nationally declared Public Health Emergency
        involving COVID-19. Except as specifically stated below, or as otherwise
        provided in the Cruise/CruiseTour Ticket Contract (the “Ticket
        Contract”) for your cruise, the standard cancellation policies and
        penalties described in the Ticket Contract apply. The below updated
        policies are effective from August 17, 2022 (the “Effective Date”) and,
        except as otherwise noted, apply to all bookings of Celebrity cruises
        scheduled to sail between the Effective Date and April 30, 2023,
        inclusive.
      </p>
      <p>
        <strong>CRUISE CANCELLED BY CELEBRITY</strong>
      </p>
      <p className="u-margin-bottom-32">
        If Celebrity cancels your cruise or boarding is delayed by three (3)
        days or more and you elect not to embark on the delayed cruise or a
        substitute cruise offered by Celebrity, you will be entitled to a refund
        of the cruise fare paid to Celebrity, or an optional Future Cruise
        Credit (“FCC”) if Celebrity offers you a FCC in lieu of a refund.
      </p>
      <p>
        <strong>CRUISE BOOKING CANCELLED BY GUEST* </strong>
      </p>
      <ul>
        <li className="u-margin-bottom-32">
          If you cancel a cruise booking due to you, or someone else in your
          Traveling Party, testing positive for COVID-19 within 10 days before
          embarkation, you, and anyone else in your Traveling Party who cancels,
          are eligible for a refund of the cruise fare paid to Celebrity. To
          qualify for a refund, if your COVID-19 test was administered by a
          provider other than one retained by Celebrity, you must present your
          verified positive test result in a form acceptable to Celebrity.
        </li>
        <li className="u-margin-bottom-32">
          If you had a confirmed close contact with a person who tested positive
          for COVID-19, or who is suspected of having COVID-19, within 10 days
          before embarkation and Celebrity deems you are unfit to travel, you
          are eligible for a refund of the cruise fare paid to Celebrity.
        </li>
        <li className="u-margin-bottom-32">
          If we determine that you may have been exposed to or are likely to
          have been infected by COVID-19 within 10 days before embarkation, we
          may require that you and others living with you in the same household
          do not travel to the departure port. This is in order to prevent the
          transmission of COVID-19. Anyone effectively denied boarding in these
          circumstances will be entitled to a refund for the cruise fare paid to
          Celebrity.
        </li>
      </ul>
      <p>
        <strong>
          FOR CELEBRITY CRUISES BOOKED PRIOR TO THE EFFECTIVE DATE ON SAILINGS
          SCHEDULED TO SAIL ON OR BEFORE NOVEMBER 30, 2022
        </strong>
      </p>
      <p>
        <u>
          Denial of Embarkation or Reboarding; Quarantine and/or Disembarkation
        </u>
      </p>
      <ul>
        <li className="u-margin-bottom-32">
          If you or members of your Traveling Party, or other close contacts are
          denied embarkation or reboarding, or quarantined or disembarked during
          your cruise, due to a positive COVID-19 test or being suspected of
          having COVID-19, you and they are entitled to a refund for the cruise
          fare paid to Celebrity in the event of denial at embarkation, or a
          pro-rated refund for the unused portion of your cruise fare in all
          other cases.
        </li>
        <li className="u-margin-bottom-32">
          For all Celebrity cruises scheduled to sail from the European Union or
          the United Kingdom on or before November 30, 2022, if you test
          positive for COVID-19 during your cruise, Celebrity will:
          <ul>
            <li className="u-margin-bottom-32">
              cover the cost of necessary COVID-19 related medical treatment
              onboard the ship;
            </li>
            <li className="u-margin-bottom-32">
              you and members of your Traveling Party may stay onboard the ship
              to complete any required quarantine period, without needing to pay
              additional cruise fare, stateroom gratuities or taxes/fees to
              Celebrity for the same; and
            </li>
            <li className="u-margin-bottom-32">
              If you purchased flights through Celebrity, Celebrity will
              coordinate the travel arrangements, and cover the airline change
              fees and any difference in the airfare for the same class of
              service, necessary to fly you back to the city of your original
              flight departure point. If you did not purchase flights through
              Celebrity, Celebrity will assist with coordinating travel
              arrangements to get you and members of your Traveling Party back
              home, but Celebrity will not be responsible for any associated
              costs.
            </li>
          </ul>
        </li>
      </ul>
      <p>
        <strong>
          FOR ALL OTHER CELEBRITY CRUISES BOOKED ON OR AFTER THE EFFECTIVE DATE
          ON SAILINGS SCHEDULED TO SAIL ON OR BEFORE APRIL 30, 2023
        </strong>
      </p>
      <p>
        <u>
          Denial of Embarkation or Reboarding; Quarantine and/or Disembarkation
        </u>
      </p>
      <ul>
        <li className="u-margin-bottom-32">
          If you are denied embarkation or reboarding, or quarantined or
          disembarked during your cruise, due to testing positive for COVID-19
          or being suspected of having COVID-19, you are entitled to a refund
          for the cruise fare paid to Celebrity in the event of denial at
          embarkation, or a pro-rated refund for the unused portion of your
          cruise fare in all other cases. If you are required to quarantine
          onboard the vessel, days spent in quarantine shall be treated as
          unused days of the cruise.
        </li>
      </ul>
      <p>
        <strong>
          OBLIGATION TO COMPLY WITH CELEBRITY COVID-19 POLICIES AND PROCEDURES
        </strong>
      </p>
      <p className="u-margin-bottom-32">
        Guests denied embarkation or reboarding, or who are disembarked or
        quarantined during the voyage, for failure to comply with the Celebrity
        COVID-19 Policies and Procedures in effect at the time of the cruise,
        shall not be entitled to a refund or FCC, compensation of any kind, or
        any of the assistance described in this Policy. Please refer to the
        Ticket Contract issued for your cruise for complete details.
      </p>
      <p>
        <strong>GENERAL</strong>
      </p>
      <p className="u-margin-bottom-32">
        For purposes of this Policy, your “Traveling Party” means your family
        members living with you in the same household and traveling companions
        assigned to your stateroom on the cruise.
      </p>
      <p className="u-margin-bottom-32">
        This Policy does not apply to guests booked on chartered sailings.
      </p>
      <p className="u-margin-bottom-32">
        The terms of this Policy are valid from the Effective Date below,
        subject to change, and will remain in full force until we choose, in our
        sole discretion, to update or modify all or part of it. Updates or the
        modifications may be made and shall be effective without publication,
        although we will endeavor to post any updates or changes in a timely
        manner to a publicly accessible forum such as the Celebrity Cruises
        website or mobile phone application.
      </p>
      <p>
        <i>
          * All refund requests must be made within six (6) months after the
          scheduled embarkation date.
        </i>
      </p>

      <p className="u-margin-bottom-32">Effective Date: August 17, 2022</p>
    </div>
    <div>
      <div
        className="u-text-center title bold"
        style={{ marginTop: '65px', marginBottom: '20px' }}
      >
        <h1 className="u-remove-margin">
          <strong>CELEBRITY CRUISES</strong>
        </h1>
        <h1 className="u-remove-margin">
          <strong>REFUND* AND CANCELLATION POLICY FOR COVID-19</strong>
        </h1>
        <h1 className="u-remove-margin">
          <strong> (OUTSIDE NORTH AMERICA) </strong>
        </h1>
      </div>
      <p className="u-margin-bottom-32">
        The terms of this Policy apply to all Celebrity Cruises (“Celebrity”)
        cruise holidays booked and scheduled to depart during a period of
        temporary government order, international travel restriction, or other
        official regulatory measures in relation to COVID-19 that affect your
        booking (“Restricted Period”).
      </p>
      <p>
        Except as specified below, the standard cancellation policies and
        penalties in your Booking Terms and Conditions and any current COVID-19
        Supplemental Terms or other published Celebrity policies will apply to
        your cruise holiday.
      </p>
      <p>
        This Policy is effective from the Version Date at the bottom of this
        document (“Effective Date”) and applies to all bookings of Celebrity
        cruises outside the U.S. and scheduled to sail between the Effective
        Date and 30 November 2022, inclusive.
      </p>
      <p>
        We strongly encourage you to purchase adequate travel insurance for your
        particular needs, and with sufficient cover for any additional costs not
        payable y Celebrity, in accordance with our standard Booking Conditions.
      </p>
      <p>
        <strong>Cruise Cancelled by Celebrity Cruises</strong>
      </p>
      <p>
        If Celebrity cancels your cruise, or boarding is delayed by three (3)
        days or more during a Restricted Period and you elect not to embark on
        the delayed cruise or a substitute cruise that we offer, you will be
        entitled to a full refund of your cruise fare plus any additional items
        purchased from Celebrity, or an optional Future Cruise Credit (“FCC”)
        for the equivalent amount you paid to Celebrity.
      </p>
      <p>
        <strong>Cruise Cancelled by Guest*</strong>
      </p>
      <ul>
        <li className="u-margin-bottom-32">
          If you cancel a cruise booking due to you or someone in your Traveling
          Party testing positive for COVID-19 within 10 days before embarkation,
          you and they are entitled to a full refund of your cruise fare plus
          any additional items purchased from Celebrity, a substitute cruise of
          equal or greater value, or an optional Future Cruise Credit (“FCC”)
          for the equivalent amount you paid to Celebrity.
        </li>
        <li className="u-margin-bottom-32">
          If you had a confirmed close contact with a person who tested
          positive, or is suspected of having COVID-19 within 10 days before
          embarkation and Celebrity deems you are unfit to travel, you and
          anyone else in your Traveling Party who cancels, are eligible for a
          full refund of your cruise fare plus any additional items purchased
          from Celebrity, a substitute cruise of equal or greater value, or an
          optional Future Cruise Credit (“FCC”) for the equivalent amount you
          paid to Celebrity. If your COVID-19 test was administered by a
          provider other than one retained by Celebrity, you must present your
          verified positive test result in a form acceptable to Celebrity.
        </li>
        <li className="u-margin-bottom-32">
          If you report, or we identify, that you may have been exposed to or
          are likely to have been infected by COVID-19 within 10 days before
          embarkation, we may require that you and others in your Traveling
          Party do not travel to the departure port. This is in order to prevent
          the transmission of COVID-19. Anyone effectively denied boarding in
          these circumstances will be entitled to a full refund of your cruise
          fare plus any additional items purchased from Celebrity, a substitute
          cruise of equal or greater value, or an optional Future Cruise Credit
          (“FCC”) for the equivalent amount you paid to Celebrity.
        </li>
      </ul>
      <p>
        <strong>
          Denial of Embarkation or Reboarding; Quarantine and/or Disembarkation
        </strong>
      </p>
      <ul>
        <li className="u-margin-bottom-32">
          If you or members of your Travelling Party, or other close contacts
          are denied embarkation or reboarding or quarantined or disembarked
          during the cruise due to a positive COVID-19 test or being suspected
          of having COVID-19, you and they are entitled to a refund of your
          cruise fare plus any additional items purchased from Celebrity, or an
          optional Future Cruise Credit (“FCC”) for the equivalent amount you
          paid to Celebrity. In all other cases, you will be entitled a
          pro-rated refund or pro-rated FCC for the unused portion of your
          cruise fare plus any additional items purchased from Celebrity.
        </li>
        <p>
          Celebrity will also provide the same assistance to identified Close
          Contacts on these sailings if they are required to quarantine onboard
          or are disembarked or denied re-boarding due to having been in close
          contact to a guest who tests positive for COVID-19.
        </p>
      </ul>
      <ul>
        <li className="u-margin-bottom-32">
          <strong>
            For all Celebrity cruises scheduled to sail from outside the U.S. or
            from the European Union or the United Kingdom on or before November
            30, 2022, if you test positive for COVID-19 during the voyage:
          </strong>
        </li>
        <ul>
          <li className="u-margin-bottom-32">
            Celebrity will cover the cost of necessary COVID-19 related medical
            treatment onboard the ship;
          </li>
          <li className="u-margin-bottom-32">
            you and members of your Traveling Party may stay onboard the ship to
            complete any required quarantine period, without needing to pay
            additional cruise fare, stateroom gratuities or taxes/fees to
            Celebrity for the same; and
          </li>
          <li className="u-margin-bottom-32">
            If you purchased flights through Celebrity, Celebrity will
            coordinate the travel arrangements, and cover the airline change
            fees and any difference in the airfare for the same class of
            service, necessary to fly you back to the city of your original
            flight departure point. If you did not purchase flights through
            Celebrity, Celebrity will assist with coordinating travel
            arrangements to get you and members of your Traveling Party back
            home, but will not be responsible for any associated costs.
          </li>
        </ul>
        <li className="u-margin-bottom-32">
          <strong>
            For all Celebrity cruises scheduled to sail from the U.S. on or
            before June 30, 2022, and those scheduled to sail from the European
            Union or the United Kingdom on or before November 30, 2022:
          </strong>
        </li>
        <ul>
          <li className="u-margin-bottom-32">
            If a guest who purchased flights through Celebrity is denied
            boarding at embarkation due to a positive COVID-19 test or being
            suspected of having COVID-19, Celebrity will coordinate the travel
            arrangements, and cover the airline change fees and any difference
            in the airfare for the same class of service, necessary to fly the
            guest back to the city of their original flight departure point. If
            such a guest did not purchase flights through Celebrity, Celebrity
            will assist with coordinating travel arrangements necessary to get
            the guest back home but will not be responsible for any associated
            costs.
          </li>
        </ul>
      </ul>
      <p>
        <strong>
          Obligation to Comply with Celebrity COVID-19 Policies and Procedures{' '}
        </strong>
      </p>
      <p>
        Guests denied embarkation or reboarding, or who are disembarked or
        quarantined during the voyage, for failure to comply with the
        <strong>
          <u
            style={{ color: '#2a467b', marginLeft: '5px', marginRight: '5px' }}
          >
            Celebrity COVID-19 Policies and Procedures
          </u>
        </strong>
        in effect at the time of the cruise, shall not be entitled to a refund
        or FCC, compensation of any kind, or any of the assistance described in
        this Policy. Please refer to the Ticket Contract or Booking Conditions
        issued for your cruise for complete details.
      </p>
      <p>
        <strong>General</strong>
      </p>
      <p>
        For purposes of this Policy, your “Traveling Party” means your family
        members living with you in the same household and traveling companions
        assigned to your stateroom on the cruise.
      </p>
      <p>
        “Close Contact” means a person who has been in close proximity with
        another person who tests positive for COVID-19 within 2 days after such
        contact, or (if the other person did not have any symptoms at the date
        of contact) within 2 days of the confirmation date of a COVID-19
        positive test by the other person and/or up to 10 days after such
        confirmation date (being the period when they can still pass the
        infection on to others). For more detailed information about Close
        Contact, please consult your national government and Public Health
        Authority COVID-19 websites.
      </p>
      <p>
        This COVID-19 Cancellation and Refund Policy does not apply to guests
        booked on chartered sailings.
      </p>
      <p>
        This Celebrity COVID-19 Cancellation and Refund Policy is valid from the
        Effective Date below, is subject to change, and will remain in full
        force and effect until further notice.
      </p>
      <p>
        If there is an inconsistency or conflict between any of the terms of
        this COVID-19 Cancellation and Refund Policy and the applicable
        Celebrity Ticket Contract or Booking Terms and Conditions and/or any
        current Supplemental Terms or other notified policies applicable to your
        cruise, the provisions of this COVID-19 Cancellation and Refund Policy
        will prevail.
      </p>
      <p>
        The terms of this Policy are valid from the Effective Date below and
        will remain in full force until we choose, in our sole discretion, to
        update or modify all or part of it. Updates or the modifications may be
        made and shall be effective without publication, although we will
        endeavor to post any updates or changes in a timely manner to a publicly
        accessible forum such as the Celebrity Cruises website or mobile phone
        application.
      </p>
      <p>
        <i>
          * By default, you will be issued a Future Cruise Credit (FCC) for the
          amount specified, unless and until you request a refund in lieu of an
          FCC. All refund requests must be made within six (6) months of the
          date your booking is cancelled or the scheduled embarkation date,
          whichever is earlier, or you will only be entitled to an FCC for the
          amount specified.
        </i>
      </p>
      <p>
        <i>
          *** Currently, reimbursement of the costs of a hotel stay required for
          land-based quarantine is limited to US$250 per hotel room, per night,
          and meal costs of up to US$100 per person, per day.
        </i>
      </p>

      <p className="u-margin-bottom-32">
        <strong>Effective Date:</strong> 13 May 2022
      </p>
    </div>
  </>
)

export default CovidCelebrityPolicy

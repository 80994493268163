import { getShips } from '../../api/api'
import formatShips from '../../utilities/formatShips'

export const REQUEST_SHIPS = 'REQUEST_SHIPS'
export const RECEIVE_SHIPS = 'RECEIVE_SHIPS'
export const SHIPS_ERROR = 'SHIPS_ERROR'

export const fetchShips = () => dispatch => {
  dispatch({ type: REQUEST_SHIPS })

  getShips()
    .then(res => {
      dispatch({
        type: RECEIVE_SHIPS,
        payload: formatShips(res.data)
      })
    })
    .catch(() => {
      dispatch({ type: SHIPS_ERROR })
    })
}

import React from 'react'
import PropTypes from 'prop-types'

import '../../styles/scss/elements/loading.screen.scss'

const LoadingScreen = ({ color }) => {
  return (
    <div
      className={`loading-screen ${color || ''}`}
      data-testid="loading-screen"
    >
      <div className="lds-ring">
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  )
}

LoadingScreen.defaultProps = {
  color: null
}

LoadingScreen.propTypes = {
  color: PropTypes.oneOf(['white'])
}

export default LoadingScreen

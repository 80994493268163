import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setRequestType } from '../../redux/actions/setRequestType'
import SelectionCards from './SelectionCards'

import {
  getSelectedProgramData,
  setActiveSelectionData,
} from '../../redux/actions/setSelectedProgramData'

import {
  getAgentDetails,
  getIsAuthenticated,
} from '../../redux/reducers/ssoData'

import '../../styles/scss/components/single-option-form-heading.scss'

import selectionCardUtilities from './selectionCardUtilities'

export default function SelectionCardContainer({ handleRouteChange }) {
  const currentSelection = useSelector(state => state.requestType)
  const dispatch = useDispatch()
  const preSelectedProgram = useSelector(
    state => state.activeSearchFilters.preSelectedProgram,
  )
  const isAuthenticated = useSelector(state => getIsAuthenticated(state))
  const agentData = useSelector(getAgentDetails)

  const setSelectedProgram = selection => {
    dispatch(setRequestType(selection ? selection.value : ''))
    dispatch(setActiveSelectionData(selection))
  }

  const currentBrandCode = useSelector(state => state.activeSearchFilters.brand)

  const selectedProgramData = useSelector(state =>
    getSelectedProgramData(state),
  )

  function renderOptions() {
    if (isAuthenticated) {
      return selectionCardUtilities.renderAuthenticatedOptions({
        countryCode: agentData?.country,
      })
    }

    if (currentBrandCode === 'C') {
      return selectionCardUtilities.renderCelebrityOptions()
    }

    if (currentBrandCode === 'Z') {
      return selectionCardUtilities.renderAzamaraOptions()
    }

    return selectionCardUtilities.renderDefaultOptions()
  }

  const options = renderOptions().filter(({ enabled }) => enabled)
  useEffect(() => {
    // there are buttons in the flow that reset redux,
    // we need to keep the preSelectedProgram active if it exists.
    if (preSelectedProgram) {
      if (!currentSelection) {
        dispatch(setRequestType(preSelectedProgram))
      }

      if (!selectedProgramData) {
        const selectedProgram = options.find(
          option => option.value === preSelectedProgram,
        )
        dispatch(setActiveSelectionData(selectedProgram))
      }
    }
  }, [
    preSelectedProgram,
    currentSelection,
    dispatch,
    options,
    selectedProgramData,
  ])

  // // the consumer flow is down to one option and we need it preselected.
  // useEffect(() => {
  //   if (options && options.length === 1 && !currentSelection) {
  //     dispatch(setRequestType(options[0]?.value))
  //     dispatch(setActiveSelectionData(options[0]))
  //   }
  // }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SelectionCards
      setSelectedProgram={setSelectedProgram}
      options={options}
      currentBrandCode={currentBrandCode}
      preSelectedProgram={preSelectedProgram}
      handleRouteChange={handleRouteChange}
    />
  )
}

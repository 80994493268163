import React from 'react'
import clipBoardIcon from '../../assets/icons/clip-check.svg'
import moment from 'moment'
import formats from '../../configuration/formats'
import formatPackageName from '../../utilities/formatPackageName'

const LiftPreference = ({
  number,
  shipOptions,
  selections,
  setSelections,
  disabled,
  openItineraryModal,
  disableModal,
  isCruiseTour
}) => {
  const selectedShip =
    selections[number] && selections[number].ship
      ? shipOptions[selections[number].ship]
      : null

  const selectedDate =
    selections[number] && selections[number].date
      ? selectedShip.dates[selections[number].date]
      : null

  const selectedPackage =
    selectedShip && selectedDate && selections[number].package
      ? selectedShip.dates[selections[number].date][selections[number].package]
      : null

  const formattedShipOptions =
    shipOptions && Object.keys(shipOptions).length
      ? Object.entries(shipOptions)
          .map(([key, shipData]) => {
            return {
              code: key,
              name:
                shipData && shipData.shipDetails
                  ? shipData.shipDetails.name
                  : key
            }
          })
          .sort((a, b) => (a.name < b.name ? -1 : 1))
      : null

  function handleDateChange(e) {
    const shouldSetPackage = Boolean(
      selectedShip &&
        selectedShip.dates &&
        selectedShip.dates[e.target.value] &&
        Object.keys(selectedShip.dates[e.target.value]).length === 1
    )

    setSelections({
      ...selections,
      [number]: {
        ...selections[number],
        date: e.target.value,
        package: shouldSetPackage
          ? Object.keys(selectedShip.dates[e.target.value])[0]
          : ''
      }
    })
  }

  function handleShipChange(e) {
    setSelections({
      ...selections,
      [number]: {
        ...selections[number],
        [e.target.name]: e.target.value,
        date: '',
        package: ''
      }
    })
  }

  function handlePackageChange(e) {
    setSelections({
      ...selections,
      [number]: {
        ...selections[number],
        package: e.target.value
      }
    })
  }

  function handleModalOpen() {
    const id =
      selectedPackage && selectedPackage.inclusivePackageOptions
        ? selectedPackage.inclusivePackageOptions.packageCode
        : null
    const brand =
      selectedShip && selectedShip.shipDetails
        ? selectedShip.shipDetails['brand_code']
        : null
    const sailDate = selectedPackage ? selectedPackage.startDate : null

    openItineraryModal(id, brand, sailDate)
  }

  if (disabled && (!selectedShip || !selectedDate)) {
    return (
      <div className="lift-and-shift-preference">
        <div className="u-icon-label u-icon-label-sm">
          <img src={clipBoardIcon} alt="Itinerary Details" />
          <p className="u-bold u-brand-blue">
            {formats.preferenceLabels[number - 1]} Preference
          </p>
        </div>
        <div className={'u-review-information-item'}>
          <p className="u-bold">No Preference Selected</p>
        </div>
      </div>
    )
  }

  return (
    <div className="lift-and-shift-preference">
      <div className="u-icon-label u-icon-label-sm">
        <img src={clipBoardIcon} alt="Itinerary Details" />
        <p className="u-bold u-brand-blue">
          {' '}
          {formats.preferenceLabels[number - 1]} Preference
        </p>
      </div>
      {disabled ? (
        <div className="u-review-information-item">
          <p className="u-review-information-label">Choose Ship</p>
          <p className="u-review-information-value">
            {selectedShip && selectedShip.shipDetails
              ? selectedShip.shipDetails.name
              : null}
          </p>
        </div>
      ) : (
        <label className="u_select-label u-flex u-flex-column">
          <span>Choose Ship</span>
          <select
            className="small-input"
            onChange={handleShipChange}
            name="ship"
            value={
              selections[number] && selections[number].ship
                ? selections[number].ship
                : ''
            }
          >
            <option value="">Select a ship</option>
            {formattedShipOptions &&
              formattedShipOptions.map(option => (
                <option key={option.code} value={option.code}>
                  {option.name}
                </option>
              ))}
          </select>
        </label>
      )}
      {disabled ? (
        <div className="u-review-information-item">
          <p className="u-review-information-label">Select a date</p>
          <p className="u-review-information-value">
            {selectedPackage
              ? moment(selectedPackage.startDate, formats.date)
                  .format(formats.displayDateMedium)
                  .toUpperCase()
              : null}
          </p>
        </div>
      ) : (
        <label className="u_select-label u-flex u-flex-column">
          <span>Select Sailing Date</span>
          <select
            className="small-input"
            onChange={handleDateChange}
            name="date"
            value={
              selections[number] && selections[number].date
                ? selections[number].date
                : ''
            }
          >
            <option value="">Select a date</option>
            {selectedShip &&
              selectedShip.dates &&
              Object.keys(selectedShip.dates)
                .map(option => {
                  return (
                    <option
                      key={option + selectedShip.shipDetails['ship_code']}
                      value={option}
                    >
                      {option &&
                        moment(option, formats.date)
                          .format(formats.displayDateMedium)
                          .toUpperCase()}
                    </option>
                  )
                })
                .sort((a, b) =>
                  moment(a, formats.date).isBefore(moment(b, formats.date))
                    ? -1
                    : 1
                )}
          </select>
        </label>
      )}

      {Boolean(
        !disabled &&
          selectedDate &&
          Object.entries(selectedDate) &&
          Object.entries(selectedDate).length > 1
      ) && (
        <label className="u_select-label u-flex u-flex-column">
          <span>Select a Package</span>
          <select
            className="small-input"
            onChange={handlePackageChange}
            name="package"
            value={
              selections[number] && selections[number].package
                ? selections[number].package
                : ''
            }
          >
            <option value="">Select a Package</option>
            {Object.entries(selectedDate).map(([key, option]) => {
              return (
                <option value={key} key={key}>
                  {option.packageName}
                </option>
              )
            })}
          </select>
        </label>
      )}
      {selectedShip && selectedDate && selectedPackage && (
        <>
          <div className="u-review-information-item">
            <p className="u-review-information-label">Itinerary</p>
            <button
              onClick={disableModal ? () => {} : handleModalOpen}
              className={`btn btn-link u-text-left ${
                disableModal ? 'no-decoration ' : ''
              }`}
            >
              <p
                className={`u-review-information-value ${
                  disableModal ? '' : 'u-brand-blue'
                }`}
              >
                {selectedPackage.packageName
                  ? formatPackageName(selectedPackage, isCruiseTour)
                  : 'Itinerary'}
              </p>
            </button>
          </div>

          <div className="u-review-information-item">
            <p className="u-review-information-label">Departure Port</p>
            <p
              className={`u-review-information-value ${
                disableModal ? '' : 'u-brand-blue'
              }`}
            >
              {selectedPackage.departurePortName ||
                selectedPackage.departurePort}
            </p>
          </div>
        </>
      )}
    </div>
  )
}

export default LiftPreference

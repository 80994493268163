import {
  getAgencyDetails,
  getAgentDetails,
  getAgentUserId
} from '../reducers/ssoData'
import { postCancellation } from '../../api/api'
import {
  getCancellationTypeData,
  getFirstGuest
} from '../reducers/cancellationTypeData'

import {
  getActiveBrandData,
  getIsSpanish
} from '../reducers/activeSearchFilters'
import brands from '../../configuration/brands'
import { validBPGDate } from '../../configuration/termsAndConditions'
import calculateTotalDiscount from '../../utilities/calculateTotalDiscount'
import { formatPriceAsString } from '../../utilities/formatPrice'

export const REQUEST_CANCEL_RESERVATION = 'REQUEST_CANCEL_RESERVATION'
export const RECEIVE_CANCELLATION_CONFIRMATION =
  'RECEIVE_CANCELLATION_CONFIRMATION'
export const CANCELLATION_ERROR = 'CANCELLATION_ERROR'

export const cancelReservation = agentEmail => (dispatch, getState) => {
  dispatch({ type: REQUEST_CANCEL_RESERVATION })

  const state = getState()
  const agencyDetails = getAgencyDetails(state)
  const agentDetails = getAgentDetails(state)
  const userId = getAgentUserId(state)
  const cancellationTypeData = getCancellationTypeData(state)
  const originalRequestData = state.requestData.data
  const reservationData =
    cancellationTypeData && cancellationTypeData.booking
      ? cancellationTypeData.booking
      : null

  const brandData = getActiveBrandData(state)

  const firstGuest = getFirstGuest(state)

  const params = {}
  params.sessionId = state.sessionId
  params.type = originalRequestData.requestType === 'shell' ? 9 : 14

  if (cancellationTypeData) {
    params.bookingId = cancellationTypeData.id
  }

  params.directBooking = state.activeSearchFilters.brand ? 'Y' : 'N'

  // if direct booking use firstGuestEmail
  // if not check for agentEmail passed in, otherwise use cancellationTypeData.agencyEmail
  if (cancellationTypeData.directBooking === 'Y') {
    params.email = firstGuest ? firstGuest.email : null
  } else {
    params.email = agentEmail
      ? agentEmail
      : cancellationTypeData
      ? cancellationTypeData.agencyEmail
      : ''
  }

  if (agentDetails) {
    params.name = agentDetails.name
    params.channel = 'CruisingPower Web'
  } else {
    params.name = firstGuest
      ? firstGuest.givenName + ' ' + firstGuest.surname
      : ''
    if (brandData && brands && brands[brandData['brand_code']]) {
      params.channel = brands[brandData['brand_code']].channel
    } else {
      params.channel = 'CruisingPower Agent Consumer Web'
    }
  }

  if (userId) {
    params.userId = userId
  }

  if (cancellationTypeData) {
    params.programCode = cancellationTypeData.code
  }

  if (reservationData) {
    if (reservationData.sailDate) {
      params.sailDate = reservationData.sailDate
    }
    if (reservationData.shipCode) {
      params.shipCode = reservationData.shipCode
    }
    if (reservationData.surname) {
      params.surname = reservationData.surname
    }
    if (reservationData.groupId) {
      params.groupId = reservationData.groupId
    }
  }

  if (agencyDetails && agencyDetails['agency_id']) {
    params.agencyId = agencyDetails['agency_id']
  }

  if (state.requestType === 'las') {
    params.sailingQualifiers = state.liftAndShiftSelections
  }

  params.lang = getIsSpanish(state) ? 'es-es' : 'en-us'

  if (
    state.requestType === 'bpg' &&
    cancellationTypeData &&
    cancellationTypeData.pricing
  ) {
    const { pricing } = cancellationTypeData

    params.rateQualifiers = {
      prevailingPriceId:
        pricing.prevailingRate && pricing.prevailingRate.priceId
          ? pricing.prevailingRate.priceId
          : '',
      prevailingPriceType:
        pricing.prevailingRate && pricing.prevailingRate.priceType
          ? pricing.prevailingRate.priceType
          : '',
      discount: formatPriceAsString(calculateTotalDiscount(pricing)),
      qualifierCodes: pricing.prevailingRate
        ? pricing.prevailingRate.qualifiers
        : []
    }

    params.validDate = validBPGDate
  }

  // To avoid killing limited test booking uncomment the below code. DO NOT COMMIT

  // console.log(params)
  // return new Promise(resolve => {
  //   setTimeout(() => {
  //     resolve({ data: { data: {} } })
  //   }, 500)
  // })
  return postCancellation(params)
}

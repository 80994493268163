import React from 'react'

export const ListItem = ({
  item,
  borderBottom,
  className,
  clickHandler,
  blue,
  btnClassName,
  subText
}) => {
  return (
    <div
      className={`u-review-information-item ${
        borderBottom ? 'u-border-bottom' : ''
      } ${className ? className : ''}`}
    >
      <p className="u-review-information-label">{item.label}</p>
      {clickHandler ? (
        <button
          className={`btn btn-hollow u-no-padding u-review-information-value ${
            btnClassName ? btnClassName : ''
          }`}
          onClick={clickHandler}
        >
          <p className="u-review-information-value u-brand-blue">
            {item.value}
          </p>
        </button>
      ) : (
        <div>
          <p
            className={`u-review-information-value ${
              blue ? 'u-brand-blue' : ''
            }`}
          >
            {item.value}
          </p>
        </div>
      )}
      {subText && <p className="u-review-information-disclaimer">{subText}</p>}
    </div>
  )
}

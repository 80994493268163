import React from 'react'

import '../../styles/scss/components/fcc-terms.scss'

const CvcTermsAndConditions = ({ dismissSubmit, submit }) => (
  <div className="" data-testid="fcc-terms">
    <div className="fcc-terms-header">
      <h1 className="title u-text-center">
        Cruise Booking Cancellation Request Due to COVID-19
        <br />
        Royal Caribbean International
        <br />
        Celebrity Cruises
        <br />
        Terms and Conditions
      </h1>
      <p className="u-text-center u-margin-bottom-32">
        (For sailings on or before April 30, 2023)
      </p>
    </div>
    <div className="u-margin-bottom-32">
      <p>
        A. As a material inducement to the cruise line,{' '}
        <strong>
          <i>
            Royal Caribbean Cruises Ltd., trading as Royal Caribbean
            International, or Celebrity Cruises Inc. (the “Cruise Line”)
          </i>
        </strong>
        , I, the individual travel agent submitting this request form, hereby
        represent, warrant and covenant to the Cruise Line on behalf of myself
        and my clients that:
      </p>
      <ol>
        <li>
          The guests named on the booking(s) identified in this cancellation
          request are my clients;
        </li>
        <li>
          I am acting as the agent of these guests and am duly authorized to
          complete and submit this cancellation request on their behalf;
        </li>
        <li>
          The information I entered as part of this request form is, to the best
          of my knowledge and belief, accurate and truthful;
        </li>
        <li>
          The guest I identified in this request as having tested positive for
          COVID-19 within 10 days of the scheduled day of embarkation for this
          cruise (the “COVID Positive Guest”) is the person named on the test
          result I uploaded to this request;
        </li>
        <li>
          The test result I uploaded is dated within 10 days of the scheduled
          embarkation date of the cruise and it indicates that the test result
          was positive for COVID-19;
        </li>
        <li>
          I have no reason to believe that the test result I uploaded is fake or
          that it has been altered; and
        </li>
        <li>
          If I have selected any additional staterooms (i.e., in addition to
          that of the COVID Positive Guest) for cancellation pursuant to this
          request, all guests booked in those stateroom(s) are family members of
          the COVID Positive Guest living with him/her in the same household.
        </li>
      </ol>
      <p>B. My clients understand and agree that:</p>
      <ol>
        <li>
          Upon submission of this request, the cruise reservations of all guests
          booked in the COVID Positive Guest‘s stateroom, as well as all guests
          booked in any additional stateroom(s) selected, will be cancelled.
          This tool cannot be used to remove select guest(s) from a booking or
          to change names on a booking;
        </li>
        <li>
          This request is irrevocable and, once submitted, it cannot be
          withdrawn;
        </li>
        <li>
          The Cruise Line reserves the right to seek reimbursement from these
          guests if it determines, after issuing refund(s), that the document
          uploaded as the test result is not a valid positive COVID-19 test
          result for the guest identified as the COVID Positive Guest; and
        </li>
        <li>
          If one or more of the guests whose reservation is cancelled is not
          eligible for a refund pursuant to the Cruise Line’s COVID-19
          Cancellation and Refund Policy, the guest may be subject to 100%
          cancellation charges.
        </li>
      </ol>
    </div>
    <div className="terms-ctas">
      <div className="u-flex u-flex-align-center u-margin-bottom-16">
        <button
          onClick={submit}
          className="btn btn-primary btn-oval with-arrow u-uppercase"
        >
          I Agree
        </button>
        <p className="u-remove-margin">
          Continue to submit the Cruise Booking Cancellation Request due to
          COVID-19.
        </p>
      </div>
      <div className="u-flex u-flex-align-center">
        <button
          onClick={dismissSubmit}
          className="btn btn-oval btn-white with-arrow u-uppercase"
        >
          Cancel
        </button>
        <p className="u-remove-margin">
          I’ve changed my mind and would like to cancel the Cruise Booking
          Cancellation Request due to COVID-19.
        </p>
      </div>
    </div>
  </div>
)

export default CvcTermsAndConditions

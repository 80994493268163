import React from 'react'
import moment from 'moment'
import brands from '../../configuration/brands'
import { useSelector } from 'react-redux'
import calIcon from '../../assets/icons/calendar.svg'
import { ListItem } from './ReviewItemsList'
import { selectShipsData } from '../../redux/reducers/ships'

function generateBookingLabel(cancellationData, requestData) {
  if (!cancellationData || Number(cancellationData.type) === 14)
    return 'Booking ID'

  if (requestData && requestData.requestType === 'shell') {
    return 'Group Shell ID'
  }
  return 'Group Booking ID'
}

const BookingDetails = ({
  reservationData,
  isAuthenticated,
  cancellationData
}) => {
  const ships = useSelector(selectShipsData)

  const shipData =
    reservationData && reservationData.shipCode
      ? ships.find(ship => ship['ship_code'] === reservationData.shipCode)
      : null

  const brandInfo = shipData ? brands[shipData['brand_code']] : null
  const requestData = useSelector(state => state.requestData.data)

  const title =
    isAuthenticated && cancellationData && Number(cancellationData.type) === 9
      ? 'Group Information'
      : 'Booking Information'

  const bookingLabel = generateBookingLabel(cancellationData, requestData)

  return (
    <div className="review-reservation-details-detail-section">
      <div className="u-icon-label">
        <img src={calIcon} alt={title} />
        <p className="u-bold u-brand-blue">{title}</p>
      </div>

      {cancellationData && cancellationData.id && (
        <ListItem
          item={{
            label: bookingLabel,
            value: cancellationData.id
          }}
          borderBottom
          blue
        />
      )}
      {Boolean(
        reservationData &&
          reservationData.groupName &&
          reservationData.groupName.length
      ) && (
        <ListItem
          item={{
            label: 'Group Name:',
            value: reservationData.groupName
          }}
          borderBottom
          blue
        />
      )}

      {brandInfo && (
        <ListItem
          item={{
            label: 'Brand:',
            value: brandInfo.fullName
          }}
          borderBottom
          blue
        />
      )}

      {shipData && (
        <ListItem
          item={{
            label: 'Ship:',
            value: shipData.name
          }}
          borderBottom
          blue
        />
      )}
      {reservationData && reservationData.sailDate && (
        <ListItem
          item={{
            label: 'Sail Date:',
            value: moment(reservationData.sailDate, 'YYYY-MM-DD')
              .format('DDMMMYYYY')
              .toUpperCase()
          }}
          blue
        />
      )}
    </div>
  )
}

export default BookingDetails

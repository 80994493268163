import React, { useRef, useState } from 'react'
import ObcTable from './ObcTable'
// import * as yup from 'yup'
import '../../styles/scss/components/existing-obc-list.scss'
import formatGuestName from '../../utilities/formatGuestName'
// import Modal from '../elements/Modal'

// import trash from '../../assets/icons/trash-blue.svg'
// import { ErrorMessage, Field, Formik } from 'formik'
// import { bool } from 'yup'
// import LoadingScreen from '../elements/LoadingScreen'

const ExistingObc = ({
  obcs,
  // setTermsModalIsOpen,
  // isLoading,
  // submit,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  // const [confirmCancelModalState, setConfirmCancelModalState] = useState({
  //   isOpen: false,
  //   item: null,
  // })
  const ref = useRef(null)
  const innerRef = useRef(null)

  const toggleTable = () => {
    if (isOpen) {
      ref.current.style.maxHeight = 0
      setIsOpen(false)
      return
    }

    ref.current.style.maxHeight = `${
      innerRef?.current?.getBoundingClientRect()?.height
    }px`
    setIsOpen(true)
  }

  // const stageItemForCancellation = item =>
  //   setConfirmCancelModalState({
  //     isOpen: true,
  //     item,
  //   })
  //
  // const closeConfirmCancelModal = () =>
  //   setConfirmCancelModalState({
  //     isOpen: false,
  //     item: null,
  //   })
  //
  // const confirmCancel = () => {
  //   submit(confirmCancelModalState.item)
  // }

  return (
    <div className="existing-obc-list">
      <div className={`banner ${isOpen ? 'active' : 'closed'}`}>
        <button className="toggle btn btn-link" onClick={toggleTable}>
          {isOpen ? 'Close Order History' : 'View Order History'}
          <span>{isOpen ? '-' : '+'}</span>
        </button>
      </div>
      <div
        ref={ref}
        className="animate-drawer"
        style={{ maxHeight: 0, overflow: 'hidden' }}
      >
        <div className="existing-obc-table" ref={innerRef}>
          <h2 className="title">Onboard Credit Order History</h2>
          {obcs?.length ? (
            <ObcTable
              purchases={obcs?.map(obc => ({
                ...obc,
                guestId: obc.guestID,
                toName: formatGuestName({
                  givenName: obc.guestFirstName,
                  surname: obc.guestLastName,
                }),
                amount: obc.obcAmountInShipCurrency,
                status: obc.optionStatus === 'C' ? 'Purchased' : 'Cancelled',
              }))}
              applicableOnBoardCredit={{
                ...(obcs?.[0] || {}),
              }}
              amountTotalLabel="Total Amount Paid"
              creditTotalLabel="Total Purchased Onboard Credit Value"
              // handleDelete={item => stageItemForCancellation(item)}
              // deleteIcon={trash}
            />
          ) : (
            <div>
              <p>No Order History.</p>
            </div>
          )}
        </div>
      </div>
      {/*<Modal*/}
      {/*  isOpen={confirmCancelModalState.isOpen}*/}
      {/*  onRequestClose={closeConfirmCancelModal}*/}
      {/*  className="confirm-cancel-obc-modal"*/}
      {/*>*/}
      {/*  <div>*/}
      {/*    <p className="heading">You are about to delete the following OBC</p>*/}
      {/*    <p className="summary">*/}
      {/*      {confirmCancelModalState.item?.toName}{' '}*/}
      {/*      {confirmCancelModalState.item?.obcAmountInShipCurrency}{' '}*/}
      {/*      {confirmCancelModalState.item?.shipCurrency}{' '}*/}
      {/*      <span>*/}
      {/*        ({confirmCancelModalState.item?.obcAmountInBookingCurrency}{' '}*/}
      {/*        {confirmCancelModalState.item?.bookingCurrency})*/}
      {/*      </span>*/}
      {/*    </p>*/}
      {/*    <p className="warning">*/}
      {/*      Once deleted, this action can not be undone.*/}
      {/*    </p>*/}
      {/*    <Formik*/}
      {/*      initialValues={{ terms: false }}*/}
      {/*      onSubmit={confirmCancel}*/}
      {/*      validationSchema={yup.object({*/}
      {/*        terms: bool().oneOf(*/}
      {/*          [true],*/}
      {/*          'You must accept the terms and conditions',*/}
      {/*        ),*/}
      {/*      })}*/}
      {/*    >*/}
      {/*      {({ handleSubmit }) => (*/}
      {/*        <div className="form">*/}
      {/*          <label>*/}
      {/*            <Field type="checkbox" name="terms" />*/}
      {/*            <p>I consent to and accept </p>*/}
      {/*            <button*/}
      {/*              className="btn btn-link"*/}
      {/*              onClick={() => setTermsModalIsOpen(true)}*/}
      {/*            >*/}
      {/*              the terms and conditions.*/}
      {/*            </button>*/}
      {/*          </label>*/}
      {/*          <ErrorMessage*/}
      {/*            name="terms"*/}
      {/*            className="error-message"*/}
      {/*            component="p"*/}
      {/*          />*/}
      {/*          <div className="ctas">*/}
      {/*            {isLoading ? (*/}
      {/*              <LoadingScreen />*/}
      {/*            ) : (*/}
      {/*              <>*/}
      {/*                <button*/}
      {/*                  type="submit"*/}
      {/*                  className="btn btn-oval"*/}
      {/*                  onClick={() => handleSubmit()}*/}
      {/*                >*/}
      {/*                  Delete OBC*/}
      {/*                </button>*/}
      {/*                <button*/}
      {/*                  className="btn btn-link"*/}
      {/*                  type="button"*/}
      {/*                  onClick={closeConfirmCancelModal}*/}
      {/*                >*/}
      {/*                  Cancel*/}
      {/*                </button>*/}
      {/*              </>*/}
      {/*            )}*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      )}*/}
      {/*    </Formik>*/}
      {/*  </div>*/}
      {/*</Modal>*/}
    </div>
  )
}

export default ExistingObc

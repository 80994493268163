import React, { useRef, useState } from 'react'
import AddGuestOBCForm from './AddGuestOBCForm'
import Modal from '../elements/Modal'

import PaymentForm from './PaymentForm'
import {
  purchaseObc,
  // refundObc
} from '../../redux/actions/purchaseObc'
import { useDispatch, useSelector } from 'react-redux'
import routes from '../../configuration/routes'
import ObcTable from './ObcTable'
import ExistingObc from './ExistingObc'
import { convert } from './utilities'
import generateSessionId from '../../utilities/generateSessionId'

import checkMark from '../../assets/icons/checkmark-highlight.svg'
import triggerCustomGaEvent from '../../utilities/triggerCustomGaEvent'

const PurchaseForm = ({
  guests,
  applicableOnBoardCredit,
  resetFlow,
  handleRouteChange,
  appliedOnBoardCredits,
  bookingData,
  shipData,
  brandData,
}) => {
  const dispatch = useDispatch()
  const isLoading = useSelector(state => !!state.confirmationData.loading)
  const mainFormResetButton = useRef(null)
  const [purchases, setPurchases] = useState([])
  const [isShowingPaymentForm, setIsShowingPaymentForm] = useState(false)

  const [termsModalIsOpen, setTermsModalIsOpen] = useState(false)
  const [errorModalState, setErrorModalState] = useState({
    isOpen: false,
    message: ',',
  })
  const convertToBookingCurrency = amount => {
    if (!amount) {
      return ''
    }
    const converted = convert(amount, applicableOnBoardCredit)

    return `$${converted
      .round(2)
      .toFixed(2)} ${applicableOnBoardCredit?.bookingCurrency || ''}`
  }

  const [removePurchaseModalState, setRemovePurchaseModalState] = useState({
    isOpen: false,
    purchase: null,
  })

  const [editPurchaseModalState, setEditPurchaseModalState] = useState({
    isOpen: false,
    purchase: null,
    showSubmitting: false,
  })

  const handleConfirmRemoveNewPurchase = () => {
    setPurchases(
      purchases.filter(
        currentPurchase =>
          currentPurchase.transactionReferenceNumber !==
          removePurchaseModalState.purchase.transactionReferenceNumber,
      ),
    )
    setRemovePurchaseModalState({
      isOpen: false,
      guestId: null,
    })
  }

  const dismissRemoveNewPurchase = () => {
    setRemovePurchaseModalState({
      isOpen: false,
      guestId: null,
    })
  }

  const stageRemoveNewPurchase = purchase => {
    setRemovePurchaseModalState({
      isOpen: true,
      purchase,
    })
  }

  const stagePurchaseForEdit = purchase => {
    setEditPurchaseModalState({
      isOpen: true,
      purchase,
      showSubmitting: false,
    })
  }

  const dismissEditPurchase = () => {
    setEditPurchaseModalState({
      isOpen: false,
      purchase: null,
      showSubmitting: false,
    })
  }

  const confirmEditPurchases = () => {
    setEditPurchaseModalState(currentState => ({
      ...currentState,
      showSubmitting: true,
    }))
    if (mainFormResetButton.current) {
      mainFormResetButton.current.click()
    }
    window.setTimeout(() => {
      dismissEditPurchase()
    }, 1000)
  }

  const dismissErrorModal = () =>
    setErrorModalState({ isOpen: false, message: '' })

  const submitPayment = paymentValues => {
    if (!paymentValues.cardType || paymentValues.cardType === 'unknown') {
      return setErrorModalState({
        isOpen: true,
        message:
          'The credit card number is invalid or not supported. Please check the credit card number and try again.',
      })
    }

    return dispatch(
      purchaseObc({
        paymentValues,
        purchases,
        bookingData,
        shipData,
        brandData,
      }),
    )
      .then(() => {
        handleRouteChange(routes.confirmation)
      })
      .catch(err => {
        setErrorModalState({
          isOpen: true,
          message: err?.message || 'Unable to complete purchase.',
        })
      })
  }

  return (
    <div className="purchase-form">
      {guests?.length ? (
        <AddGuestOBCForm
          guests={guests.map(guest => {
            const pendingAndCurrentPurchaseTotal =
              purchases?.reduce((num, purchase) => {
                if (purchase.guestId === `${guest.id}` && purchase.amount) {
                  return num + +purchase.amount
                }
                return num
              }, guest.currentActiveCreditTotal || 0) || 0

            return {
              ...guest,
              pendingAndCurrentPurchaseTotal,
            }
          })}
          onSubmit={newGuest => {
            const data = {
              ...newGuest,
              transactionReferenceNumber: generateSessionId(),
            }
            setPurchases([...purchases, data])
            return Promise.resolve([purchases, data])
          }}
          ref={mainFormResetButton}
        />
      ) : null}

      {purchases.length ? (
        <div className="new-purchase-list">
          <p className="title">Onboard Credits</p>

          <ObcTable
            purchases={purchases}
            applicableOnBoardCredit={applicableOnBoardCredit}
            handleDelete={item => stageRemoveNewPurchase(item)}
            handleEdit={item => stagePurchaseForEdit(item)}
            creditTotalLabel="Total Onboard Credit Value"
            amountTotalLabel="Total Amount Due"
          />
        </div>
      ) : null}

      <ExistingObc
        obcs={appliedOnBoardCredits}
        setTermsModalIsOpen={setTermsModalIsOpen}
        isLoading={isLoading}
      />

      {isShowingPaymentForm ? (
        <div>
          <PaymentForm
            onSubmit={submitPayment}
            resetFlow={resetFlow}
            isLoading={isLoading}
            setTermsModalIsOpen={setTermsModalIsOpen}
          />
        </div>
      ) : (
        <div className="ctas">
          <div>
            <button
              disabled={!purchases?.length}
              className="btn btn-oval royal-blue with-arrow lg"
              onClick={e => {
                e.preventDefault()
                triggerCustomGaEvent('obc-proceedToPayment')
                setIsShowingPaymentForm(true)
              }}
            >
              Proceed to Payment
            </button>
          </div>

          <div className="btn-container">
            <button className="btn btn-hollow" onClick={resetFlow}>
              Cancel request
            </button>
          </div>
        </div>
      )}
      {editPurchaseModalState.isOpen && (
        <Modal
          isOpen={editPurchaseModalState.isOpen}
          onRequestClose={dismissEditPurchase}
          className="edit-new-purchase-modal"
        >
          <div className="relative  ">
            <div className="purchase-form">
              {editPurchaseModalState.showSubmitting && (
                <div className="absolute success-message-container">
                  <img alt="checkmark" src={checkMark} />
                  <p>Changes Saved</p>
                </div>
              )}
              <div className="edit-modal-header">
                <p>Edit Onboard Credits:</p>
                <p className="purchase-name">
                  {editPurchaseModalState.purchase?.toName}
                </p>
              </div>
              <AddGuestOBCForm
                guests={guests.map(guest => {
                  const pendingAndCurrentPurchaseTotal =
                    purchases?.reduce((num, purchase) => {
                      const isActiveEdit =
                        purchase.transactionReferenceNumber ===
                        editPurchaseModalState.purchase
                          ?.transactionReferenceNumber
                      if (
                        purchase.guestId === `${guest.id}` &&
                        purchase.amount &&
                        !isActiveEdit
                      ) {
                        return num + +purchase.amount
                      }
                      return num
                    }, guest.currentActiveCreditTotal || 0) || 0

                  return {
                    ...guest,
                    pendingAndCurrentPurchaseTotal,
                  }
                })}
                hideGuestSelect
                initialValues={{ ...editPurchaseModalState.purchase }}
                onSubmit={newValues => {
                  setPurchases(
                    purchases.map(purchase =>
                      purchase.transactionReferenceNumber ===
                      newValues.transactionReferenceNumber
                        ? newValues
                        : purchase,
                    ),
                  )
                  confirmEditPurchases()
                }}
                submitCta="Save & Close"
                handleDismiss={dismissEditPurchase}
              />
            </div>
          </div>
        </Modal>
      )}

      <Modal
        isOpen={removePurchaseModalState.isOpen}
        onRequestClose={dismissRemoveNewPurchase}
        className="remove-new-purchase-confirmation-modal"
      >
        <div className="">
          <p className="disclaimer">
            You are about to delete the following OBC
          </p>

          <p className="details">
            {removePurchaseModalState?.purchase?.toName || ''}{' '}
            {removePurchaseModalState?.purchase?.amount}{' '}
            <span className="currency">
              {applicableOnBoardCredit?.shipCurrency || ''}
            </span>
            <span className="converted">
              {' '}
              (
              {convertToBookingCurrency(
                removePurchaseModalState?.purchase?.amount,
              )}
              )
            </span>
          </p>
          <div>
            <button
              className="confirm"
              onClick={handleConfirmRemoveNewPurchase}
            >
              Delete OBC
            </button>
          </div>
          <button className="dismiss" onClick={dismissRemoveNewPurchase}>
            Cancel
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={termsModalIsOpen}
        onRequestClose={() => setTermsModalIsOpen(false)}
        className="obc-terms-modal"
      >
        <div>
          <button
            onClick={() => setTermsModalIsOpen(false)}
            className="close-btn"
          >
            Close X
          </button>
          <h1>Onboard Credit Terms and Conditions</h1>
          <p>
            <i>General.</i> Royal Caribbean International and Celebrity Cruises
            (each, a “Cruise Line”) permit Travel Advisors to purchase onboard
            credits redeemable either onboard any vessel in the issuing Cruise
            Line’s fleet or for pre-cruise purchases on the issuing Cruise
            Line’s website or app (an “OBC”). OBCs may only be issued in
            connection with an existing individual or group booking and must be
            associated with a single guest on the booking (the “recipient”).
            OBCs represent an obligation solely of the issuing Cruise Line.{' '}
            <i>Currency.</i> OBCs shall be sold in the same currency as the
            associated booking. Notwithstanding, all OBCs shall be issued in
            U.S. dollars. If an OBC is sold in a non-USD currency, the issuing
            Cruise Line shall convert the OBC to the USD equivalent based on
            prevailing exchange rates at the time of purchase. The purchaser
            shall be responsible for any foreign exchange or transaction fees
            incurred in connection with the purchase of an OBC.{' '}
            <i>Redemption.</i> The recipient may redeem their OBC by logging
            onto the issuing Cruise Line’s website or app and making a
            pre-cruise purchase for their cruise no later than eight (8) days
            prior to sailing. Thereafter, any unused balance will be added as a
            credit to the recipient’s onboard folio and applied against onboard
            charges until the value of the OBC reaches zero dollars.{' '}
            <i>Limitations.</i> OBCs are not transferable or redeemable for cash
            or cash equivalents onboard. <i>Cancellations and Refunds.</i>{' '}
            Purchaser may request a refund of an unused OBC purchase until ten
            (10) days prior to the recipient’s sailing date. Once the OBC is
            redeemed (in whole or in part) by the recipient, the purchaser may
            not request a refund or cancellation. If recipient cancels a
            purchase made with an OBC (e.g., a shore excursion) or otherwise
            does not use the full value of recipient’s OBC at least eight (8)
            days prior to sailing, the unused value of the OBC shall be credited
            to the recipient’s onboard folio. Recipient may request a refund of
            any unused OBC balance following the conclusion of the associated
            cruise or may apply such unused balance to another cruise with
            either Cruise Line. <i>Right of Setoff.</i> If, for any reason,
            recipient’s account with a Cruise Line is negative, either Cruise
            Line may, with or without prior notice, apply the unused balance of
            an OBC against such negative balance due to such Cruise Line without
            liability whatsoever or a right of refund implied.{' '}
            <i>Choice of Law; Dispute Resolution.</i> These Terms and Conditions
            shall be governed by the laws of the State of Florida, without
            reference to its conflicts of law principles. Any controversy
            arising out of or in connection with the purchase or use of an OBC
            shall be submitted to and resolved according to the Dispute
            Resolution and Arbitration provisions set forth in the Royal
            Caribbean Group{' '}
            <a
              href="https://www.royalcaribbean.com/terms-and-conditions/digital-terms-of-use-and-end-user-license-agreement."
              target="_blank"
              rel="noreferrer noopener"
            >
              Digital Terms of Use and End User License Agreement
            </a>
          </p>
          <div className="u-flex u-flex-align-center u-flex-justify-center modal-footer">
            <button
              onClick={() => setTermsModalIsOpen(false)}
              className="btn btn-secondary "
            >
              Dismiss
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={errorModalState.isOpen}
        onRequestClose={dismissErrorModal}
        className="obc-purchase-error-modal"
      >
        <div>
          <h1>Error</h1>
          <p className="error-message">{errorModalState.message}</p>
          <div className="u-flex u-flex-justify-center">
            <button className="btn btn-primary" onClick={dismissErrorModal}>
              Close
            </button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default PurchaseForm

import {
  REQUEST_CANCEL_RESERVATION,
  RECEIVE_CANCELLATION_CONFIRMATION,
  CANCELLATION_ERROR,
} from '../actions/cancelResevation'

import { RESET_BOOKING_FLOW } from '../actions/fetchCancellationType'

const initialState = {
  loading: false,
  error: null,
  data: null,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case REQUEST_CANCEL_RESERVATION:
      return Object.assign({}, state, {
        loading: true,
      })
    case RECEIVE_CANCELLATION_CONFIRMATION:
      return Object.assign({}, state, {
        loading: false,
        data: action.payload,
        error: null,
      })
    case CANCELLATION_ERROR:
      return Object.assign({}, state, {
        loading: false,
        error: action.payload,
      })
    case RESET_BOOKING_FLOW:
      return initialState
    default:
      return state
  }
}

export const selectConfirmationData = state => state?.confirmationData?.data

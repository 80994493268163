import { combineReducers } from 'redux'
import config from './config'
import ssoData from './ssoData'
import cancellationType from './cancellationTypeData'
import confirmationData from './confirmationData'
import activeSearchFilters from './activeSearchFilters'
import requestData from './requestData'
import sessionId from './sessionId'
import brands from './brand'
import ships from './ships'
import labels from './labels'
import liftAndShiftSelections from './liftAndShiftSelectionsData'
import requestType from './requestType'
import itineraries from './itineraries'
import editedAgentDetails from './editedAgentDetails'
import selectedProgramData from './selectedProgramData'

const rootReducer = combineReducers({
  activeSearchFilters,
  brands,
  confirmationData,
  config,
  cancellationType,
  editedAgentDetails,
  itineraries,
  labels,
  liftAndShiftSelections,
  requestData,
  requestType,
  ships,
  ssoData,
  selectedProgramData,
  sessionId
})

export default rootReducer

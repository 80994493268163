import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import ConfirmationCTAs from './ConfirmationCTAs'

import '../../styles/scss/components/cwc-confirmation.scss'

const FCCConfirmation = ({ resetFlow }) => {
  const confirmationData = useSelector(state => state.confirmationData?.data)

  return (
    <div className="confirmation u-white-box" data-testid="cwc-confirmation">
      <div className="u-max-text-container">
        <div className="u-text-center">
          <div className="confirmation-header">
            <div className="fcc-header">
              <h1>You're All Set!</h1>
            </div>
          </div>
        </div>
        <div className="fcc-body" data-testid="fcc-conf-copy">
          <div>
            <p>
              Your request to transfer Future Cruise Credit{' '}
              <strong>{confirmationData?.id}</strong> has been processed. No
              additional action is needed on your part. You’ll receive an email
              copy of your request as a confirmation of receipt shortly.
            </p>
            <p>
              We look forward to welcoming you clients onboard in the near
              future. Thank you for your support and loyalty.
            </p>
          </div>
        </div>
      </div>
      <ConfirmationCTAs
        resetFlow={resetFlow}
        buttonText="Transfer Another Credit"
      />
    </div>
  )
}

FCCConfirmation.defaultProps = {
  isAuthenticated: false,
}

FCCConfirmation.propTypes = {
  resetFlow: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
}

export default FCCConfirmation

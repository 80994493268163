import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import '../../styles/scss/components/las-review.scss'
import '../../styles/scss/components/lift-shift-form.scss'
import checkListIcon from '../../assets/icons/edit.svg'
import routes from '../../configuration/routes'
import TermsAndConditionsTriggerAndModal from '../modals/TermsAndConditionsTriggerAndModal'
import LiftAndShiftBookingDetails from '../liftAndShiftComponents/LiftAndShiftBookingDetails'
import LiftAndShiftCurrentDetails from '../liftAndShiftComponents/LiftAndShiftCurrentDetails'
import LiftAndShiftPreferences from '../liftAndShiftComponents/LiftAndShiftPrefrences'
import ContactContainer from '../contact/ContactContainer'
import dispatchAnalyticsEvent from '../../redux/actions/dispatchAnalyticsEvent'
import checkMark from '../../assets/icons/checkmark.png'
const LiftReview = ({
  reservationData,
  cancellationData,
  shipData,
  brandInfo,
  handleRouteChange,
  submit,
  editedAgentEmail,
  cancelRequest,
  liftAndShiftSelections,
  isAuthenticated
}) => {
  const dispatch = useDispatch()
  function back() {
    const eventName = isAuthenticated
      ? 'bookingCancellationEditInfo-agent'
      : 'bookingCancellationEditInfo-consumer'

    dispatch(
      dispatchAnalyticsEvent('bookingCancellationEditInfo', { brandInfo })
    )
    dispatch(dispatchAnalyticsEvent(eventName, { brandInfo }))

    handleRouteChange(routes.change)
  }

  const [isChecked, setIsChecked] = useState(false)
  return (
    <div
      className="u-background-white-transparent lift-shift-form-container lift-and-shift-review"
      data-testid="lift-review"
    >
      <div className="u-max-medium-container">
        <h1 className="u-bold u-text-center">Review and Submit Your Request</h1>
        <div className="u-margin-bottom-32">
          {isAuthenticated ? (
            <p className="u-text-center">
              Review the sailings you've chosen to Lift & Shift your clients'
              current booking to. If everything is correct, click the{' '}
              <strong>SUBMIT</strong> button at the bottom of the page. If you
              need to make any changes, click the EDIT button to go back to Step
              #2.
            </p>
          ) : (
            <p className="u-text-center">
              Review the sailings you've chosen to Lift & Shift your current
              booking to. If everything is correct, click the{' '}
              <strong>SUBMIT</strong> button at the bottom of the page. If you
              need to make any changes, click the EDIT button to go back to Step
              #2.
            </p>
          )}
        </div>
      </div>

      <div className="u-background-white u-border">
        <div className="u-flex-md-up">
          <div className="lift-shift-form-column">
            <LiftAndShiftBookingDetails
              reservationData={reservationData}
              bookingData={cancellationData}
              shipData={shipData}
              brandInfo={brandInfo}
            />

            <div className="u-background-white lift-shift-form-section">
              <div className="lift-shift-form-booking-info-section">
                <ContactContainer agentEmail={editedAgentEmail} showAsItems />
              </div>
            </div>
          </div>
          <div className="lift-shift-form-column">
            <div className="u-border-bottom lift-shift-form-heading">
              <h2 className="u-text-center u-bold u-remove-margin">
                Choose Preferred Sailings
              </h2>
            </div>
            <LiftAndShiftCurrentDetails reservationData={reservationData} />
            <div className="lift-and-shift-review-current-details">
              <div className="lift-shift-form-booking-info-section">
                <div className="lift-shift-form-preferences u-background-light u-border">
                  <LiftAndShiftPreferences
                    disabled={true}
                    bookingData={cancellationData}
                    selections={liftAndShiftSelections.reduce(
                      (obj, selection, i) => {
                        obj[i + 1] = {
                          ship: selection.shipCode,
                          date: selection.startDate,
                          package: selection.packageCode
                        }
                        return obj
                      },
                      {}
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="u-background-white u-border review-buttons-container">
        <div className="u-custom-terms-checkbox-container">
          <label className="u-custom-terms-checkbox-label">
            <div className="u-custom-terms-checkbox-input-container">
              <input
                type="checkbox"
                checked={isChecked}
                onChange={e => setIsChecked(e.target.checked)}
              />
              {isChecked && <img src={checkMark} alt="check" />}
            </div>

            <span>I agree to the </span>
          </label>
          <TermsAndConditionsTriggerAndModal />
        </div>
        <div className="las-review-submit-container ">
          <div className="u-text-center u-flex-md-up u-flex-justify-center">
            <button
              onClick={submit}
              disabled={!isChecked}
              className="btn btn-primary"
            >
              Submit
            </button>
            <button className="btn btn-link" onClick={back}>
              <div className="u-icon-label u-icon-label-xs">
                <img src={checkListIcon} alt="edit" />
                <span>Edit Your Information</span>
              </div>
            </button>
          </div>
          <div className="u-text-center">
            <button onClick={cancelRequest} className="btn btn-link">
              Cancel Request
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LiftReview

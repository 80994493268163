import React, { useState } from 'react'
import { ErrorMessage, Field, Formik } from 'formik'
import * as yup from 'yup'
import { capitalize } from 'lodash'

import CustomNumberInput from './CustomNumberInput'
import Modal from '../elements/Modal'
import MultiEmailInput from '../elements/MultiEmailInput'
import triggerCustomGaEvent from "../../utilities/triggerCustomGaEvent";

const MAX_OBC_AMOUNT = 500

const creditOptions = [25, 50, 100, 150, 250, 300, 500]

const formSchema = yup.object().shape(
  {
    guestId: yup
      .string()
      .required('Please select a guest that will receive the Onboard Credit'),
    amount: yup
      .number()
      .min(25, 'under')
      .max(MAX_OBC_AMOUNT, 'over')
      .required(),
    toName: yup
      .string()
      .required(
        'Please enter the guest name that will receive the Onboard Credit',
      ),
    toEmails: yup
      .array()
      .of(yup.string().email('Please enter the recipient email address'))
      .min(1, 'Please enter the recipient email address'),
    fromName: yup.string().required('Please enter the sender name'),
    fromEmail: yup
      .string()
      .email('Please enter the sender email address')
      .required('Please enter the sender email address'),
    message: yup.string(),
  },
  ['amount'],
)

const getMaxValue = (values, guests) => {
  const selectedGuest = values.guestId
    ? guests.find(guest => `${guest.id}` === values.guestId)
    : null
  return MAX_OBC_AMOUNT - (selectedGuest?.pendingAndCurrentPurchaseTotal || 0)
}

const AddGuestOBCForm = React.forwardRef(
  (
    {
      onSubmit,
      guests,
      initialValues,
      hideGuestSelect,
      submitCta = 'Add to Order',
      handleDismiss = null,
    },
    ref = null,
  ) => {
    const [exceedsLimitWarningIsOpen, setExceedsLimitWarningIsOpen] = useState(
      false,
    )

    const valuesOnMount = initialValues
      ? initialValues
      : {
          guestId: '',
          toName: '',
          toEmails: [],
          amount: 25,
          fromName: '',
          fromEmail: '',
          message: '',
        }
    return (
      <Formik
        initialValues={valuesOnMount}
        validationSchema={formSchema}
        validateOnMount
        onSubmit={values => {
          const maxValue = getMaxValue(values, guests)
          if (values.amount > maxValue) {
            setExceedsLimitWarningIsOpen(true)
            return
          }
          triggerCustomGaEvent('obc-addToOrder', values)
          onSubmit(values)
        }}
      >
        {({
          values,
          setValues,
          handleSubmit,
          touched,
          setTouched,
          setFieldValue,
          setFieldTouched,
          resetForm,
        }) => {
          const maxValue = getMaxValue(values, guests)
          return (
            <div>
              {guests?.length && !hideGuestSelect ? (
                <div className="guest-selection">
                  <button
                    style={{ display: 'none' }}
                    onClick={resetForm}
                    ref={ref}
                  />

                  <p>Who will receive the Onboard Credit?</p>
                  <select
                    value={values.guestId}
                    onChange={event => {
                      const matchingGuest = guests.find(
                        guest => `${guest.id}` === `${event.target.value}`,
                      )
                      const exceedsLimit =
                        (matchingGuest?.pendingAndCurrentPurchaseTotal || 0) >=
                        MAX_OBC_AMOUNT

                      setValues({
                        ...values,
                        guestId: event.target.value,
                        toName: matchingGuest ? matchingGuest.displayName : '',
                        toEmails: [],
                        message: '',
                        amount:
                          values.amount >
                          MAX_OBC_AMOUNT -
                            (matchingGuest?.pendingAndCurrentPurchaseTotal || 0)
                            ? 25
                            : values.amount,
                      })
                      if (exceedsLimit) {
                        setExceedsLimitWarningIsOpen(true)
                      }
                    }}
                  >
                    <option value="">Select a Guest</option>
                    {guests.map(guest => (
                      <option
                        value={guest.id}
                        key={guest.id}
                        disabled={!!guest.disabled}
                      >
                        {capitalize(guest.givenName)}{' '}
                        {capitalize(guest.surname)}
                      </option>
                    ))}
                  </select>

                  <ErrorMessage
                    name="guestId"
                    component="p"
                    className="error-message"
                  />
                </div>
              ) : null}
              <div className="amount-selection">
                <p className="title">
                  How much would you like to add on the Onboard Credit (USD)?
                </p>
                <div className="amount-input-container">
                  <div className="amount-input">
                    <CustomNumberInput
                      value={values.amount}
                      onChange={value => {
                        setValues({
                          ...values,
                          amount: value,
                        })
                      }}
                      min={25}
                      max={MAX_OBC_AMOUNT}
                      disabled={maxValue <= 0}
                      onBlur={() => {
                        setTouched(
                          {
                            ...(touched || {}),
                            amount: true,
                          },
                          true,
                        )
                      }}
                    />
                  </div>
                  <ErrorMessage
                    name="amount"
                    component="p"
                    className="error-message u-text-center u-bold u-color-error"
                    render={message => {
                      return (
                        <p className="error-message u-text-center u-bold u-color-error">
                          {message === 'under'
                            ? 'Please note, the minimum amount for an Onboard Credit purchase is $25 USD.'
                            : 'Please note, the maximum amount for an Onboard Credit purchase is $500 USD.'}
                          <br />
                          Please choose an Onboard Credit value between $25-$500
                          and try again.
                        </p>
                      )
                    }}
                  />
                </div>
                <p className="title">Or, select a pre-defined amount</p>
                <div className="credit-options">
                  {creditOptions.map(option => (
                    <div key={option}>
                      <button
                        type="button"
                        disabled={option > maxValue}
                        className={`btn ${
                          option === values.amount ? 'selected' : ''
                        }`}
                        onClick={() =>
                          setValues({
                            ...values,
                            amount: option,
                          })
                        }
                      >
                        ${option}
                      </button>
                    </div>
                  ))}
                </div>
              </div>
              <div className="contact-details">
                <p className="title">Contact Information</p>
                <div className="contact-details-inputs">
                  <div className="input-group">
                    <label>
                      <p>To</p>
                      <Field name="toName" />
                      <ErrorMessage
                        name="toName"
                        component="p"
                        className="error-message"
                      />
                    </label>
                  </div>
                  <div className="input-group">
                    <label>
                      <p>Recipient</p>
                    </label>
                    <MultiEmailInput
                      emails={values.toEmails}
                      onAddEmail={email => {
                        setFieldValue('toEmails', [...values.toEmails, email])
                      }}
                      handleRemoveEmail={({ index }) => {
                        const currentEmails = [...values.toEmails]
                        currentEmails.splice(index, 1)
                        setFieldValue('toEmails', currentEmails)
                      }}
                      onBlur={() => setFieldTouched('toEmails')}
                    />

                    <ErrorMessage
                      name="toEmails"
                      component="p"
                      className="error-message"
                    />
                  </div>
                  <div className="input-group">
                    <label>
                      <p>From</p>
                      <Field name="fromName" />
                      <ErrorMessage
                        name="fromName"
                        component="p"
                        className="error-message"
                      />
                    </label>
                  </div>
                  <div className="input-group">
                    <label>
                      <p>From Email</p>
                      <Field
                        name="fromEmail"
                        onBlur={e => {
                          setFieldValue('fromEmail', e.target.value.trim())
                        }}
                      />
                      <ErrorMessage
                        name="fromEmail"
                        component="p"
                        className="error-message"
                      />
                    </label>
                  </div>
                  <div className="input-group message">
                    <label>
                      <p>Message</p>
                      <Field name="message" as="textarea" rows="5" />
                    </label>
                  </div>
                </div>
              </div>
              <div className="submit-container">
                <>
                  <button
                    type="submit"
                    className="submit"
                    onClick={() => handleSubmit(values)}
                  >
                    {submitCta}
                    <span>+</span>
                  </button>
                  {handleDismiss && (
                    <button
                      onClick={handleDismiss}
                      className="dismiss btn btn-link"
                    >
                      Cancel Request
                    </button>
                  )}
                </>
              </div>
              <Modal
                isOpen={exceedsLimitWarningIsOpen}
                onRequestClose={() => setExceedsLimitWarningIsOpen(false)}
              >
                <div>
                  <div className="exceeds-limit-message">
                    <p>
                      The selected guest has reached the maximum Onboard Credit
                      amount allowed of (${MAX_OBC_AMOUNT} USD).
                    </p>
                    <p>Please select another guest and try again.</p>
                    {maxValue && maxValue > 0 ? (
                      <p>The maximum remaining amount is ${maxValue} USD</p>
                    ) : null}
                    <button
                      className="btn btn-oval"
                      onClick={() => setExceedsLimitWarningIsOpen(false)}
                    >
                      Dismiss
                    </button>
                  </div>
                </div>
              </Modal>
            </div>
          )
        }}
      </Formik>
    )
  },
)

export default AddGuestOBCForm

import React from 'react'

const AgentDetails = ({
  agentDetails = {},
  agencyDetails = {},
  email,
  onChangeEmail,
  emailError,
}) => {
  const { name } = agentDetails
  const { name: agencyName, phone } = agencyDetails

  return (
    <div className=" u-flex agency-details">
      <div className="agency-details-item underline">
        <p className="agency-details-label">Agency Name:</p>
        {agencyName && <p className="agency-details-value">{agencyName}</p>}
      </div>
      <div className="agency-details-item underline">
        <p className="agency-details-label">Agent Name:</p>
        {name && <p className="agency-details-value">{name}</p>}
      </div>
      {onChangeEmail ? (
        <label className="agency-details-item underline">
          <p className="agency-details-label">Email Address</p>
          <input
            type="email"
            value={email}
            onChange={e => onChangeEmail(e.target.value)}
          />
          {emailError && <p className="error">{emailError}</p>}
        </label>
      ) : (
        <div className="agency-details-item underline">
          <p className="agency-details-label">Email Address</p>
          {email && <p className="agency-details-value">{email}</p>}
        </div>
      )}
      <div className="agency-details-item underline">
        <p className="agency-details-label">Agency Phone Number:</p>
        {phone && <p className="agency-details-value">{phone}</p>}
      </div>
    </div>
  )
}

export default AgentDetails

import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import '../../styles/scss/structure/cancel-footer.scss'
import '../../styles/scss/components/shared-terms-modal-styles.scss'
import brands from '../../configuration/brands'
import { getAgencyDetails } from '../../redux/reducers/ssoData'
import Modal from '../elements/Modal'
import TermsAndConditions from '../../configuration/termsAndConditions'
import { getSelectedProgramData } from '../../redux/actions/setSelectedProgramData'
import CovidRoyalPolicy from '../../configuration/CovidRoyalPolicy'
import CovidCelebrityPolicy from '../../configuration/CovidCelebrityPolicy'
import FFTermsCWCVersion from '../../configuration/FCCTermsCWCVersion'
import FccTermsGSVersion from '../../configuration/FccTermsGSVersion'

const footerLinks = {
  agent: {
    D: [
      {
        name: 'Privacy Policy',
        link: process.env.REACT_APP_CRUISE_POWER_PRIVACY_URL,
      },
      {
        name: 'Legal Information',
        link: process.env.REACT_APP_CRUISE_POWER_LEGAL_URL,
      },
    ],
  },
  consumer: {
    D: [
      {
        name: 'Privacy Policy',
        link: 'https://www.royalcaribbean.com/resources/privacy-policy',
      },
      {
        name: 'Legal Information',
        link:
          'https://www.royalcaribbean.com/terms-and-conditions/digital-terms-of-use-and-end-user-license-agreement',
      },
    ],
    R: [
      {
        name: 'Privacy Policy',
        link: 'https://www.royalcaribbean.com/resources/privacy-policy',
      },
      {
        name: 'Legal Information',
        link:
          'https://www.royalcaribbean.com/terms-and-conditions/digital-terms-of-use-and-end-user-license-agreement',
      },
    ],
    C: [
      {
        name: 'Privacy Policy',
        link: 'https://www.celebritycruises.com/privacy-policy',
      },
      {
        name: 'Legal Information',
        link: 'https://www.celebritycruises.com/legal-information',
      },
    ],
    Z: [
      {
        name: 'Privacy Policy',
        link: 'https://www.azamara.com/privacy-policy',
      },
      {
        name: 'Legal Information',
        link: 'https://www.azamara.com/legal-information',
      },
    ],
  },
}

const CancelFooter = ({ showProgramTerms }) => {
  const selectedProgram = useSelector(getSelectedProgramData)
  const activeSearchBrand = useSelector(
    state => state.activeSearchFilters.brand,
  )

  const agencyData = useSelector(state => getAgencyDetails(state))

  const userKey = agencyData ? 'agent' : 'consumer'
  const brandKey = activeSearchBrand && !agencyData ? activeSearchBrand : 'D'

  const links = footerLinks[userKey][brandKey]
  const year = new Date().getFullYear()
  const copyWrite = activeSearchBrand
    ? brands[activeSearchBrand].copyWrite
    : `©${year} Celebrity Cruises ©${year} Royal Caribbean International. All Rights Reserved. `

  const [TCModalIsOpen, setTCModalIsOpen] = useState(false)
  const [covidTermModalState, setCovidTermModalState] = useState({
    open: false,
    brand: '',
  })
  const [fccTermModalState, setFccTermModalState] = useState({
    open: false,
    brand: '',
  })

  const openCovidTermsModal = brand =>
    setCovidTermModalState({ open: true, brand })

  const closeCovidTermsModal = () =>
    setCovidTermModalState({ open: false, brand: '' })

  const closeFccTermsAndConditions = () =>
    setFccTermModalState({ open: false, brand: '' })

  return (
    <>
      <div className="cancel-reservation__footer">
        {selectedProgram?.value === 'cvc' ? (
          <p className="footer-copy-disclaimer">
            {selectedProgram?.disclaimerFooterCopy}
          </p>
        ) : null}
        <div className="footer-links">
          <div className="u-flex u-flex-justify-center u-flex-wrap">
            {links &&
              links.map(link => (
                <a
                  className="footer-link"
                  key={link.name}
                  href={link.link}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {link.name}
                </a>
              ))}
            {showProgramTerms && selectedProgram?.value === 'cvc' && (
              <>
                <button
                  className="btn btn-link footer-link"
                  onClick={() => openCovidTermsModal('R')}
                >
                  Royal Caribbean COVID-19 Refund and Cancellation Policies
                </button>
                <button
                  className="btn btn-link footer-link"
                  onClick={() => openCovidTermsModal('C')}
                >
                  Celebrity Cruises COVID-19 Refund and Cancellation Policies
                </button>
              </>
            )}
            {/* I'm going to leave this here because I strongly suspect it may return. */}
            {/*{showProgramTerms && selectedProgram?.value === 'fcc' && (*/}
            {/*  <button*/}
            {/*    className="btn btn-link footer-link"*/}
            {/*    onClick={() => setFccTermModalState({ open: true, brand: '' })}*/}
            {/*  >*/}
            {/*    Cruise with Confidence Terms and Conditions*/}
            {/*  </button>*/}
            {/*)}*/}
          </div>
          <p className="copywrite">{copyWrite}</p>
        </div>
      </div>
      {TCModalIsOpen && (
        <Modal
          onRequestClose={() => setTCModalIsOpen(false)}
          isOpen={TCModalIsOpen}
        >
          <div className="terms-modal">
            <>
              <h1 className="u-text-center title small">
                {brandKey === 'D' ? (
                  <>
                    Royal Caribbean International and Celebrity Cruises
                    <br />
                    Cruise With Confidence Program
                    <br />
                    Terms & Conditions
                  </>
                ) : (
                  'Cruise With Confidence Terms and Conditions'
                )}
              </h1>
              <TermsAndConditions brandCode={brandKey} />
            </>

            <div className="u-text-center" style={{ padding: '38px 0' }}>
              <button
                className="btn btn-primary btn-oval with-arrow u-capitalize"
                onClick={() => setTCModalIsOpen(false)}
              >
                Return to Request Form
              </button>
            </div>
          </div>
        </Modal>
      )}
      {covidTermModalState?.open && (
        <Modal
          onRequestClose={closeCovidTermsModal}
          isOpen={covidTermModalState?.open}
        >
          <div className="terms-modal">
            <h1 className="u-text-center title">
              {covidTermModalState?.brand === 'R' ? (
                <>
                  ROYAL CARIBBEAN INTERNATIONAL
                  <br />
                  REFUND AND CANCELLATION POLICY FOR COVID-19
                </>
              ) : (
                <>
                  CELEBRITY CRUISES
                  <br />
                  REFUND AND CANCELLATION POLICY FOR COVID-19
                </>
              )}
            </h1>
            <div className="u-margin-bottom-32">
              {covidTermModalState.brand === 'R' ? (
                <CovidRoyalPolicy />
              ) : (
                <CovidCelebrityPolicy />
              )}
            </div>
          </div>
          <div className="u-flex u-flex-align-center u-flex-justify-center">
            <button
              className="btn btn-primary btn-oval with-arrow u-capitalize"
              onClick={closeCovidTermsModal}
            >
              Return to Request Form
            </button>
          </div>
        </Modal>
      )}

      {fccTermModalState.open && (
        <Modal
          onRequestClose={closeFccTermsAndConditions}
          isOpen={fccTermModalState.open}
        >
          <div className="fcc-terms terms-modal">
            <div className="u-margin-bottom-32">
              <div className="fcc-terms-header u-margin-bottom-32">
                <h1 className="u-text-center title">
                  Request for Transfer of
                  <br />
                  Future Cruise Credit (“FCC”)
                  <br />
                  Cruise with Confidence Terms and Conditions
                </h1>
              </div>
              <FFTermsCWCVersion />
            </div>
            <div className="u-margin-bottom-32">
              <div className="fcc-terms-header u-margin-bottom-32">
                <h1 className="u-text-center title">
                  Request for Transfer of <br />
                  Future Cruise Credit (“FCC”)
                  <br />
                  Global Suspension Terms and Conditions
                </h1>
              </div>
              <FccTermsGSVersion />
            </div>
            <button
              className="btn btn-primary btn-oval with-arrow u-capitalize"
              onClick={closeFccTermsAndConditions}
            >
              Return to Request Form
            </button>
          </div>
        </Modal>
      )}
    </>
  )
}

export default CancelFooter

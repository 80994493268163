import { getItinerary } from '../../api/api'
import moment from 'moment'

export const FETCH_ITINERARY = 'FETCH_ITINERARY'
export const RECEIVE_ITINERARY = 'RECEIVE_ITINERARY'

export const fetchItinerary = reqBody => dispatch => {
  dispatch({ type: FETCH_ITINERARY })

  const { id, brand, sailDate } = reqBody

  const fetchQuery = new URLSearchParams()

  fetchQuery.append('brand', brand)
  fetchQuery.append('sailDate', sailDate)
  const params = `${id}/itinerary?${fetchQuery.toString()}`

  return getItinerary(params)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: RECEIVE_ITINERARY,
          payload: {
            key: `${id}-${sailDate}`,
            itinerary: res.data.package
              ? formatItinerary(res.data.package, sailDate)
              : { hasError: true }
          }
        })
      } else {
      }
    })
    .catch(() => {
      dispatch({
        type: RECEIVE_ITINERARY,
        payload: {
          key: `${id}-${sailDate}`,
          itinerary: { hasError: true }
        }
      })
    })
}

function formatItinerary(itinerary, sailDate) {
  if (!itinerary || !sailDate || !itinerary.length) return []

  const embarkmentDay = itinerary.find(day => day.activity === 'EMBARK')

  const preCruiseDays = itinerary
    .filter(
      day =>
        day.activity === 'Cruisetour' &&
        Number(day.day) <= Number(embarkmentDay.day)
    )
    .map(day => {
      const displayDate = moment(sailDate, 'YYYY-MM-DD')
        .add(Number(day.day) - Number(embarkmentDay.day), 'days')
        .format('YYYY-MM-DD')
      return {
        ...day,
        displayDate
      }
    })
  const postCruiseDays = itinerary
    .filter(
      day =>
        day.activity === 'Cruisetour' &&
        Number(day.day) > Number(embarkmentDay.day)
    )
    .map(day => {
      const displayDate = moment(sailDate, 'YYYY-MM-DD')
        .add(Number(day.day) - Number(embarkmentDay.day), 'days')
        .format('YYYY-MM-DD')
      return {
        ...day,
        displayDate
      }
    })
  const sailingDays = itinerary
    .filter(day => day.activity !== 'Cruisetour')
    .map(day => {
      const displayDate = moment(sailDate, 'YYYY-MM-DD')
        .add(Number(day.day) - Number(embarkmentDay.day), 'days')
        .format('YYYY-MM-DD')
      return {
        ...day,
        displayDate
      }
    })
  return {
    preCruiseDays,
    postCruiseDays,
    sailingDays
  }
}

import React, { useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'
import confetti from 'canvas-confetti'

import '../../styles/scss/components/obc-confirmation.scss'
import ObcTable from '../../components/purchaseObc/ObcTable'
import { getCancellationTypeData } from '../../redux/reducers/cancellationTypeData'
import printIcon from '../../assets/icons/print-blue.svg'

const ObcConfirmation = ({ resetFlow }) => {
  const confirmationData = useSelector(state => state.confirmationData?.data)
  const cancellationData = useSelector(getCancellationTypeData)

  const isRefund = confirmationData?.confirmationType === 'refund'

  useLayoutEffect(() => {
    const isReduced =
      window?.matchMedia(`(prefers-reduced-motion: reduce)`) === true ||
      window?.matchMedia(`(prefers-reduced-motion: reduce)`)?.matches === true
    if (!isReduced) {
      window.setTimeout(
        () =>
          confetti({
            particleCount: 200,
            spread: 100,
            origin: { y: 0.6 },
          }),
        250,
      )
    }
  }, [])

  return (
    <div className="u-white-box obc-confirmation">
      <h1 className="u-text-center u-bold title">
        {isRefund
          ? 'Your Onboard Credit Modification Summary'
          : 'Your Onboard Credit Order'}
      </h1>

      {isRefund && confirmationData?.item && (
        <div className="confirmation-table refund">
          <ObcTable
            purchases={[
              {
                ...confirmationData?.item,
                status: 'Refund',
              },
            ]}
            applicableOnBoardCredit={{
              ...confirmationData?.item,
            }}
            isRefund
            creditTotalLabel="Total Refunded Onboard Credit Value"
            amountTotalLabel="Total Amount Refunded"
          />
        </div>
      )}

      {confirmationData.purchases?.length &&
      cancellationData?.applicableOnBoardCredit ? (
        <div className="confirmation-table">
          <ObcTable
            purchases={confirmationData.purchases.map(purchase => ({
              ...purchase,
              status: 'Purchased',
            }))}
            applicableOnBoardCredit={cancellationData.applicableOnBoardCredit}
            creditTotalLabel="Total Purchased Onboard Credit Value"
            amountTotalLabel="Total Amount Paid"
          />
        </div>
      ) : null}

      <div className="instructions">
        <div className="content">
          <h2>What We Are Sending</h2>
          <p className="u-margin-bottom-16">
            No additional action is required on your part. Both you and your
            client will receive an email confirmation with the onboard credit
            details. You can retrieve the reservation in Espresso to view your
            client’s onboard credit.
          </p>
          <p className="u-margin-bottom-16">
            We look forward to welcoming your clients onboard.
          </p>
          <p className="u-margin-bottom-16">
            Thank you for your support and loyalty.
          </p>
        </div>
      </div>
      <div className="ctas u-no-print">
        <button
          onClick={resetFlow}
          className="btn btn-oval royal-blue with-arrow lg"
        >
          Purchase Another OBC
        </button>
        <button className="btn btn-hollow " onClick={() => window.print()}>
          <div className="u-flex u-flex-align-center u-flex-justify-center">
            <img
              style={{ width: '16px', marginRight: '8px' }}
              src={printIcon}
              alt={'print'}
            />
            <span className="u-bold u-brand-blue u-underline">Print</span>
          </div>
        </button>
      </div>
    </div>
  )
}

export default ObcConfirmation

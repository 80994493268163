import { SET_LIFT_AND_SHIFT_PREFERENCES } from '../actions/setLiftAndShiftSelections'
import { RESET_BOOKING_FLOW } from '../actions/fetchCancellationType'

export default function(state = [], action) {
  if (action.type === SET_LIFT_AND_SHIFT_PREFERENCES) {
    return action.payload
  }
  if (action.type === RESET_BOOKING_FLOW) {
    return []
  }
  return state
}

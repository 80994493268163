import React from 'react'
import { Field } from 'formik'

import '../../styles/scss/components/covid-cancellation-bookings.scss'

const passengerLabelNumbers = ['one', 'two', 'three', 'four']

const Booking = ({
  booking = {},
  isPrimaryBooking,
  bookingIsChecked,
  selectedPassengerId = null,
  handleToggleGuest,
  children,
}) => {
  const { id, passengers = [] } = booking

  return (
    <div className="selectable-booking">
      <label className="u-flex u-margin-bottom-8">
        <Field
          name="bookingIds"
          value={id}
          className={`booking-checkbox ${isPrimaryBooking ? 'primary' : ''}`}
          checked={!!(bookingIsChecked || isPrimaryBooking)}
          readOnly={isPrimaryBooking}
          type="checkbox"
        />
        <div>
          <p className="booking-details-label booking-label highlight">
            {isPrimaryBooking ? 'Booking ' : null}
            <span className="booking-details-value booking-value highlight">
              {id}
            </span>
          </p>
        </div>
      </label>
      {passengers?.length > 0 && (
        <div
          className={`passenger-list u-flex-md-up ${
            isPrimaryBooking ? '' : 'padding-left'
          }`}
        >
          {passengers.map((passenger, index) =>
            isPrimaryBooking ? (
              <div className="passenger selectable" key={passenger.id}>
                <label htmlFor={passenger.id} className="u-flex">
                  <input
                    type="checkbox"
                    id={passenger.id}
                    name={passenger.id}
                    checked={
                      selectedPassengerId?.toString() ===
                      passenger.id?.toString()
                    }
                    className="passenger-checkbox"
                    onChange={handleToggleGuest}
                    data-testid={passenger.id}
                  />
                  <div>
                    <p className="passenger-label">
                      Guest {passengerLabelNumbers[index]}
                    </p>
                    <p className="passenger-name">{passenger.name}</p>
                  </div>
                </label>
              </div>
            ) : (
              <div className="passenger" key={passenger.id}>
                <p className="passenger-label">
                  Guest {passengerLabelNumbers[index]}
                </p>
                <p className="passenger-name">{passenger.name}</p>
              </div>
            ),
          )}
        </div>
      )}
      {children}
    </div>
  )
}

export default Booking

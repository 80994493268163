import React from 'react'
import { ErrorMessage, Field, Formik } from 'formik'
import * as yup from 'yup'
import Cleave from 'cleave.js/react'

import allCc from '../../assets/icons/all-ccs-6.png'
import amex from '../../assets/icons/amex.svg'
import mastercard from '../../assets/icons/mastercard.svg'
import visa from '../../assets/icons/visa.svg'
import discover from '../../assets/icons/discover.png'
import jcb from '../../assets/icons/jcb.png'
import diners from '../../assets/icons/diners.png'

import LoadingScreen from '../elements/LoadingScreen'

const initialValues = {
  firstName: '',
  lastName: '',
  cardNumber: '',
  expiry: '',
  cvv: '',
  zip: '',
  terms: false,
}

const schema = yup.object({
  firstName: yup
    .string()
    .required('Please enter the credit card holder first name.'),
  lastName: yup
    .string()
    .required('Please enter the credit card holder last name.'),
  cardNumber: yup.string().required('Please enter a credit card number.'),
  expiry: yup
    .string()
    .required('Please enter the credit card expiration date.'),
  cvv: yup.string().required('Please enter the CVV number.'),
  zip: yup.string().required('Please enter the Zip/Postal Code.'),
  terms: yup
    .bool()
    .oneOf(
      [true],
      'Please accept the terms and conditions to complete your purchase.',
    ),
})

// https://en.wikipedia.org/wiki/Payment_card_number#Issuer_identification_number_.28IIN.29
const renderCardImages = type => {
  switch (type) {
    case 'amex':
      return amex
    case 'mastercard':
      return mastercard
    case 'visa':
      return visa
    case 'discover':
      return discover
    case 'diners':
      return diners
    case 'jcb':
      return jcb
    default:
      return allCc
  }
}

const PaymentForm = ({
  onSubmit,
  resetFlow,
  isLoading,
  setTermsModalIsOpen,
}) => {
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {({ setFieldValue, values, handleSubmit, setFieldTouched }) => {
          return isLoading ? (
            <div>
              <LoadingScreen />
            </div>
          ) : (
            <div className="payment">
              <div className="payment-form">
                <h2 className="title">Your Payment Information</h2>
                <p className="instructions">
                  Complete Your Onboard Credit Purchase
                </p>
                <p className="section-title">Card Holder</p>
                <div className="form-fields contact ">
                  <div className="input-group">
                    <label>
                      <p>First Name</p>
                      <Field name="firstName" />
                      <ErrorMessage
                        name="firstName"
                        component="p"
                        className="error-message"
                      />
                    </label>
                  </div>
                  <div className="input-group">
                    <label>
                      <p>Last Name</p>
                      <Field name="lastName" />
                      <ErrorMessage
                        name="lastName"
                        component="p"
                        className="error-message"
                      />
                    </label>
                  </div>
                </div>
                <p className="section-title">Payment Information</p>
                <div className="form-fields card ">
                  <div className="input-group">
                    <label>
                      <p>Credit Card Number</p>
                      <div className="credit-card-field">
                        <Cleave
                          options={{
                            creditCard: true,
                            onCreditCardTypeChanged: type =>
                              setFieldValue('cardType', type),
                          }}
                          onChange={e =>
                            setFieldValue('cardNumber', e.target.value)
                          }
                          onBlur={() => setFieldTouched('cardNumber')}
                          value={values.cardNumber}
                        />
                        <div className="cc-img-container">
                          <img
                            src={renderCardImages(values.cardType)}
                            alt="credit card"
                          />
                        </div>
                      </div>
                      <ErrorMessage
                        name="cardNumber"
                        component="p"
                        className="error-message"
                      />
                    </label>
                  </div>
                  <div className="cc-details">
                    <div className="input-group expiry">
                      <label>
                        <p>Expiration Date</p>
                        <Cleave
                          options={{
                            date: true,
                            datePattern: ['m', 'y'],
                          }}
                          placeholder="MM/YY"
                          value={values.expiry}
                          onChange={e =>
                            setFieldValue('expiry', e.target.value)
                          }
                          onBlur={() => setFieldTouched('expiry')}
                        />
                      </label>
                      <ErrorMessage
                        name="expiry"
                        component="p"
                        className="error-message"
                      />
                    </div>
                    <div className="input-group cvv">
                      <label>
                        <p>CVV</p>
                        <Field name="cvv" />
                      </label>
                      <ErrorMessage
                        name="cvv"
                        component="p"
                        className="error-message"
                      />
                    </div>
                    <div className="input-group cvv">
                      <label>
                        <p>Zip / Postal Code</p>
                        <Field name="zip" />
                      </label>
                      <ErrorMessage
                        name="zip"
                        component="p"
                        className="error-message"
                      />
                    </div>
                  </div>
                </div>

                <div className="terms">
                  <div className="terms-input-group">
                    <label>
                      <Field name="terms" type="checkbox" />
                      <p>I accept the Onboard Credit </p>
                      <button
                        type="button"
                        className="btn btn-link"
                        onClick={() => setTermsModalIsOpen(true)}
                      >
                        Terms & Conditions.
                      </button>
                    </label>
                  </div>
                  <ErrorMessage
                    name="terms"
                    component="p"
                    className="error-message"
                  />
                </div>
                <div className="ctas">
                  <button
                    className="btn btn-oval royal-blue with-arrow lg"
                    type="button"
                    onClick={values => handleSubmit(values)}
                  >
                    Complete My Purchase
                  </button>
                  <button
                    className="btn btn-link"
                    onClick={resetFlow}
                    type="button"
                  >
                    Cancel request
                  </button>
                </div>
              </div>
            </div>
          )
        }}
      </Formik>
    </>
  )
}

export default PaymentForm

import React from 'react'
import checkIcon from '../../assets/icons/checklist.svg'
import { ListItem } from '../reservationReview/ReviewItemsList'

const AgencyDetails = ({
  agentDetails,
  cancellationData,
  agentEmail,
  setAgentEmail,
  agentEmailError,
  setAgentEmailError,
  isDirectBooking,
  guestEmail,
  guestPhone,
  isFlex,
  showAsItems,
  isSpanish,
}) => {
  function getDefaultValue(key) {
    if (
      cancellationData &&
      cancellationData[key] &&
      (cancellationData[key].length || cancellationData[key] > 0)
    ) {
      return cancellationData[key]
    }
    return null
  }

  const defaultAgencyPhone = getDefaultValue('agencyPhone')
  const defaultAgencyName = getDefaultValue('agencyName')

  return (
    <div>
      <div className="u-icon-label">
        <img src={checkIcon} alt={''} />
        <p className="u-bold u-brand-blue">
          {isSpanish ? 'INFORMACIÓN DE LA AGENCIA' : 'Agency Information'}
        </p>
      </div>
      <div
        className={`contact-details-flex-container ${
          isFlex ? 'u-flex-md-up u-flex-wrap ' : ''
        }`}
      >
        {defaultAgencyName && (
          <>
            {showAsItems ? (
              <ListItem
                item={{
                  label: isSpanish ? 'Nombre de agencia:' : 'Agency Name:',
                  value: defaultAgencyName,
                }}
                borderBottom
              />
            ) : (
              <div
                className={`contact-details-item ${isFlex ? 'max-width' : ''}`}
              >
                <label className="u-input-label u-full-width">
                  <span>
                    {isSpanish ? 'Nombre de agencia:' : 'Agency Name:'}
                  </span>
                  <input
                    className="contact-details-item-value u-brand-blue u-bold"
                    readOnly
                    value={defaultAgencyName}
                  />
                </label>
              </div>
            )}
          </>
        )}
        {agentDetails && agentDetails.name && (
          <>
            {showAsItems ? (
              <ListItem
                item={{
                  label: isSpanish ? 'Nombre de la agente:' : 'Agent Name:',
                  value: agentDetails.name,
                }}
                borderBottom
              />
            ) : (
              <div
                className={`contact-details-item ${isFlex ? 'max-width' : ''}`}
              >
                <label className="u-input-label u-full-width">
                  <span>
                    {isSpanish ? 'Nombre de la agente:' : 'Agent Name:'}
                  </span>
                  <input
                    className="contact-details-item-value u-brand-blue u-bold"
                    readOnly
                    value={agentDetails.name}
                  />
                </label>
              </div>
            )}
          </>
        )}
        {isDirectBooking ? (
          <>
            {guestEmail && (
              <>
                {showAsItems ? (
                  <ListItem
                    item={{
                      label: isSpanish
                        ? 'Correo electrónico de invitado:'
                        : 'Guest Email:',
                      value: guestEmail,
                    }}
                    borderBottom
                  />
                ) : (
                  <div
                    className={`contact-details-item ${
                      isFlex ? 'max-width' : ''
                    }`}
                  >
                    <label className="u-input-label u-full-width">
                      <span>
                        {isSpanish
                          ? 'Correo electrónico de invitado:'
                          : 'Guest Email:'}
                      </span>
                      <input
                        className="contact-details-item-value u-brand-blue u-bold"
                        readOnly
                        value={guestEmail}
                      />
                    </label>
                  </div>
                )}
              </>
            )}
            {guestPhone && (
              <>
                {showAsItems ? (
                  <ListItem
                    item={{
                      label: isSpanish
                        ? 'Número de teléfono de invitado:'
                        : 'Guest Phone Number:',
                      value: guestPhone,
                    }}
                    borderBottom
                  />
                ) : (
                  <div
                    className={`contact-details-item ${
                      isFlex ? 'max-width' : ''
                    }`}
                  >
                    <label className="u-input-label u-full-width">
                      <span>
                        {isSpanish
                          ? 'Número de teléfono de invitado:'
                          : 'Guest Phone Number:'}
                      </span>
                      <input
                        className="contact-details-item-value u-brand-blue u-bold"
                        readOnly
                        value={guestPhone}
                      />
                    </label>
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <>
            {agentDetails && (
              <>
                {showAsItems ? (
                  <ListItem
                    item={{
                      label: isSpanish
                        ? 'Dirección de correo electrónico del agente:'
                        : 'Agent Email Address:',
                      value: agentEmail,
                    }}
                    borderBottom
                  />
                ) : (
                  <div
                    className={`contact-details-item ${
                      isFlex ? 'max-width' : ''
                    }`}
                  >
                    <label className="u-input-label u-full-width">
                      <span>
                        {isSpanish
                          ? 'Dirección de correo electrónico del agente:'
                          : 'Agent Email Address:'}
                      </span>
                      <input
                        className="contact-details-item-value u-brand-blue u-bold"
                        type="email"
                        required={Boolean(setAgentEmail)}
                        readOnly={!Boolean(setAgentEmail)}
                        value={agentEmail ? agentEmail : ''}
                        onChange={e => {
                          if (setAgentEmail) {
                            if (agentEmailError) {
                              setAgentEmailError(false)
                            }
                            setAgentEmail(e.target.value)
                          }
                        }}
                      />
                    </label>
                    {agentEmailError && (
                      <span style={{ color: 'red', fontSize: '14px' }}>
                        {isSpanish
                          ? 'Por favor introduzca una dirección de correo electrónico válida'
                          : 'Please enter a valid email'}
                      </span>
                    )}
                  </div>
                )}
              </>
            )}
            {defaultAgencyPhone && (
              <>
                {showAsItems ? (
                  <ListItem
                    item={{
                      label: isSpanish
                        ? 'Número de teléfono de la agencia:'
                        : 'Agency Phone Number:',
                      value: defaultAgencyPhone,
                    }}
                    borderBottom
                  />
                ) : (
                  <div
                    className={`contact-details-item ${
                      isFlex ? 'max-width' : ''
                    }`}
                  >
                    <label className="u-input-label u-full-width">
                      <span>
                        {isSpanish
                          ? 'Número de teléfono de la agencia:'
                          : 'Agency Phone Number:'}
                      </span>
                      <input
                        className="contact-details-item-value u-brand-blue u-bold"
                        readOnly
                        value={defaultAgencyPhone}
                      />
                    </label>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default AgencyDetails

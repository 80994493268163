import React from 'react'
import PropTypes from 'prop-types'
import printIcon from '../../assets/icons/print-blue.svg'

const ConfirmationCTAs = ({ resetFlow, buttonText }) => {
  return (
    <div className="u-flex confirmation-button-container u-no-print">
      <div>
        <button
          className="btn btn-oval with-arrow u-uppercase confirmation-button-submit"
          onClick={resetFlow}
        >
          {buttonText ? buttonText : 'Submit Another Booking'}
        </button>
      </div>
      <div>
        <button
          className="btn btn-hollow u-no-print"
          onClick={() => window.print()}
        >
          <div className="u-flex u-flex-align-center u-flex-justify-center">
            <img
              style={{ width: '16px', marginRight: '8px' }}
              src={printIcon}
              alt={'print'}
            />
            <span className="u-bold u-brand-blue u-underline">Print</span>
          </div>
        </button>
      </div>
    </div>
  )
}

ConfirmationCTAs.propTypes = {
  resetFlow: PropTypes.func.isRequired
}

export default ConfirmationCTAs

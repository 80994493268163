import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { uniqBy } from 'lodash'

import { Field, Formik } from 'formik'
import * as Yup from 'yup'

import '../../styles/scss/components/covid-cancellation-form.scss'
import '../../styles/scss/components/shared-terms-modal-styles.scss'

import BookingDetails from './BookingDetails'
import PassengerDocuments from './PassengerDocuments'
import {
  getAgencyDetails,
  getAgentDetails,
  getAgentUserId,
} from '../../redux/reducers/ssoData'

import arrowRight from '../../assets/icons/arrow-right.svg'
import Modal from '../elements/Modal'
import Booking from './Booking'
import { selectBrandsData } from '../../redux/reducers/brand'
import moment from 'moment'
import { selectShipsData } from '../../redux/reducers/ships'
import LoadingScreen from '../elements/LoadingScreen'
import { postCovidCancellation } from '../../api/api'
import { RECEIVE_CANCELLATION_CONFIRMATION } from '../../redux/actions/cancelResevation'
import documentIcon from '../../assets/icons/document.svg'
import testIcon from '../../assets/icons/test-date.svg'
import DatePicker from '../reservationForms/DatePicker'
import formats from '../../configuration/formats'
import CvcTermsAndConditions from '../reservationReview/CvcTermsAndConditions'
import AgentDetails from './AgentDetails'
import AgencyDetails from '../contact/AgencyDetails'

const ErrorMessage = ({
  errors,
  touched,
  name,
  messageClassName = '',
  containerClassName = '',
}) => {
  return errors?.[name] && touched?.[name] ? (
    <div className={containerClassName}>
      <p
        className={`error u-margin-y-8 ${messageClassName}`}
        style={{ marginTop: '10px' }}
      >
        {errors[name]}
      </p>
    </div>
  ) : null
}

const isDateInRange = ({ testDate, sailDate }) => {
  return (
    testDate &&
    moment(testDate).isBefore(moment(sailDate), 'days') &&
    moment(testDate).isAfter(
      moment(sailDate)
        .clone()
        .subtract(11, 'days'),
    )
  )
}

const CovidCancellationForm = ({
  resetFlow,
  reservationData = {},
  onSubmitSuccess,
}) => {
  const brands = useSelector(selectBrandsData)
  const ships = useSelector(selectShipsData)
  const agentDetails = useSelector(getAgentDetails)
  const agencyDetails = useSelector(getAgencyDetails)
  const userId = useSelector(getAgentUserId)
  const sessionId = useSelector(state => state.sessionId)

  const dispatch = useDispatch()

  const {
    id,
    brandCode,
    sailDate,
    shipCode,
    guests,
    linkedBookings = [],
  } = reservationData
  const brandData = brands?.find(brand => brand.brand_code === brandCode)
  const shipData = ships?.find(ship => ship.ship_code === shipCode)
  const testDateValidationMessage = `The testing date entered is not eligible for the COVID-19 Cancellation program. 
    The guest's positive result must be obtained from a test conducted within 10 days of embarkation.
     Please check your entry and try again.`

  const primaryBooking = {
    id,
    brand: brandData?.name,
    sailDate: sailDate ? moment(sailDate).format('DD MMM YYYY') : '',
    ship: shipData?.name || '',
    passengers:
      guests?.map(guest => ({
        ...guest,
        name: `${guest.givenName} ${guest.surname}`,
      })) || [],
  }

  const formattedLinkedBookings =
    uniqBy(
      linkedBookings?.map(booking => ({
        ...booking,
        id: booking.id,
        passengers:
          booking.guests?.map(guest => ({
            ...guest,
            name: `${guest.givenName} ${guest.surname}`,
          })) || [],
      })),
      'id',
    ).filter(booking => !booking.error) || []

  const [formValues, setFormValues] = useState(null)
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false)

  const [submitState, setSubmitState] = useState({
    loading: false,
    error: null,
  })

  const submit = () => {
    setConfirmationModalIsOpen(false)
    setSubmitState({
      loading: true,
      error: null,
    })
    const params = {
      id: primaryBooking.id,
      agencyId: agencyDetails?.agency_id || '',
      agentEmail: formValues.agentEmail,
      sessionId,
      linkedBookingIds: formValues.bookingIds,
      guests: [
        {
          id: formValues.selectedGuest,
          testDate: formValues.testDate,
          images: [formValues?.documents?.[0]?.value || ''], // We currently only allow one document but this could change.
        },
      ],
    }

    if (userId) {
      params.userId = userId
    }

    return postCovidCancellation(params)
      .then(res => {
        const data = res?.data?.['hydra:member']?.[0]
        if (!data?.success) {
          throw new Error('Unexpected error')
        }

        dispatch({
          type: RECEIVE_CANCELLATION_CONFIRMATION,
          payload: {
            ...data,
            linkedBookings: formValues.bookingIds,
          },
        })
        onSubmitSuccess()
      })
      .catch(err => {
        setSubmitState({
          loading: false,
          error:
            err?.response?.data?.['hydra:description'] ||
            err?.message ||
            'Something went wrong.',
        })
      })
  }

  const confirmSubmit = values => {
    setFormValues(values)
    setConfirmationModalIsOpen(true)
  }

  const dismissSubmit = () => {
    setFormValues(null)
    setConfirmationModalIsOpen(false)
  }

  return (
    <div
      className="u-background-white covid-cancellation-form"
      data-testid="covid-cancellation-form"
    >
      <div>
        <Formik
          initialValues={{
            bookingIds: [],
            documents: [],
            acknowledgment: false,
            selectedGuest: '',
            testDate: '',
            agentEmail: agentDetails?.email || '',
          }}
          validationSchema={Yup.object().shape({
            acknowledgment: Yup.bool().when('bookingIds', {
              is: value => value.length,
              then: Yup.bool().test(
                'is-true',
                'This acknowledgement is required',
                val => !!val,
              ),
              otherwise: Yup.bool(),
            }),
            bookingIds: Yup.array().of(Yup.string()),
            selectedGuest: Yup.string().required(
              'You must select at least one guest',
            ),
            documents: Yup.array()
              .of(Yup.object().shape({}))
              .min(1, 'Please upload a COVID Test'),
            testDate: Yup.date()
              .required('Please enter a COVID Test Date')
              .test('is-in-range', testDateValidationMessage, val =>
                isDateInRange({ testDate: val, sailDate }),
              ),
            agentEmail: Yup.string()
              .email('Please enter a valid email')
              .required('Please enter an email'),
          })}
          onSubmit={confirmSubmit}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            handleSubmit,
            setFieldTouched,
          }) => (
            <form onSubmit={handleSubmit}>
              {submitState.loading ? (
                <div>
                  <LoadingScreen />
                </div>
              ) : (
                <>
                  <p className="sub-head-copy">
                    PRIMARY BOOKING (Stateroom of COVID positive guest)
                  </p>
                  <p className="notice-sentence">
                    Select the guest(s) that tested positive for COVID-19.
                    Please note you will be required to provide proof of a
                    positive test result.
                  </p>
                  <p className="u-margin-bottom-32">
                    <strong>Note:</strong> Upon submitting this request, the
                    cruise reservation of all guests booked in the COVID
                    positive guest‘s stateroom will be cancelled. This tool
                    cannot be used to remove select guest(s) from a booking or
                    to change names on a booking.
                  </p>
                  {primaryBooking && (
                    <>
                      <BookingDetails reservation={primaryBooking} />
                      <Booking
                        booking={primaryBooking}
                        isPrimaryBooking
                        handleToggleGuest={e => {
                          setFieldValue('selectedGuest', e.target.name)
                          setFieldValue('documents', [])
                        }}
                        selectedPassengerId={values.selectedGuest}
                      >
                        <>
                          <ErrorMessage
                            name="selectedGuest"
                            touched={touched}
                            errors={errors}
                          />
                          {values.selectedGuest ? (
                            <div
                              className="passenger-documents"
                              data-testid="passenger-documents"
                            >
                              <p className="detail-names">
                                Please enter the COVID-19 test date and upload
                                the COVID-19 test report for{' '}
                                <strong>
                                  {
                                    primaryBooking.passengers.find(
                                      passenger =>
                                        passenger.id?.toString() ===
                                        values.selectedGuest?.toString(),
                                    )?.name
                                  }
                                </strong>
                                , showing a positive result from a test taken
                                within 10 days of the embarkation date.
                              </p>
                              <div className="passenger-boxes">
                                <div data-testid="passenger-box-2">
                                  <div className="date-container">
                                    <p className="doc-title">
                                      <img
                                        className="document"
                                        src={testIcon}
                                        alt={''}
                                      />
                                      COVID Test Date
                                    </p>

                                    <div>
                                      <DatePicker
                                        handleDateChange={date => {
                                          setFieldTouched(
                                            'testDate',
                                            true,
                                            true,
                                          )
                                          setFieldValue(
                                            'testDate',
                                            date
                                              ? date.format(formats.date)
                                              : '',
                                          )
                                        }}
                                        date={
                                          values.testDate
                                            ? moment(
                                                values.testDate,
                                                formats.date,
                                              )
                                            : null
                                        }
                                        disabled={false}
                                      />
                                    </div>
                                    <ErrorMessage
                                      name="testDate"
                                      errors={errors}
                                      touched={touched}
                                    />
                                  </div>
                                </div>
                                <div data-testid="passenger-box-1">
                                  <div className="u-flex u-flex-column u-flex-align-center">
                                    <p className="doc-title">
                                      <img
                                        className="document"
                                        src={documentIcon}
                                        alt={''}
                                      />
                                      COVID Test Upload
                                    </p>

                                    <PassengerDocuments
                                      abilityToBrowseFile={
                                        !!(
                                          values?.testDate && !errors?.testDate
                                        )
                                      }
                                      guestDetails={primaryBooking.passengers.find(
                                        passenger =>
                                          passenger.id?.toString() ===
                                          values.selectedGuest?.toString(),
                                      )}
                                      currentFiles={values.documents}
                                      handleAddUpdateDocuments={documents => {
                                        setFieldValue('documents', documents)
                                      }}
                                      handleRemoveImage={id => {
                                        setFieldValue(
                                          'documents',
                                          values.documents?.filter(
                                            doc => doc.preview !== id,
                                          ) || [],
                                        )
                                      }}
                                      renderError={() => (
                                        <ErrorMessage
                                          name="documents"
                                          errors={errors}
                                          touched={touched}
                                        />
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </>
                      </Booking>

                      {formattedLinkedBookings?.length > 0 && (
                        <>
                          <div role="group">
                            <div className="related-booking-copy">
                              <p className="related-booking-title">
                                Related Bookings
                              </p>
                              <p className="related-booking-text">
                                The following are additional stateroom
                                reservations linked to the COVID positive
                                guest’s reservation.
                              </p>
                              <p className="related-booking-text">
                                If all guests named on a linked stateroom
                                reservation(s){' '}
                                <strong>are family members</strong> of the COVID
                                positive guest identified in Step 1,{' '}
                                <strong>living in the same household</strong> as
                                that COVID positive guest, they too will be
                                eligible for a refund under our COVID-19 Refund
                                and Cancellation Policies, and you may use this
                                tool to cancel those additional reservations on
                                your clients’ behalf. If your clients’ wish to
                                cancel any of these additional stateroom
                                reservations, please select the booking number
                                of the reservation(s) to be cancelled. Note
                                that, upon submitting this request, the
                                reservation(s) of all guests booked in any
                                additional stateroom(s) selected will be
                                cancelled. This tool cannot be used to remove
                                select guest(s) from a booking or to change
                                names on a booking.
                              </p>
                              <p className="related-booking-text">
                                If any guest named on a linked stateroom
                                reservation is not a family member of the COVID
                                positive guest identified in Step 1, living in
                                the same household as that positive guest, those
                                guests may be subject to 100% cancellation
                                charges and you should not use this tool to
                                cancel their reservation as part of this
                                request.
                              </p>
                            </div>
                            {formattedLinkedBookings.map(booking => (
                              <Booking
                                key={booking.id}
                                booking={booking}
                                bookingIsChecked={values?.bookingIds?.some(
                                  id =>
                                    id?.toString() === booking.id?.toString(),
                                )}
                              />
                            ))}
                          </div>
                          {values?.bookingIds?.length ? (
                            <div className="acknowledgment">
                              <label className="u-flex">
                                <Field type="checkbox" name="acknowledgment" />
                                <p>
                                  I represent and warrant to the cruise line, on
                                  behalf of my clients, that all guests named in
                                  the additional staterooms being cancelled are
                                  family members of the COVID positive guest
                                  identified in Step 1, living in the same
                                  household as that COVID positive guest.
                                </p>
                              </label>
                              <ErrorMessage
                                name="acknowledgment"
                                touched={touched}
                                errors={errors}
                              />
                            </div>
                          ) : null}
                        </>
                      )}
                      <div className="agency">
                        <div className="agency-top">
                          <AgencyDetails />
                        </div>
                        <div className="agency-bottom">
                          <AgentDetails
                            agentDetails={agentDetails || {}}
                            agencyDetails={agencyDetails || {}}
                            email={values?.agentEmail}
                            onChangeEmail={value =>
                              setFieldValue('agentEmail', value)
                            }
                            emailError={
                              touched.agentEmail && errors.agentEmail
                                ? errors.agentEmail
                                : null
                            }
                          />
                        </div>
                      </div>
                    </>
                  )}
                  <div className="button-dev">
                    <button
                      type="submit"
                      className="submit-button btn"
                      disabled={!!Object.keys(errors || {}).length}
                    >
                      Submit Request
                      <img
                        className="arrow"
                        src={arrowRight}
                        alt="Submit Request"
                      />
                    </button>
                    <button
                      onClick={e => {
                        e.preventDefault()
                        resetFlow()
                      }}
                      type="reset"
                      className="btn btn-link u-uppercase"
                    >
                      Cancel Request
                    </button>
                  </div>
                </>
              )}
            </form>
          )}
        </Formik>
      </div>
      <Modal onRequestClose={dismissSubmit} isOpen={confirmationModalIsOpen}>
        <div className="terms-modal">
          <CvcTermsAndConditions
            submit={submit}
            dismissSubmit={dismissSubmit}
          />
        </div>
      </Modal>
      <Modal
        onRequestClose={() => {
          setSubmitState({
            ...submitState,
            error: null,
          })
        }}
        isOpen={!!submitState.error}
      >
        <div>
          <h2>Error:</h2>
          <p className="u-margin-bottom-16">{submitState.error}</p>
          <button
            className="btn btn-primary btn-oval"
            onClick={() => setSubmitState({ ...submitState, error: null })}
          >
            Close
          </button>
        </div>
      </Modal>
    </div>
  )
}

export default CovidCancellationForm

import React from 'react'

const BookingDetails = ({ reservation }) => {
  const { brand, sailDate, ship } = reservation
  return (
    <div className="u-flex-md-up booking-details">
      <div className="booking-detail-item">
        <p className="booking-details-title">Sailing Information</p>
      </div>
      <div className="booking-detail-item">
        <p className="booking-details-label">Brand</p>
        <p className="booking-details-value">{brand}</p>
      </div>
      <div className="booking-detail-item">
        <p className="booking-details-label">Ship</p>
        <p className="booking-details-value">{ship}</p>
      </div>
      <div className="booking-detail-item">
        <p className="booking-details-label">Sailing Date</p>
        <p className="booking-details-value">{sailDate}</p>
      </div>
    </div>
  )
}

export default BookingDetails

import React from 'react'

export default function formatPrice(price, enableSup = true, additionalSup) {
  let priceParts = Number(price)
    .toFixed(2)
    .split('.')

  if (!enableSup && !additionalSup) {
    return (
      <>
        {Number(priceParts[0]).toLocaleString()}.{priceParts[1]}
      </>
    )
  }

  if (!enableSup && additionalSup) {
    return (
      <>
        {Number(priceParts[0]).toLocaleString()}.{priceParts[1]}
        <sup>{additionalSup}</sup>
      </>
    )
  }

  return (
    <>
      {Number(priceParts[0]).toLocaleString()}.
      <sup>
        {priceParts[1]}
        {additionalSup ? additionalSup : null}
      </sup>
    </>
  )
}

export function formatPriceAsString(price) {
  if (!price) return `0.00`

  let priceParts = Number(price)
    .toFixed(2)
    .split('.')

  return `${Number(priceParts[0]).toLocaleString()}.${priceParts[1]}`
}

import { REQUEST_BRANDS, RECEIVE_BRANDS, BRANDS_ERROR } from '../actions/brands'

const initialState = {
  loading: false,
  error: false
}

export default function(state = initialState, action) {
  switch (action.type) {
    case REQUEST_BRANDS:
      return Object.assign({}, state, {
        loading: true
      })
    case RECEIVE_BRANDS:
      return Object.assign({}, state, {
        loading: false,
        error: false,
        data: action.payload
      })
    case BRANDS_ERROR:
      return Object.assign({}, state, {
        loading: false,
        error: true
      })
    default:
      return state
  }
}

export const selectBrandsData = state => state?.brands?.data || null

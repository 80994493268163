import React, { useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
import { useDispatch } from 'react-redux'

import arrowIcon from '../../assets/icons/arrow-right.svg'
import Modal from '../elements/Modal'

import '../../styles/scss/components/fcc-review.scss'
import FccTermsAndConditions from './FccTermsAndConditions'
import { postFccTransfer } from '../../api/api'
import { RECEIVE_CANCELLATION_CONFIRMATION } from '../../redux/actions/cancelResevation'
import LoadingScreen from '../elements/LoadingScreen'
import FCCReceipt from './FCCReceipt'

const FCCReview = ({ data = {}, agentEmail, resetFlow, onSubmitSuccess }) => {
  const dispatch = useDispatch()
  const {
    currencyCode,
    expiryDate,
    id,
    issuedFirstName,
    issuedLastName,
    pricingInfo,
    sailByDate,
    issuedReservationId,
    issueReason,
    issuedEmailAddress,
    issuedShipBrand,
  } = data
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false)
  const [formValues, setFormValues] = useState(null)
  const [submitState, setSubmitState] = useState({
    loading: false,
    error: null,
  })
  const shipBrand =
    issuedShipBrand === 'RCC'
      ? 'Royal Caribbean'
      : issuedShipBrand === 'CEL'
      ? 'Celebrity'
      : issuedShipBrand

  // https://jira.verbinteractive.com/browse/RCL202-472
  const remainingBalance = pricingInfo?.find(info => +info?.code === 5)?.amount
  const originalCredit = pricingInfo?.find(info => +info?.code === 1)?.amount

  const total = !isNaN(remainingBalance)
    ? remainingBalance
    : originalCredit || 0

  const submit = () => {
    setSubmitState({
      error: null,
      loading: true,
    })
    setConfirmationModalIsOpen(false)
    const params = {
      issuedFirstName,
      issuedLastName,
      transferredFirstName: formValues.firstName,
      transferredLastName: formValues.lastName,
      id,
      bookingId: issuedReservationId,
      agentEmail,
      issuedEmailAddress: issuedEmailAddress || '',
    }

    return postFccTransfer(params)
      .then(res => {
        const data = res?.data?.['hydra:member']?.[0]
        if (!data?.success) {
          throw new Error('Unexpected error')
        }

        dispatch({
          type: RECEIVE_CANCELLATION_CONFIRMATION,
          payload: {
            ...data,
            issuedFirstName,
            issuedLastName,
            transferredFirstName: formValues.firstName,
            transferredLastName: formValues.lastName,
            id,
          },
        })
        onSubmitSuccess()
      })
      .catch(err => {
        setSubmitState({
          loading: false,
          error:
            err?.response?.data?.['hydra:description'] ||
            err?.message ||
            'Something went wrong.',
        })
      })
  }

  const stageSubmit = values => {
    setFormValues(values)
    setConfirmationModalIsOpen(true)
  }

  const renderError = ({ errors, touched, name }) => {
    return errors?.[name] && touched?.[name] ? (
      <p className="error u-color-error">{errors[name]}</p>
    ) : null
  }

  const dismissSubmit = () => {
    setFormValues(null)
    setConfirmationModalIsOpen(false)
  }

  return (
    <div className="fcc-container" data-testid="fcc-review-container">
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
        }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string().required('Please enter First Name'),
          lastName: Yup.string().required('Please enter Last Name'),
        })}
        onSubmit={stageSubmit}
      >
        {({ handleChange, errors, touched, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div
              className="u-flex u-flex-column u-flex-align-center fcc-review"
              data-testid="fcc-review-form"
            >
              <div className="fcc-review-section u-flex u-flex-column  u-full-width">
                <h1
                  className="fcc-review-header header-display u-uppercase"
                  data-testid="original-recipient-information"
                >
                  Original Recipient Information
                </h1>
                <FCCReceipt
                  guestName={`${issuedFirstName || ''} ${issuedLastName || ''}`}
                  amount={total}
                  currency={currencyCode ? currencyCode : ''}
                  certificateNumber={id}
                  bookingId={issuedReservationId}
                  sailByDate={
                    sailByDate
                      ? moment(sailByDate).format('MMM. DD, YYYY')
                      : null
                  }
                  expirationDate={
                    expiryDate
                      ? moment(expiryDate).format('MMM. DD, YYYY')
                      : null
                  }
                  shipBrand={shipBrand}
                  typeDetail={issueReason}
                />
              </div>
              <div
                className="recipient-information-section u-full-width"
                data-testid="fcc-review-form-recipient-information-section"
              >
                <h1 className="fcc-review-header u-remove-margin u-uppercase">
                  Transferee's Information
                </h1>
                <p>
                  Enter the first and last name of the individual the
                  certificate is being transferred to.
                </p>
                <div className="u-flex-md-up">
                  <div>
                    <label>
                      <p className="u-bold">First Name</p>
                      <input name="firstName" onChange={handleChange} />
                    </label>
                    {renderError({ touched, errors, name: 'firstName' })}
                  </div>
                  <div className="second-label">
                    <label>
                      <p className="u-bold">Last Name</p>
                      <input name="lastName" onChange={handleChange} />
                    </label>
                    {renderError({ touched, errors, name: 'lastName' })}
                  </div>
                </div>
              </div>
              <div className="recipient-information-section-bottom u-flex u-flex-column">
                {submitState.loading ? (
                  <LoadingScreen />
                ) : (
                  <div className="button-container">
                    <button type="submit" className="btn btn-oval u-uppercase">
                      Submit Request
                      <img src={arrowIcon} alt="" />
                    </button>
                    <button
                      onClick={e => {
                        e.preventDefault()
                        resetFlow()
                      }}
                      type="reset"
                      className="btn btn-link u-uppercase"
                    >
                      Cancel Request
                    </button>
                  </div>
                )}
              </div>
            </div>
          </form>
        )}
      </Formik>
      <Modal onRequestClose={dismissSubmit} isOpen={confirmationModalIsOpen}>
        <FccTermsAndConditions
          submit={submit}
          dismissSubmit={dismissSubmit}
          issueReason={issueReason}
        />
      </Modal>
      <Modal
        onRequestClose={() => setSubmitState({ ...submitState, error: null })}
        isOpen={!!submitState.error}
      >
        <div>
          <h2>Error:</h2>
          <p className="u-margin-bottom-16">{submitState.error}</p>
          <button
            className="btn btn-primary btn-oval"
            onClick={() => setSubmitState({ ...submitState, error: null })}
          >
            Close
          </button>
        </div>
      </Modal>
    </div>
  )
}

export default FCCReview

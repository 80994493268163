import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import '../../styles/scss/components/bpg-review.scss'
import { RESET_BOOKING_FLOW } from '../../redux/actions/fetchCancellationType'
import routes from '../../configuration/routes'
import brands from '../../configuration/brands'

import ContactContainer from '../contact/ContactContainer'
import BPGPricingInfo from './BPGPricingInfo'
import BPGBookingInfo from './BPGBookingInfo'
import releaseActiveBooking from '../../redux/actions/releaseActiveBooking'
import { selectShipsData } from '../../redux/reducers/ships'

import Modal from '../elements/Modal'
import BpgTermsAndConditions from './BpgTermsAndConditions'

import checkMark from '../../assets/icons/checkmark.png'
import TermsAndConditionsTriggerAndModal from '../modals/TermsAndConditionsTriggerAndModal'
import { isBpgReviewOnly } from '../../redux/reducers/cancellationTypeData'

/*
 * ONLY IFO0489 is considered a successful state
 * IFO0489 = Congratulations! Your clients are entitled to a Non-Refundable Onboard Credit <<amount>> USD OBC
 * IFO0490 = Congratulations! Your clients already have the Best Rate.
 * IFO0492 = Congratulations! Your clients already have the Best Value.
 * IFO0491 = Congratulations! Your clients already received the Non-Refundable Onboard Credit <<amount>> USD OBC
 * */

/*
 * flow: "CWC" or "GS"
 * */

const BPGReview = ({
  handleRouteChange,
  reservationData,
  cancellationData,
  agentEmail,
  setAgentEmail,
  agentEmailError,
  setAgentEmailError,
  submit,
}) => {
  const ships = useSelector(selectShipsData)
  // https://jira.verbinteractive.com/browse/RCL202-529
  const isReviewOnly = useSelector(isBpgReviewOnly)

  const shipData =
    reservationData && reservationData.shipCode
      ? ships.find(ship => ship['ship_code'] === reservationData.shipCode)
      : null
  const brandInfo = shipData ? brands[shipData['brand_code']] : null
  const brandName = brandInfo ? brandInfo.name : 'Royal Caribbean'

  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false)
  const [agreedToTerms, setTermsAgreement] = useState(false)
  const dispatch = useDispatch()
  const cancelRequest = () => {
    if (cancellationData && cancellationData.id) {
      dispatch(releaseActiveBooking(cancellationData.id))
    }
    dispatch({ type: RESET_BOOKING_FLOW })
    handleRouteChange(routes.cancellationForm)
  }

  const warningCode = reservationData ? reservationData.warningCode : null

  const isSuccess = warningCode === 'IFO0489'
  const shouldShowSuccessDisclaimer =
    !isReviewOnly &&
    (warningCode === 'IFO0489' ||
      warningCode === 'IFO0491' ||
      warningCode === 'IFO0493')

  // in this case we present the modal before submit and no terms and conditions checkbox.
  const isCWC = cancellationData?.flow === 'CWC'
  const requiresTerms = isCWC

  const dismissSubmit = () => {
    setConfirmationModalIsOpen(false)
  }
  const stageSubmit = () => {
    if (isCWC) {
      submit()
    } else {
      setConfirmationModalIsOpen(true)
    }
  }
  return (
    <div className="bpg-review-container ">
      <div className="u-max-medium-container">
        <h4 className={'u-text-center u-bold'}>
          {isReviewOnly ? 'REVIEW' : 'REVIEW AND SUBMIT'}
        </h4>
        <p className="bpg-review-description">
          {isReviewOnly ? (
            <>
              Please compare the current rate below against the prevailing rate
              to determine whether the price has changed and the booking is
              eligible for a price adjustment. Once you’ve completed your
              review, you may click the button at the bottom of the page to
              check another reservation.
            </>
          ) : (
            <>
              Before you submit your request, please make sure you review all
              the information below and verify that everything is correct. Once
              you’ve completed your review, click the SUBMIT button at the
              bottom of the page.'
            </>
          )}
        </p>
      </div>

      <div className="bpg-review-body ">
        <div className="u-background-white">
          <BPGPricingInfo
            pricing={
              cancellationData && cancellationData.pricing
                ? cancellationData.pricing
                : {}
            }
            guests={
              reservationData && reservationData.guests
                ? reservationData.guests
                : []
            }
            isSuccess={isSuccess}
            warningCode={warningCode}
            suppressResultColumn={isReviewOnly} // https://jira.verbinteractive.com/browse/RCL202-530
          />
        </div>

        <div className="best-price-disclaimers">
          <div className="u-flex disclaimer">
            <p>*</p>
            <p>
              Current rate is based on total cruise fare your client is paying,
              including non-commissionable cruise fare (NCCF) and applicable
              discounts. Excludes government fees and taxes.
            </p>
          </div>
          <div className="u-flex disclaimer">
            <p>**</p>
            <p>
              Prevailing rate is based on current total cruise fare including
              non-commissionable cruise fare (NCCF) for the same ship, sail
              date, stateroom category and number of guests. Excludes government
              fees and taxes. Lower rates must be the {brandName} publicly
              advertised fares available to the general public. Additional
              restrictions apply, including the terms and conditions of any
              promotions and pricing programs.
            </p>
          </div>
          {shouldShowSuccessDisclaimer && (
            <div className="u-flex disclaimer">
              <p>†</p>
              <p>
                The onboard credit is non-refundable, non-transferable and has
                no cash value. Any unused portion of the onboard credit as of
                10pm on the last night of the cruise will be forfeited.
              </p>
            </div>
          )}
        </div>

        <div className="bpg-review-booking-information u-background-white">
          <BPGBookingInfo
            bookingId={cancellationData && cancellationData.id}
            reservationData={reservationData ? reservationData : {}}
            brandName={brandInfo && brandInfo.fullName}
            shipName={shipData && shipData.name}
          />
        </div>
      </div>
      <div className="u-background-white u-border bpg-review-contact-details-container">
        <ContactContainer
          isFlex
          agentEmail={agentEmail}
          setAgentEmail={setAgentEmail}
          agentEmailError={agentEmailError}
          setAgentEmailError={setAgentEmailError}
        />
      </div>
      <div className="u-background-white u-text-center u-border submit-container">
        {requiresTerms && (
          <div className="u-custom-terms-checkbox-container">
            <label className="u-custom-terms-checkbox-label">
              <div className="u-custom-terms-checkbox-input-container">
                <input
                  type="checkbox"
                  checked={agreedToTerms}
                  onChange={e => setTermsAgreement(e.target.checked)}
                  data-testid="bpg-checkbox"
                />
                {agreedToTerms && <img src={checkMark} alt="check" />}
              </div>
              <div className="u-flex u-flex-wrap">
                <span>
                  I accept the above non-refundable Onboard Credit (OBC) and the
                </span>
                <TermsAndConditionsTriggerAndModal />
              </div>
            </label>
          </div>
        )}
        <div className="bpg-review-footer">
          {isReviewOnly ? (
            <button onClick={cancelRequest} className="btn btn-oval with-arrow">
              Check Another Reservation
            </button>
          ) : (
            <>
              <button
                className="btn btn-oval with-arrow"
                disabled={
                  !agentEmail || !isSuccess || (requiresTerms && !agreedToTerms)
                }
                onClick={stageSubmit}
              >
                Submit Request
              </button>
              <button
                className="btn btn-link u-uppercase"
                onClick={cancelRequest}
              >
                Cancel Request
              </button>
            </>
          )}
        </div>
      </div>
      <Modal onRequestClose={dismissSubmit} isOpen={confirmationModalIsOpen}>
        <>
          <div className={'terms-and-conditions-modal-text-content'}>
            <BpgTermsAndConditions
              submit={submit}
              dismissSubmit={dismissSubmit}
            />
          </div>
        </>
      </Modal>
    </div>
  )
}

export default BPGReview

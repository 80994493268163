import Big from 'big.js'

export const convert = (amount, applicableOnBoardCredit) => {
  const rate = new Big(applicableOnBoardCredit?.exchangeRate || 1)
  const original = new Big(amount)
  return rate.times(original)
}

export const convertToBookingCurrency = (amount, applicableOnBoardCredit) => {
  if (!amount) {
    return ''
  }
  const converted = convert(amount, applicableOnBoardCredit)
  return `${converted
    .round(2)
    .toFixed(2)} ${applicableOnBoardCredit?.bookingCurrency || ''}`
}

import React from 'react'

import '../styles/scss/components/fcc-terms.scss'

const FccTermsGSVersion = () => (
  <div className="">
    <p>
      <strong>
        <i>
          A. As valid consideration to the issuing cruise line, Royal Caribbean
          Cruises Ltd., trading as Royal Caribbean International, or Celebrity
          Cruises Inc. (the “Cruise Line”) and in respect to the transfer of the
          individual’s (the “Client”) FCC with Cruise Line to a named and known
          friend or family member of the Client (the “Transferee”), I, the
          travel agent completing this form (the “Agent”) and the Client hereby
          jointly and severally represent, warrant and covenant to Cruise Line
          that:
        </i>
      </strong>
    </p>
    <ol>
      <li>
        a. The Agent has (i) verified the Client’s identity; and (ii) checked
        the Client’s previously issued FCC; and
        <br />
        b. The Client is the “Original Recipient” named on the FCC, meaning the
        individual (and lead name on the original booking) to whom the Cruise
        Line initially issued the FCC; and
        <br />
        c. The Agent has been expressly granted the authority, by the Client, to
        complete this Request for Transfer of FCC form on their behalf;
      </li>
      <li>
        The FCC represented by the Certificate Number that the Client has
        presented to the Agent - and which the Agent has checked and verified -
        was issued to the Client as a result of the cancellation of their
        original reservation/confirmed booking with Cruise Line, identified by
        the Booking ID/number shown on the FCC (the “Cancelled Booking”);
      </li>
      <li>
        The Client has not received any other form of compensation, refund (or
        part-refund) or other reimbursement for the Cancelled Booking, including
        (but not limited to) claiming under any applicable travel insurance
        and/or credit card refund scheme or other means;
      </li>
      <li>
        The Client has not previously attempted to transfer the FCC or
        previously requested that Cruise Line transfer it;
      </li>
      <li>
        The Client wishes to voluntarily transfer the entire FCC to the
        Transferee (who is a known friend or family member) and whose name has
        been provided to the Agent, by the Client, for such transfer;  and that
        such transfer: (i) is not for the purposes of any credit agreement, debt
        settlement or security; and/or (ii) that the Client has not received,
        and will not receive, anything of value from the Transferee in return
        for transferring the FCC to the Transferee;
      </li>
      <li>
        The Client has the legal authority to act for and on behalf of all
        persons named in the Cancelled Booking and/or any one of them, as
        applicable;
      </li>
      <li>
        The Transferee has freely consented and granted the Client permission to
        provide the Transferee’s name to the Agent and Cruise Line, in order for
        the FCC to be transferred to the Transferee; and
      </li>
      <li>
        The Client and the Transferee further warrant that the Transferee is a
        living person, is not a minor and has the legal capacity to enter into a
        new contract in respect to a new cruise booking with the Cruise Line,
        upon redeeming the FCC.
      </li>
    </ol>
    <p>
      <strong>B. It is understood and agreed that:</strong>
    </p>
    <ol>
      <li>
        Upon submission of this transfer request, the Transferee shall be
        considered the new beneficial owner of the FCC who will enter into a new
        contract with the Cruise Line in respect to a new cruise booking with
        the Cruise Line, upon redeeming the FCC;
      </li>
      <li>
        This transfer request is irrevocable and, once submitted, it cannot be
        withdrawn, cancelled or amended;
      </li>
      <li>
        By submitting this transfer request, the Client will be (i)
        relinquishing all of their rights to the value represented by the FCC
        and/or any rights they may have to receive a refund of any part of the
        value represented by the FCC; and (ii) in transferring the FCC to the
        Transferee the Client is assigning any and all rights under the FCC to
        the Transferee; and
      </li>
      <li>
        The Transferee’s rights under the FCC shall be limited to the redemption
        value of the FCC which may only be applied to the cruise fare of a new
        cruise booking with the Cruise Line, that shall be subject to (i) the
        terms and conditions of the FCC (which cannot be further transferred,
        gifted, or assigned by the Transferee), and (ii) Cruise Line’s Cruise
        Ticket Contract, a/k/a Booking Conditions, that shall apply to the new
        cruise booking with the Cruise Line upon redemption of the FCC.
      </li>
    </ol>
    <p>
      <strong>
        THE CLIENT AND AGENT (WHERE APPLICABLE) ACCEPT THE ABOVE TERMS AND
        CONDITIONS
        <br />
        THE TRANSFEREE HAS GIVEN THE CLIENT WRITTEN CONSENT TO PROVIDE THEIR
        PERSONAL DATA TO THE AGENT AND CRUISE LINE FOR THE PURPOSES OF
        TRANSFERING THE FCC TO THE TRANSFEREE.
      </strong>
    </p>
    <p>Please Note:</p>
    <p>
      <strong>Eligibility:</strong>
    </p>
    <p>
      In order to participate in the FCC Transfer Offer, the Client may be
      requested to provide the Agent and/or Cruise Line with proof of
      identification (e.g., passport) along with any other original booking
      documents and/or information that may be requested, that relates to the
      Cancelled Booking.  Most of the FCCs were issued in electronic form,
      stored in the Cruise Line’s reservations system and not represented by a
      tangible certificate. However, if the Client received the original FCC in
      the form of a tangible certificate, they must return the original
      certificate to the Agent and/or Cruise Line.
    </p>

    <p>
      <strong>Data protection:</strong>
    </p>
    <p>
      Subject to the Cruise Line’s privacy policy, any personal data belonging
      to the Client (Transferor) and/or the Transferee shall be collected and
      processed by the Cruise Line as a ‘Data Controller’ as defined in the
      applicable data protection and privacy laws including, as applicable, the
      EU General Data Protection Regulation (“EU GDPR”); the UK GDPR; or the
      CCPA (each as amended or supplemented) and the data protection laws of any
      other country, state or territory which apply to such processing. Cruise
      Line is responsible for the personal data the Client provides and will
      only share such personal data within its Group companies, suppliers and/or
      agents and business partners and where there is a legitimate reason
      permitted by law, to do so. For more information on how Cruise Line
      processes your personal data, please see the Cruise Line’s Privacy Policy
      at{' '}
      <a
        href="https://www.royalcaribbean.com/resources/privacy-policy"
        target="_blank"
        rel="noreferrer noopener"
      >
        https://www.royalcaribbean.com/resources/privacy-policy.
      </a>
    </p>

    <p>
      <strong>Indemnity and Waiver:</strong>
    </p>
    <p>
      To participate in this FCC Transfer Offer, the Client hereby releases
      Cruise Line and its parent, subsidiaries and other affiliates, employees,
      and agents from any further liability of any kind related to the Cancelled
      Booking, including any rights to refund, compensation, damages or
      equitable relief. The Client further warrants that it is their intention
      to legally and equitably transfer and assign the FCC to the Transferee in
      full, and the Client hereby indemnifies Cruise Line against any demand,
      claim, application or legal proceedings against Cruise Line in relation to
      such transfer and/or against any loss, damage, cost or other expense
      arising out of or in connection with such transfer.
    </p>
    <p>
      <strong>Version Date:</strong> August 12, 2022.
    </p>
  </div>
)

export default FccTermsGSVersion

import React from 'react'
import { Route, Switch } from 'react-router-dom'
import PropTypes from 'prop-types'
import routes from '../../configuration/routes'
import ReservationFormContainer from './ReservationFormContainer'
import ReviewReservationContainer from './ReviewReservationContainer'
import ConfirmationContainer from './ConfirmationContainer'
import LiftAndShiftReservationForm from './LiftAndShiftReservationForm'
import { useSelector } from 'react-redux'
import brands from '../../configuration/brands'
import { getCancellationTypeData } from '../../redux/reducers/cancellationTypeData'
import { getIsAuthenticated } from '../../redux/reducers/ssoData'
import pullmanturConfig from '../../configuration/pullmanturConfig'
import { selectShipsData } from '../../redux/reducers/ships'
import ViewWrapper from './ViewWrapper'
import PurchaseObc from './PurchaseObc'
import ObcConfirmationHeader from '../../components/confirmation/ObcConfirmationHeader'

const Views = ({
  handleRouteChange,
  activeBrandCode,
  selectedProgram,
  resetFlow,
}) => {
  const ships = useSelector(selectShipsData)

  // the cancellationData and reservationData use to be two api calls and live in different places in redux
  // that eventually changed and now lives together, the app however still uses both.
  // I've tried to clean this up as best I can by moving them here and passing them as props to the
  // views and components.
  // So sorry, Matt
  const cancellationData = useSelector(state => getCancellationTypeData(state))
  const reservationData =
    cancellationData && cancellationData.booking
      ? cancellationData.booking
      : null

  const shipData =
    reservationData && reservationData.shipCode && ships
      ? [...ships, ...pullmanturConfig.ships].find(
          ship => ship['ship_code'] === reservationData.shipCode,
        )
      : null

  const brandInfo = shipData ? brands[shipData['brand_code']] : null
  const isAuthenticated = useSelector(state => getIsAuthenticated(state))

  return (
    <Switch>
      <Route exact path={routes.cancellationForm}>
        <ViewWrapper
          handleRouteChange={handleRouteChange}
          hidden={!selectedProgram}
          showIntroCopy
          baseClassName={selectedProgram === 'obc' ? 'purchase' : ''}
        >
          <ReservationFormContainer
            handleRouteChange={handleRouteChange}
            ships={ships}
            isAuthenticated={isAuthenticated}
            activeBrandCode={activeBrandCode}
          />
        </ViewWrapper>
      </Route>
      <Route exact path={routes.review}>
        <ViewWrapper handleRouteChange={handleRouteChange}>
          <ReviewReservationContainer
            handleRouteChange={handleRouteChange}
            reservationData={reservationData}
            cancellationData={cancellationData}
            brandInfo={brandInfo}
            shipData={shipData}
            isAuthenticated={isAuthenticated}
            activeBrandCode={activeBrandCode}
            resetFlow={resetFlow}
          />
        </ViewWrapper>
      </Route>
      <Route exact path={routes.confirmation}>
        <ViewWrapper
          handleRouteChange={handleRouteChange}
          baseClassName={
            selectedProgram === 'obc' ? 'purchase confirmation' : ''
          }
          // darkArrows={selectedProgram === 'obc'}
          renderHeader={
            selectedProgram === 'obc' ? () => <ObcConfirmationHeader /> : null
          }
        >
          <ConfirmationContainer
            handleRouteChange={handleRouteChange}
            reservationData={reservationData}
            cancellationData={cancellationData}
            brandInfo={brandInfo}
            shipData={shipData}
            isAuthenticated={isAuthenticated}
            activeBrandCode={activeBrandCode}
          />
        </ViewWrapper>
      </Route>
      <Route exact path={routes.change}>
        <LiftAndShiftReservationForm
          handleRouteChange={handleRouteChange}
          reservationData={reservationData}
          cancellationData={cancellationData}
          brandInfo={brandInfo}
          shipData={shipData}
          isAuthenticated={isAuthenticated}
        />
      </Route>
      <Route exact path={routes.purchase}>
        <ViewWrapper
          handleRouteChange={handleRouteChange}
          showIntroCopy
          baseClassName="purchase"
          paragraphOverrides={
            selectedProgram === 'obc'
              ? [
                  `Select the guest you'd like to purchase an onboard credit for and then indicate the desired amount of the credit.  You can choose from pre-defined values or manually enter a custom amount between $25 and $500 USD. After selecting the guest and onboard credit amount, you can enter a personalized message and click "Add to Total" to review your order. If the order is ready, click 'Proceed to Payment' and confirm your purchase.`,
                ]
              : null
          }
        >
          <PurchaseObc
            cancellationData={cancellationData}
            resetFlow={resetFlow}
            handleRouteChange={handleRouteChange}
          />
        </ViewWrapper>
      </Route>
    </Switch>
  )
}

Views.defaultProps = {
  activeBrandCode: null,
}

Views.propTypes = {
  handleRouteChange: PropTypes.func.isRequired,
  activeBrandCode: PropTypes.string,
}

export default Views

import React from 'react'

import '../../styles/scss/components/purcahse-obc.scss'
import { ListItem } from '../../components/reservationReview/ReviewItemsList'
import { useSelector } from 'react-redux'
import { selectShipsData } from '../../redux/reducers/ships'
import moment from 'moment'
import { selectBrandsData } from '../../redux/reducers/brand'
import { getSsoData } from '../../redux/reducers/ssoData'
import PurchaseForm from '../../components/purchaseObc/PurchaseForm'
import passengerLabelNumbers from '../../utilities/passengerLabelNumbers'

const PurchaseObc = ({ cancellationData, resetFlow, handleRouteChange }) => {
  const shipData = useSelector(selectShipsData)
  const shipInfo = shipData?.find(
    ship => ship?.['ship_code'] === cancellationData?.shipCode,
  )
  const brands = useSelector(selectBrandsData)
  const brandData = brands?.find(
    brand => brand['brand_code'] === shipInfo?.['brand_code'],
  )

  const agentData = useSelector(getSsoData)
  return (
    <div className="purchase-obc">
      <div className=" header">
        <div className="sailing-info u-white-box">
          <p className="title">Sailing Information</p>
          <div className="items ">
            <ListItem
              item={{
                label: 'Brand',
                value: brandData?.name || '',
              }}
            />
            <ListItem
              item={{
                label: 'Ship',
                value: shipInfo?.name || '',
              }}
            />
            <ListItem
              item={{
                label: 'Sailing Date',
                value: cancellationData?.sailDate
                  ? moment(cancellationData.sailDate).format('DD MMM YYYY')
                  : '',
              }}
            />
          </div>
        </div>
        {cancellationData?.guests?.length ? (
          <div className="guest-list u-white-box">
            <p className="title">Booking ID: {cancellationData?.id}</p>
            <div className="items ">
              {cancellationData.guests.map((guest, index) => {
                return (
                  <ListItem
                    key={guest.id}
                    item={{
                      label: `Guest ${passengerLabelNumbers[index] ||
                        index + 1}`,
                      value: guest.displayName,
                    }}
                  />
                )
              })}
            </div>
          </div>
        ) : null}

        <div className="guest-list u-white-box">
          <p className="title">Agency Information</p>
          <div className="items ">
            <ListItem
              item={{
                label: 'Agency Name',
                value: agentData?.agency?.name || '',
              }}
            />
            <ListItem
              item={{
                label: 'Agent Name',
                value: agentData?.agent?.name || '',
              }}
            />
            <ListItem
              item={{
                label: 'Agent Email',
                value: agentData?.agent?.email || '',
              }}
            />
            <ListItem
              item={{
                label: 'Agent Phone Number',
                value:
                  agentData?.agent?.phone || agentData?.agency?.phone || '',
              }}
            />
          </div>
        </div>
      </div>

      <div className="u-white-box form">
        <PurchaseForm
          guests={cancellationData?.guests || []}
          applicableOnBoardCredit={cancellationData?.applicableOnBoardCredit}
          resetFlow={resetFlow}
          handleRouteChange={handleRouteChange}
          appliedOnBoardCredits={
            cancellationData?.appliedOnBoardCreditsOnBooking
          }
          bookingData={cancellationData}
          brandData={brandData}
          shipData={shipInfo}
        />
      </div>
    </div>
  )
}

export default PurchaseObc

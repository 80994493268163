export default function formatPackageName(selectedPackage, isCruiseTour) {
  const rawName = isCruiseTour
    ? selectedPackage.packageName
    : selectedPackage.itineraryName

  if (!rawName) return null

  return rawName.substring(0, 3) === `${selectedPackage.shipCode} `
    ? rawName.substring(3, rawName.length)
    : rawName
}

import { getLabels } from '../../api/api'

export const REQUEST_LABELS = 'REQUEST_LABELS'
export const RECEIVE_LABELS = 'RECEIVE_LABELS'
export const LABELS_ERROR = 'LABELS_ERROR'

export const fetchLabels = () => dispatch => {
  dispatch({ type: REQUEST_LABELS })

  getLabels()
    .then(res => {
      dispatch({
        type: RECEIVE_LABELS,
        payload: res.data
      })
    })
    .catch(() => {
      dispatch({ type: LABELS_ERROR })
    })
}

import {
  FETCH_SSO_DATA,
  RECEIVE_SSO_DATA,
  SSO_DATA_ERROR,
} from '../actions/ssoData'

const initialState = {
  loading: false,
  error: null,
  data: null,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_SSO_DATA:
      return Object.assign({}, state, {
        loading: true,
      })
    case RECEIVE_SSO_DATA:
      return Object.assign({}, state, {
        loading: false,
        data: action.payload,
      })
    case SSO_DATA_ERROR:
      return Object.assign({}, state, {
        loading: false,
        error: action.payload,
      })
    default:
      return state
  }
}

export const getSsoData = state => state.ssoData?.data

export const getAgentDetails = state =>
  state.ssoData.data && state.ssoData.data.agent
    ? state.ssoData.data.agent
    : null

export const getAgencyDetails = state =>
  state.ssoData.data && state.ssoData.data.agency
    ? state.ssoData.data.agency
    : null

export const getAgentUserId = state =>
  state.ssoData.data && state.ssoData.data.userId
    ? state.ssoData.data.userId
    : null

export const getAgencyId = state => {
  const agencyDetails = getAgencyDetails(state)
  return agencyDetails?.agency_id || ''
}

export const getIsAuthenticated = state => {
  const agentDetails = getAgentDetails(state)
  return Boolean(agentDetails)
}

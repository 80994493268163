import { REQUEST_SHIPS, RECEIVE_SHIPS, SHIPS_ERROR } from '../actions/ships'

const initialState = {
  loading: false,
  error: false,
  data: null
}

export default function(state = initialState, action) {
  switch (action.type) {
    case REQUEST_SHIPS:
      return Object.assign({}, state, {
        loading: true
      })
    case RECEIVE_SHIPS:
      return Object.assign({}, state, {
        loading: false,
        error: false,
        data: action.payload
      })
    case SHIPS_ERROR:
      return Object.assign({}, state, {
        loading: false,
        error: true
      })
    default:
      return state
  }
}

export const selectShipsData = state => state?.ships?.data

import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

import '../styles/scss/components/file-uploader.scss'

const allowedTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/jpg']

const MAX_SIZE_IN_MB = 16
const MAX_SIZE_IN_BYTES = MAX_SIZE_IN_MB * 1000000
const NUMBER_OF_ALLOWED_FILES = 1

const FileUploader = ({ name, onChange, files, abilityToBrowseFile }) => {
  const [error, setError] = useState(null)
  const inputRef = useRef(null)

  useEffect(() => {
    // prevents a bug preventing a user from uploading an image, removing it and then trying to upload the same image again.
    if (inputRef.current && !files?.length) {
      inputRef.current.value = ''
    }
  }, [inputRef, files])

  const renderFileSizeErrorText = () => {
    return (
      <>
        <p className="u-color-error u-text-center error-message">
          The file you've selected exceeds the maximum size limit.
        </p>
        <p className="u-color-error u-text-center error-message">
          Please select another file and try again.
        </p>
      </>
    )
  }

  const handleUpload = async e => {
    e.preventDefault()

    const filePromises = Array.from(e.target.files).map(
      file =>
        new Promise((resolve, reject) => {
          if (!allowedTypes.some(type => type === file.type)) {
            reject()
          }
          const { name, size, type, lastModified } = file
          const reader = new FileReader()
          reader.onload = event => {
            let value

            try {
              value = btoa(event.target.result)
            } catch (e) {
              console.log(e)
            }

            let preview
            try {
              preview = URL.createObjectURL(file)
            } catch (e) {
              console.log(error)
            }
            resolve({
              name,
              size,
              type,
              lastModified,
              preview,
              value,
              error:
                file.size > MAX_SIZE_IN_BYTES
                  ? renderFileSizeErrorText()
                  : null,
            })
          }
          reader.onerror = () => {
            resolve({
              error: 'Unable to upload file.',
            })
          }
          reader.readAsBinaryString(file)
        }),
    )

    Promise.all(filePromises)
      .then(responses => {
        const successes = responses.filter(response => !response.error)
        const errors = responses
          .filter(response => !!response.error)
          .map(file => file.error)

        const allFiles = [...files, ...successes]

        const overLimit = [...allFiles].slice(NUMBER_OF_ALLOWED_FILES)
        const allowed = [...allFiles].slice(0, NUMBER_OF_ALLOWED_FILES)

        if (overLimit?.length) {
          errors.push('Maximum number of files exceeded')
        }
        onChange(allowed)
        setError(errors?.length ? errors : null)
      })
      .catch(err => {
        setError([err] || ['Failed to load one of more files.'])
      })
  }

  return (
    <>
      <div
        className={`file-upload-container ${
          files?.length >= NUMBER_OF_ALLOWED_FILES ? 'disabled' : ''
        }`}
      >
        <div className="label-container u-text-center">
          <label htmlFor={name} className="u-bold">
            Drag your file here or <span className="highlight">browse</span> for
            the file.
          </label>
          <p className="file-limit">
            Maximum size 16MB. Supports JPEG, PNG, PDF.
          </p>
        </div>

        <input
          ref={inputRef}
          disabled={!!files?.length || !abilityToBrowseFile}
          id={name}
          type="file"
          accept="image/*, application/pdf"
          multiple={NUMBER_OF_ALLOWED_FILES > 1}
          placeholder="browse"
          onChange={handleUpload}
        />
      </div>
      {error &&
        error?.length > 0 &&
        error.map((err, i) => (
          <p className="u-color-error u-text-center error-message" key={i}>
            Error: {err}
          </p>
        ))}
    </>
  )
}
FileUploader.defaultProps = {
  files: [],
}

FileUploader.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  files: PropTypes.arrayOf(PropTypes.shape({})),
}

export default FileUploader

import React from 'react'

export default function CVCConfirmationParagraphs({
  bookingID,
  linkedBookings,
}) {
  const ids = [bookingID]
  if (linkedBookings?.length) {
    ids.push(...linkedBookings)
  }
  return (
    <>
      <p className="confirmation-disclaimers " data-testid="cvc-paragraph">
        <strong>
          Your request has been submitted for Booking ID:{ids.join(', ')}. No
          additional action is needed on your part. You’ll receive an email copy
          of your request as a confirmation of receipt shortly.
        </strong>
      </p>
      <p className="confirmation-disclaimers">
        After confirming that the reservation is eligible for a COVID-19
        cancellation refund, we will process the request and issue your client’s
        refund to the original form of payment in about 30 days from the date of
        your request. If your client’s booking did not qualify for a refund, no
        action will be taken.
      </p>
      <p className="confirmation-disclaimers">
        We look forward to welcoming you clients back onboard in the near future
        after he/she feels better. Thank you for your support and loyalty.
      </p>
    </>
  )
}

import React from 'react'
import formatPrice from '../../utilities/formatPrice'

function renderRowDescription(description) {
  if (description === 'Cruise Fare') {
    return 'Cruise'
  }

  if (
    description === 'Non-Commissionable Cruise Fare' ||
    description === 'Non Commissionable Cruise Fare'
  ) {
    return 'NCCF'
  }

  return description
}

function generateKey(item) {
  return `${item.code}-${item.type}-${
    item.discountType
  }-${item.description.replace(/\s/g, '')}`
}

export function setIsOffer(item) {
  return Number(item.discountType) === 3
}

const BPGPriceBreakdownTable = ({
  pricingArray,
  total,
  guests,
  isCurrentRate,
  bestPriceWCOBC,
  currency
}) => {
  const columns = [
    {
      title: 'Vacation Charges'
    }
  ]

  const guestNames = {}
  let offerTotal = 0
  const rows = pricingArray.reduce((obj, guestItems, index) => {
    const guestKey = index + 1

    const matchingGuest = guests && guests[index] ? guests[index] : null
    const guestName = matchingGuest
      ? `${matchingGuest.givenName} ${matchingGuest.surname}`
      : `Guest ${guestKey}`

    guestNames[guestKey] = guestName

    columns.push({
      title: guestName
    })
    guestItems.forEach((item, itemIndex) => {
      const key = generateKey(item)

      const isOffer = setIsOffer(item)

      if (isOffer) {
        offerTotal += Number(item.amount)
      }

      const order =
        Number(item.code) === 49
          ? 1
          : Number(item.code) === 60
          ? 2
          : itemIndex + 3

      if (!obj[key]) {
        obj[key] = {
          description: renderRowDescription(item.description),
          id: key,
          isOffer,
          order
        }
      }

      obj[key][guestKey] = Number(item.amount).toFixed(2)

      obj[key].total = obj[key].total
        ? Number(obj[key].total) + Number(item.amount)
        : Number(item.amount).toFixed(2)
    })

    return obj
  }, {})

  columns.push({
    title: 'Total'
  })

  if (isCurrentRate && Number(bestPriceWCOBC)) {
    rows['BESTPRCWCOBC'] = {
      1: bestPriceWCOBC,
      description: 'BESTPRCWCOBC',
      isOffer: true,
      total: bestPriceWCOBC
    }

    offerTotal += Number(bestPriceWCOBC)
  }

  const sortedRows = Object.values(rows)
    .sort((a, b) => {
      return a.order < b.order ? -1 : 1
    })
    .filter(row => !row.isOffer)

  const offers = Object.values(rows).filter(row => row.isOffer)

  function renderRows(arr, showCurrency) {
    const evenNumberOfRows = arr.length % 2 === 0
    return arr.map((pricing, i) => {
      const grey = evenNumberOfRows ? (i + 1) % 2 !== 0 : (i + 1) % 2 === 0

      return (
        <tr
          key={pricing.id + i}
          className={grey ? 'u-background-light' : 'u-background-white'}
        >
          <td>
            {pricing.description}
            {showCurrency && ' (USD OBC)'}
          </td>
          {pricingArray.map((guest, index) => {
            const guestKey = index + 1
            return (
              <td key={guestKey}>
                {pricing[guestKey]
                  ? formatPrice(pricing[guestKey], false)
                  : '0.00'}
              </td>
            )
          })}
          <td>{pricing.total ? formatPrice(pricing.total, false) : '0.00'}</td>
        </tr>
      )
    })
  }

  function renderMobileRows(arr) {
    return arr.map((pricing, i) => {
      return (
        <div className="u-border-bottom item" key={pricing.id + i}>
          <p className="u-bold u-brand-blue title">{pricing.description}</p>
          {Object.entries(guestNames).map(([key, name], index) => {
            return (
              <div
                key={name + pricing.description + index}
                className="u-flex u-flex-space-between"
              >
                <p>{name}</p>
                <p>
                  {pricing[key] ? formatPrice(pricing[key], false) : '0.00'}
                </p>
              </div>
            )
          })}
          <div className="u-flex u-flex-space-between">
            <p className="item-total u-bold u-brand-blue">Total</p>
            <p className="item-total u-bold u-brand-blue">
              {formatPrice(pricing.total, false)}
            </p>
          </div>
        </div>
      )
    })
  }

  return (
    <>
      <div className="u-xl-up">
        <table className="u-table">
          <thead>
            <tr>
              {columns.map((col, i) => (
                <td key={col.title + i}>{col.title}</td>
              ))}
            </tr>
          </thead>
          <tbody>
            {renderRows(sortedRows)}

            <tr className="u-background-light u-border-top">
              <td>Vacation Subtotal</td>
              {pricingArray.map((guest, index) => {
                const vacationTotal = guest.reduce((num, item) => {
                  if (!setIsOffer(item)) {
                    num += Number(item.amount)
                  }
                  return num
                }, 0)
                return (
                  <td key={`guest-total-${index}`}>
                    {formatPrice(vacationTotal, false)}
                  </td>
                )
              })}
              <td>{total ? formatPrice(total, false) : null}</td>
            </tr>
          </tbody>
        </table>
        {offers && offers.length ? (
          <div className="offer-table">
            <div className="offer-table-header">
              <h3 className="u-bold">Offer Details</h3>
            </div>
            <table className="u-table">
              <thead>
                <tr>
                  {columns.map((col, i) => (
                    <td key={col.title + i}>
                      {col.title === 'Vacation Charges' ? 'Offers' : col.title}
                    </td>
                  ))}
                </tr>
              </thead>
              <tbody>
                {renderRows(offers, currency !== 'USD')}
                <tr className="u-background-light u-border-top">
                  <td>Total Value</td>

                  {Object.keys(guestNames).map(guestKey => {
                    let guestOfferTotal = 0

                    for (const offer of offers) {
                      if (offer[guestKey]) {
                        guestOfferTotal += Number(offer[guestKey])
                      }
                    }
                    return (
                      <td key={`guest-total-${guestKey}`}>
                        {formatPrice(guestOfferTotal, false)}
                      </td>
                    )
                  })}
                  <td>{formatPrice(offerTotal, false)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : null}
      </div>
      <div className="u-xl-max bpg-receipt">
        {renderMobileRows(sortedRows)}
        <div className="u-flex u-flex-space-between">
          <p>Vacation Total</p>
          <p>{total ? formatPrice(total, false) : null}</p>
        </div>

        {offers && offers.length ? (
          <div className="offer-table">
            <div className="offer-table-header">
              <h3 className="u-bold u-text-center">Offer Details</h3>
            </div>
            {renderMobileRows(offers)}
            <div className="u-flex u-flex-space-between">
              <p>Offer Total</p>
              <p>{offerTotal ? formatPrice(offerTotal, false) : null}</p>
            </div>
          </div>
        ) : null}
      </div>
    </>
  )
}

export default BPGPriceBreakdownTable

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import AgentReservationForm from '../../components/reservationForms/AgentReservationForm'
import DirectReservationForm from '../../components/reservationForms/DirectReservationForm'

import {
  CANCELLATION_TYPE_ERROR,
  fetchCancellationType,
  fetchLiftAndShiftOptions,
  RECEIVE_CANCELLATION_TYPE,
} from '../../redux/actions/fetchCancellationType'
import { fetchCovidCancellation } from '../../redux/actions/covidCancellation'
import LoadingScreen from '../../components/elements/LoadingScreen'
import ForfeitModal from '../../components/modals/ForfeitModal'
import ErrorModal from '../../components/modals/ErrorModal'
import routes from '../../configuration/routes'
import { cancellationTypeDataIsLoading } from '../../redux/reducers/cancellationTypeData'
import dispatchAnalyticsEvent from '../../redux/actions/dispatchAnalyticsEvent'
import { fetchFccData } from '../../redux/actions/fetchFccData'

import '../../styles/scss/views/reservation-form.scss'
import {
  getProgramDisclaimer,
  getSelectedProgramData,
} from '../../redux/actions/setSelectedProgramData'
import { fetchObcBooking } from '../../redux/actions/fetchObcBooking'
import triggerCustomGaEvent from "../../utilities/triggerCustomGaEvent";
import {getSsoData} from "../../redux/reducers/ssoData";

const ReservationFormContainer = ({
  handleRouteChange,
  ships,
  isAuthenticated,
  activeBrandCode,
}) => {
  const [forfeitModalIsOpen, toggleForfeitModal] = useState(false)
  const [errorModalIsOpen, toggleErrorModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const shouldDisplayLoading = useSelector(state =>
    cancellationTypeDataIsLoading(state),
  )

  const dispatch = useDispatch()

  const selectedProgram = useSelector(state => state.requestType)
  const selectedProgramData = useSelector(getSelectedProgramData)
  const disclaimer = useSelector(getProgramDisclaimer)
  const userData = useSelector(getSsoData)
  // the lift and shift program has one additional step.
  const nextStep =
    selectedProgram && selectedProgram === 'las'
      ? routes.change
      : selectedProgram && selectedProgram === 'obc'
      ? routes.purchase
      : routes.review

  useEffect(() => {
    // this is analytics only, hence the // eslint-disable-line react-hooks/exhaustive-deps
    const formName = isAuthenticated
      ? 'agentCancellationFormLaunched'
      : 'consumerCancellationFormLaunched'

    dispatch(dispatchAnalyticsEvent('cancellationFormLaunched'))
    dispatch(dispatchAnalyticsEvent(formName))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  function throwError(error = { 1: 'Something Went Wrong' }) {
    setErrorMessage(error)
    toggleErrorModal(true)
    dispatch({ type: CANCELLATION_TYPE_ERROR, payload: error })
  }

  async function handleSubmit(values, formType) {
    switch (selectedProgram) {
      case 'cvc': // Covid Cancellation
        try {
          const result = await dispatch(fetchCovidCancellation(values))
          if (!result) {
            return throwError()
          }
          handleRouteChange(nextStep) // review
        } catch (e) {
          throwError({
            1: e?.message || 'Something Went Wrong',
          })
        }
        break
      case 'fcc':
        try {
          const { fccNumber, firstName, lastName } = values
          const result = await dispatch(
            fetchFccData({ fccNumber, firstName, lastName }),
          )
          if (!result) {
            return throwError()
          }
          handleRouteChange(nextStep) // review
        } catch (e) {
          throwError({
            1: e?.message || 'Something Went Wrong',
          })
        }
        break
      case 'obc':
        try {
          const result = await dispatch(
            fetchObcBooking({ id: values.bookingID }),
          )
          if (!result) {
            return throwError()
          }
          triggerCustomGaEvent('obc-startPurchase', {
            ...(result || {}),
            ...(userData || {})
          })
          handleRouteChange(nextStep) // review
        } catch (e) {
          throwError({
            1: e?.message || 'Something Went Wrong',
          })
        }
        break
      default:
        // used by cwc, cs, las, bpg
        submitCancellationType(values, formType)
        break
    }
  }

  // if need we'll trigger the forfeit modal, other wise we'll just continue
  function submitCancellationType(values, formType) {
    dispatch(fetchCancellationType(values, formType))
      .then(async res => {
        if (!res || !res.data) {
          return throwError()
        }
        if (res.data && res.data.data && res.data.data.code) {
          // we need to fetch preferences if using lift and shift
          let liftAndShiftResponse = null,
            liftAndShiftOptions = null

          const isCruiseTour = Boolean(
            res.data.data.inclusivePackageOption &&
              res.data.data.inclusivePackageOption.inclusiveIndicator &&
              res.data.data.inclusivePackageOption.inclusiveIndicator ===
                'true',
          )

          if (selectedProgram === 'las') {
            try {
              liftAndShiftResponse = await fetchLiftAndShiftOptions(
                res.data.id,
                res.data.sessionId,
                res.data.type,
              )

              if (liftAndShiftResponse?.data?.data?.length) {
                liftAndShiftOptions = liftAndShiftResponse.data.data.reduce(
                  (obj, option) => {
                    const { shipCode } = option
                    if (!obj[shipCode]) {
                      obj[shipCode] = {
                        shipDetails: ships
                          ? ships.find(ship => ship['ship_code'] === shipCode)
                          : {},
                        dates: {},
                      }
                    }

                    if (
                      option.inclusivePackageOptions &&
                      option.inclusivePackageOptions.packageCode
                    ) {
                      if (!obj[shipCode]['dates'][option.startDate]) {
                        obj[shipCode]['dates'][option.startDate] = {}
                      }
                      obj[shipCode]['dates'][option.startDate][
                        option.inclusivePackageOptions.packageCode
                      ] = option
                    }
                    return obj
                  },
                  {},
                )
              } else {
                return throwError(
                  liftAndShiftResponse.data && liftAndShiftResponse.data.error
                    ? liftAndShiftResponse.data.error
                    : { 1: 'Unable to fetch sailings' },
                )
              }
            } catch (e) {
              return throwError({ 1: 'Unable to fetch sailings' })
            }
          }

          dispatch({
            type: RECEIVE_CANCELLATION_TYPE,
            payload: {
              id: res.data.id,
              ...res.data.data,
              isCruiseTour,
              liftAndShiftOptions,
            },
          })

          // if our type is equal to GQ or MR we need to open the forfeitModal
          // otherwise we can proceed.
          if (res.data.data.code === 'GQ' || res.data.data.code === 'MR') {
            triggerForfeitModal()
          } else {
            handleRouteChange(nextStep)
          }
        } else {
          return throwError(
            res.data.error ? res.data.error : { 1: 'Something Went Wrong' },
          )
        }
      })
      .catch(() => throwError({ 1: 'Something Went Wrong' }))
  }

  function triggerForfeitModal() {
    dispatch(dispatchAnalyticsEvent('cruiseRefund'))
    const formName = isAuthenticated
      ? 'cruiseRefundAgent'
      : 'cruiseRefundConsumer'
    dispatch(dispatchAnalyticsEvent(formName))

    toggleForfeitModal(true)
  }

  return (
    <div className="reservation-forms u-white-box">
      <>
        {shouldDisplayLoading ? (
          <div>
            <LoadingScreen />
          </div>
        ) : (
          <>
            <h2 className="form-title">Let's get Started</h2>
            {!isAuthenticated && selectedProgramData?.description && (
              <p className="form-copy">{selectedProgramData?.description}</p>
            )}
            {selectedProgram ? (
              <div
                style={{
                  opacity: selectedProgram ? 1 : '0.3',
                }}
                data-testid="reservation-forms-container"
                className="forms"
              >
                {isAuthenticated ? (
                  <AgentReservationForm
                    submitForm={handleSubmit}
                    selectedProgram={selectedProgram}
                    disabled={Boolean(!selectedProgram)}
                  />
                ) : (
                  <DirectReservationForm
                    submitForm={handleSubmit}
                    disabled={Boolean(!selectedProgram)}
                    activeBrandCode={activeBrandCode}
                    selectedProgram={selectedProgram}
                  />
                )}
              </div>
            ) : null}

            {disclaimer && (
              <div className="program-disclaimer">
                <p>{disclaimer}</p>
              </div>
            )}
          </>
        )}
      </>

      {forfeitModalIsOpen && (
        <ForfeitModal
          isOpen={forfeitModalIsOpen}
          accept={() => handleRouteChange(nextStep)}
          cancel={() => toggleForfeitModal(false)}
          activeBrandCode={activeBrandCode}
        />
      )}
      {errorModalIsOpen && (
        <ErrorModal
          isOpen={errorModalIsOpen}
          onRequestClose={() => toggleErrorModal(false)}
          message={errorMessage}
        />
      )}
    </div>
  )
}

ReservationFormContainer.defaultProps = {
  isAuthenticated: false,
  ships: null,
  activeBrandCode: null,
}

ReservationFormContainer.propTypes = {
  handleRouteChange: PropTypes.func.isRequired,
  ships: PropTypes.arrayOf(
    PropTypes.shape({
      ship_code: PropTypes.string,
    }),
  ),
  isAuthenticated: PropTypes.bool,
  activeBrandCode: PropTypes.string,
}

export default ReservationFormContainer

import React, { useLayoutEffect, useState } from 'react'
import { SingleDatePicker } from 'react-dates'
import moment from 'moment'
import PropTypes from 'prop-types'
import formats from '../../configuration/formats'

const DatePicker = ({ handleDateChange, date, disabled }) => {
  const [datePickerIsFocused, setDatePickerFocus] = useState(false)

  useLayoutEffect(() => {
    function checkDate(e) {
      // if the user is entering slashes we don't need this.
      const entries = e.target.value.split('/')
      if (entries && entries.length > 1) return null
      if (
        e.target.value.length === 8 &&
        moment(e.target.value, 'MMDDYYYY').isAfter(
          moment('2020-03-12', 'YYYY-MM-DD'),
        )
      ) {
        handleDateChange(moment(e.target.value, 'MMDDYYYY'))
        setDatePickerFocus(false)
      }
    }
    const picker = document.getElementById('general-reservation-date-picker')
    if (picker) {
      picker.addEventListener('keyup', checkDate)
    }

    return () => {
      if (picker) {
        picker.removeEventListener('keyup', checkDate)
      }
    }
  }, [handleDateChange])

  function handleFocusChange({ focused }) {
    setDatePickerFocus(focused)
  }
  return (
    <SingleDatePicker
      onDateChange={handleDateChange}
      focused={datePickerIsFocused}
      onFocusChange={handleFocusChange}
      date={date}
      id="general-reservation-date-picker"
      placeholder={formats.datePickerDisplayDate}
      numberOfMonths={1}
      isOutsideRange={day => day.isBefore(moment('2020-03-13', 'YYYY-MM-DD'))}
      disabled={disabled}
      displayFormat={formats.datePickerDisplayDate}
    />
  )
}

DatePicker.defaultProps = {
  date: null,
  disabled: false,
  activeBrandCode: null,
}

DatePicker.propTypes = {
  handleDateChange: PropTypes.func.isRequired,
  date: PropTypes.instanceOf(moment),
  disabled: PropTypes.bool,
  activeBrandCode: PropTypes.string,
}

export default DatePicker

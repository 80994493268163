import AzaLogo from '../assets/images/logos/azamara-logo-2.png'
import RoyalLogo from '../assets/images/logos/rccl-logo.png'
import CelebLogo from '../assets/images/logos/celebrity-logo.png'
import RoyalFavicon from '../assets/icons/royal-favicon.ico'
import AzaFavicon from '../assets/icons/aza-favicon.ico'
import CelebFavicon from '../assets/icons/celebrity-favicon.ico'
import PullmanturLogo from '../assets/icons/pullmanturLogo.png'
const year = new Date().getFullYear()

export default {
  R: {
    name: 'Royal Caribbean',
    fullName: 'Royal Caribbean International',
    copyWrite: `©${year} Royal Caribbean International All Rights Reserved.`,
    logo: RoyalLogo,
    favicon: RoyalFavicon,
    channel: 'Royal Web',
    code: 'R'
  },
  C: {
    name: 'Celebrity',
    fullName: 'Celebrity Cruises',
    copyWrite: `©${year} Celebrity Cruises®, Inc. All Rights Reserved.`,
    logo: CelebLogo,
    favicon: CelebFavicon,
    channel: 'Celebrity Web',
    code: 'C'
  },
  Z: {
    name: 'Azamara',
    fullName: 'Azamara',
    copyWrite: `©${year} Azamara All Rights Reserved.`,
    logo: AzaLogo,
    favicon: AzaFavicon,
    channel: 'Azamara Web',
    code: 'Z'
  },
  P: {
    name: 'Pullmantur',
    fullName: 'Pullmantur Cruises',
    copyWrite: `©${year} Royal Caribbean Cruises Ltd. All Rights Reserved.`,
    logo: PullmanturLogo,
    favicon: RoyalFavicon,
    channel: 'Pullmantur Web',
    code: 'P'
  }
}

import { omit } from 'lodash'
import {
  REQUEST_CANCEL_RESERVATION,
  RECEIVE_CANCELLATION_CONFIRMATION,
  CANCELLATION_ERROR,
} from './cancelResevation'
import {
  getAgencyDetails,
  getAgencyId,
  getAgentDetails,
} from '../reducers/ssoData'
import { postObcPurchase } from '../../api/api'
import releaseActiveBooking from './releaseActiveBooking'
import triggerCustomGaEvent from '../../utilities/triggerCustomGaEvent'

const cardTypeCodes = {
  amex: 'AX',
  visa: 'VI',
  mastercard: 'MC',
  diners: 'DN',
  discover: 'DS',
  jcb: 'JC',
}

export const purchaseObc = ({
  paymentValues,
  purchases,
  bookingData,
  shipData,
  brandData,
}) => (dispatch, getState) => {
  dispatch({ type: REQUEST_CANCEL_RESERVATION })
  const state = getState()
  const agencyId = getAgencyId(state)
  const agentData = getAgentDetails(state)
  const agencyData = getAgencyDetails(state)

  const params = {
    id: bookingData.id,
    agencyId: agencyId,
    sessionId: state.sessionId,
    payment: {
      card: {
        cardHolderName: `${paymentValues.lastName},${paymentValues.firstName}`,
        cardType:
          cardTypeCodes[paymentValues.cardType] || paymentValues.cardType || '',
        cardNumber: paymentValues.cardNumber?.replace(/\s/g, ''),
        seriesCode: paymentValues.cvv,
        expireDate: paymentValues.expiry?.replace('/', ''),
      },
    },
    currencyCode: bookingData?.applicableOnBoardCredit?.bookingCurrency,
    guestObcPurchases: purchases.map(purchase => ({
      guestId: purchase.guestId,
      obcs: [
        {
          optionCode: bookingData?.applicableOnBoardCredit?.optionCode,
          amount: purchase.amount,
        },
      ],
      toEmails: purchase.toEmails,
      toName: purchase.toName,
      fromEmail: purchase.fromEmail,
      fromName: purchase.fromName,
      message: purchase.message,
    })),
    brand: omit(brandData || {}, [
      'image',
      'badge_dark',
      'badge_light',
      'disclaimer_pricing',
      'email_image',
    ]),
    ship: omit(shipData || {}, ['image']),
    agent: agentData,
    agency: agencyData,
    nonce: new Date().getTime(),
  }

  return postObcPurchase(params)
    .then(res => {
      const data = res?.data?.['hydra:member']?.[0]

      dispatch(releaseActiveBooking(bookingData.id))
      triggerCustomGaEvent('obc-completePurchase', {
        purchases,
        bookingData: omit(bookingData, [
          'appliedOnBoardCreditsOnBooking',
          'guests',
          'applicableOnBoardCredit',
        ]),
        bookingId: bookingData.id,
        brand: omit(brandData || {}, [
          'image',
          'badge_dark',
          'badge_light',
          'disclaimer_pricing',
          'email_image',
        ]),
        ship: omit(shipData || {}, ['image']),
        agent: agentData,
        agency: agencyData,
      })
      return dispatch({
        type: RECEIVE_CANCELLATION_CONFIRMATION,
        payload: {
          data,
          purchases: purchases,
          confirmationType: 'purchase',
        },
      })
    })
    .catch(err => {
      const message =
        err?.response?.status === 500
          ? 'The credit card number is invalid or not supported. Please check the credit card number and try again.'
          : err?.response?.data?.['hydra:description'] ||
            err?.message ||
            'Unable to process purchase.'
      dispatch({
        type: CANCELLATION_ERROR,
        payload: message,
      })
      throw new Error(message)
    })
}

export const refundObc = item => dispatch => {
  dispatch({ type: REQUEST_CANCEL_RESERVATION })
  return new Promise(resolve => {
    setTimeout(() => {
      dispatch({
        type: RECEIVE_CANCELLATION_CONFIRMATION,
        payload: {
          // TODO: add the api response
          item,
          confirmationType: 'refund',
        },
      })
      resolve({
        type: RECEIVE_CANCELLATION_CONFIRMATION,
        payload: {
          // TODO: add the api response
          item,
          confirmationType: 'refund',
        },
      })
    }, 500)
  })
}

export default function(pricing) {
  if (!pricing) {
    return 0
  }

  const { discount, appliedCredit } = pricing

  let total = discount ? Number(discount) : 0

  if (appliedCredit) {
    total += Number(appliedCredit)
  }
  return total.toFixed(2)
}

import React from 'react'
import PropTypes from 'prop-types'
import calIcon from '../../assets/icons/calendar.svg'
import { ListItem } from './ReviewItemsList'
import moment from 'moment'
import formats from '../../configuration/formats'

const BPGBookingInfo = ({
  bookingId,
  reservationData,
  shipName,
  brandName,
}) => {
  const { groupName, sailDate, berthedCategoryName, guests } = reservationData

  const qualifiers =
    guests && guests.length
      ? guests.reduce((arr, guest) => {
          if (guest.qualifiers) {
            for (const qualifier of guest.qualifiers) {
              if (!arr.some(str => str === qualifier.name)) {
                arr.push(qualifier.name)
              }
            }
          }

          return arr
        }, [])
      : []

  return (
    <>
      <div className="u-flex u-flex-justify-center u-flex-align-center bpg-review-booking-header">
        <img src={calIcon} alt="booking information" />
        <p className="bpg-review-booking-information-title">
          Booking Information
        </p>
      </div>

      <div className="u-flex bpg-review-booking-information-items">
        <div className="bpg-review-booking-information-item">
          {bookingId && (
            <ListItem
              item={{
                value: bookingId,
                label: 'Booking ID',
              }}
              borderBottom
            />
          )}
          {groupName && groupName.length && (
            <ListItem
              item={{
                value: reservationData.groupName,
                label: 'Group Name:',
              }}
              borderBottom
            />
          )}
          {brandName && (
            <ListItem
              item={{
                value: brandName,
                label: 'Brand',
              }}
              borderBottom
            />
          )}
          {shipName && (
            <ListItem
              item={{
                value: shipName,
                label: 'Ship',
              }}
              borderBottom
            />
          )}
          {sailDate && (
            <ListItem
              item={{
                value: moment(reservationData.sailDate, formats.date)
                  .format(formats.displayDateShort)
                  .toUpperCase(),
                label: 'Sail Date',
              }}
              borderBottom
            />
          )}
          {berthedCategoryName && (
            <ListItem
              item={{
                value: reservationData.berthedCategoryName,
                label: 'Category',
              }}
            />
          )}
        </div>

        <>
          {Boolean(guests && guests.length) && (
            <div className={'bpg-review-booking-information-item'}>
              {guests.map((guest, i) => {
                return (
                  <ListItem
                    key={`guest-${i}-${guest.givenName}`}
                    borderBottom={i + 1 < guests.length}
                    item={{
                      label: `Guest ${formats.guestLabels[i]}:`,
                      value: `${guest.givenName} ${guest.surname}`,
                    }}
                  />
                )
              })}
            </div>
          )}
        </>

        <>
          {Boolean(qualifiers && qualifiers.length) && (
            <div className={'bpg-review-booking-information-item'}>
              {qualifiers.map((qualifier, i) => (
                <ListItem
                  key={qualifier}
                  borderBottom={i + 1 < qualifiers.length}
                  item={{
                    label: 'Promotional Qualifiers',
                    value: qualifier,
                  }}
                />
              ))}
            </div>
          )}
        </>
      </div>
    </>
  )
}

BPGBookingInfo.defaultProps = {
  bookingId: null,
  reservationData: {
    groupName: null,
    sailDate: null,
    berthedCategoryName: null,
    guests: null,
  },
  shipName: null,
  brandName: null,
}

BPGBookingInfo.propTypes = {
  bookingId: PropTypes.string,
  reservationData: PropTypes.shape({
    groupName: PropTypes.string,
    sailDate: PropTypes.string,
    berthedCategoryName: PropTypes.string,
    guests: PropTypes.arrayOf(
      PropTypes.shape({
        givenName: PropTypes.string,
        surname: PropTypes.string,
      }),
    ),
  }),
  shipName: PropTypes.string,
  brandName: PropTypes.string,
}

export default BPGBookingInfo

import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'core-js' // <- at the top of your entry point
import 'airbnb-browser-shims'
import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import App from './containers/structure/App'
import configureStore from './redux/store'
import 'react-dates/lib/css/_datepicker.css'

import './styles/scss/base/base.scss'
import './styles/scss/base/buttons.scss'
import './styles/scss/base/print.scss'
import './styles/scss/base/typography.scss'
import './styles/scss/base/utilities.scss'
import './styles/scss/base/tables.scss'
const store = configureStore()

const renderApp = () =>
  render(
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>,
    document.getElementById('cs__cancel-reservation'),
  )

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./containers/structure/App', renderApp)
}

renderApp()

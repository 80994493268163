import React from 'react'
import checkIcon from '../../assets/icons/checklist.svg'
export default function ContactDetails({
  guestEmail,
  guestPhone,
  cancellationData,
  isDirectBooking,
  isFlex,
  isSpanish
}) {
  // the business rules here are to show the agency information if isDirectBooking is false
  // unauthenticated tri-brand reversion of the flow otherwise to show the guests' info
  let email, phone, agencyName, title, phoneLabel, emailLabel
  if (isDirectBooking) {
    // use the guestInfo
    email = guestEmail ? guestEmail : null
    phone = guestPhone ? guestPhone : null
    title = isSpanish ? 'INFORMACIÓN DE CONTACTO' : 'Contact Information'
    phoneLabel = isSpanish ? 'Número de teléfono:' : 'Phone Number:'
    emailLabel = isSpanish
      ? 'Dirección de correo electrónico:'
      : 'Email Address:'
  } else {
    email =
      cancellationData && cancellationData.agencyEmail
        ? cancellationData.agencyEmail
        : null
    phone =
      cancellationData && cancellationData.agencyPhone
        ? cancellationData.agencyPhone
        : null
    agencyName =
      cancellationData && cancellationData.agencyName
        ? cancellationData.agencyName
        : null

    title = isSpanish ? 'INFORMACIÓN DE LA AGENCIA' : 'Agency Information'
    phoneLabel = isSpanish
      ? 'Número de teléfono de la agencia:'
      : 'Agency Phone Number:'
    emailLabel = isSpanish
      ? 'Dirección de correo electrónico de la agencia:'
      : 'Agency Email Address:'
  }

  return (
    <div>
      <div className="u-icon-label">
        <img src={checkIcon} alt={title} />
        <p className="u-bold u-brand-blue">{title}</p>
      </div>
      <div
        className={`contact-details-flex-container ${
          isFlex ? 'u-flex-md-up u-flex-wrap ' : ''
        }`}
      >
        {agencyName ? (
          <div className={`contact-details-item ${isFlex ? 'max-width' : ''}`}>
            <label className="u-input-label u-full-width">
              <span>
                {' '}
                {isSpanish ? 'Nombre de la agencia:' : 'Agency Name:'}{' '}
              </span>
              <input
                className="contact-details-item-value u-brand-blue u-bold"
                readOnly
                value={agencyName}
              />
            </label>
          </div>
        ) : null}
        {phone && (
          <div className={`contact-details-item ${isFlex ? 'max-width' : ''}`}>
            <label className="u-input-label u-full-width">
              <span>{phoneLabel}</span>
              <input
                className="contact-details-item-value u-brand-blue u-bold"
                readOnly
                value={phone}
              />
            </label>
          </div>
        )}
        {email ? (
          <div className={`contact-details-item ${isFlex ? 'max-width' : ''}`}>
            <label className="u-input-label u-full-width">
              <span>{emailLabel}</span>
              <input
                className="contact-details-item-value u-brand-blue u-bold"
                readOnly
                value={email}
              />
            </label>
          </div>
        ) : null}
      </div>
    </div>
  )
}

import { RESET_BOOKING_FLOW } from '../actions/fetchCancellationType'
import { SET_REQUEST_TYPE } from '../actions/setRequestType'

export default function(state = '', action) {
  if (action.type === SET_REQUEST_TYPE) {
    return action.payload
  }
  if (action.type === RESET_BOOKING_FLOW) {
    return ''
  }
  return state
}

export const selectRequestType = state => state.requestType

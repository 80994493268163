import React from 'react'
import PropTypes from 'prop-types'
import '../../styles/scss/components/bpg-confirmation.scss'
import ConfirmationCTAs from './ConfirmationCTAs'
import formatPrice from '../../utilities/formatPrice'
import check from '../../assets/icons/checkmark-highlight.svg'
import calculateTotalDiscount from '../../utilities/calculateTotalDiscount'
const BPGConfirmation = ({ resetFlow, cancellationData }) => {
  const pricing =
    cancellationData && cancellationData.pricing
      ? cancellationData.pricing
      : null

  const totalDiscount = calculateTotalDiscount(pricing)

  const id = cancellationData ? cancellationData.id : null

  return (
    <div className="bpg-confirmation">
      <div className="u-max-medium-container bpg-header u-text-center">
        <img src={check} alt="Success" />
        <h1 className="u-bold u-brand-blue bpg-title">You're All Set!</h1>
        <p className="bpg-credit-amount u-bold">
          Your Clients’{' '}
          {totalDiscount ? (
            <>
              <strong>${formatPrice(totalDiscount)}</strong> USD OBC
            </>
          ) : null}
        </p>
        <p className="bpg-credit-type u-brand-blue u-bold">
          Non-Refundable Onboard Credit has been applied to Booking ID: {id}
        </p>
      </div>
      <div className="u-max-medium-container bpg-paragraphs ">
        <p>
          No additional action is required on your part. You’ll receive an email
          confirmation and a booking invoice shortly. You can retrieve the
          reservation in Espresso to view your client’s non-refundable Onboard
          Credit.
        </p>
        <p>
          We look forward to welcoming your clients onboard in the near future.
        </p>
        <p>Thank you for your support and loyalty.</p>
      </div>
      <ConfirmationCTAs resetFlow={resetFlow} />
    </div>
  )
}

BPGConfirmation.propTypes = {
  resetFlow: PropTypes.func.isRequired,
}

export default BPGConfirmation

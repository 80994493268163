import React from 'react'
import { useSelector } from 'react-redux'
import ReactModal from 'react-modal'
import * as PropTypes from 'prop-types'
import { getActiveBrandClassName } from '../../redux/reducers/activeSearchFilters'

const Modal = props => {
  const brandClassName = useSelector(state => getActiveBrandClassName(state))

  return (
    <ReactModal
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      ariaHideApp={false}
      className={`u-modal ${brandClassName} ${
        props.className ? props.className : ''
      }`}
      overlayClassName={`u-modal-overlay ${brandClassName} ${
        props.overlayClassName ? props.overlayClassName : ''
      }`}
    >
      <div className="u-modal-content-container">
        <div className="u-modal-content">{props.children}</div>
      </div>
    </ReactModal>
  )
}

Modal.defaultProps = {
  hideCloseIcon: false,
  className: null,
  overlayClassName: null
}

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  overlayClassName: PropTypes.string
}

export default Modal

import React, { useState } from 'react'

import '../../styles/scss/components/custom-number.scss'

const CustomNumberInput = ({
  value,
  onChange,
  min,
  max,
  onBlur,
  disabled = false,
}) => {
  const [state, setState] = useState({
    isTransitioning: false,
    nextValue: null,
  })

  const handleButtonClick = nextValue => {
    setState({
      isTransitioning: true,
      nextValue,
    })

    setTimeout(() => {
      setState({
        isTransitioning: false,
        nextValue: null,
      })
      onChange(nextValue)
    }, 250)
  }

  return (
    <div className="custom-number">
      <div className={`custom-number-input ${disabled ? 'is-disabled' : ''}`}>
        <button
          type="button"
          disabled={!value || value <= min || state.isTransitioning || disabled}
          onClick={() => {
            const remainder = +value % 5
            handleButtonClick(+value - (remainder || 5))
          }}
        >
          -
        </button>
        <p className="currency">$</p>
        <div className="input-container">
          <input
            className={`${state.isTransitioning ? 'hidden' : ''}`}
            type="text"
            inputMode="numeric"
            max={max}
            min={min}
            step={0}
            value={value}
            disabled={state.isTransitioning || disabled}
            onChange={event => {
              if (!isNaN(event.target.value)) {
                onChange(+event.target.value)
              }
            }}
            onBlur={e => {
              if (onBlur) {
                onBlur(e)
              }
            }}
          />
          {state.isTransitioning && (
            <>
              <div
                className={`outgoing-value ${
                  state.nextValue > value ? 'up' : 'down'
                }`}
              >
                <p>{value}</p>
              </div>
              <div
                className={`incoming-value  ${
                  state.nextValue > value ? 'bottom' : 'top'
                }`}
              >
                <p>{state.nextValue}</p>
              </div>
            </>
          )}
        </div>
        <button
          type="button"
          disabled={value >= max || state.isTransitioning || disabled}
          onClick={() => {
            const remainder = +value % 5
            const newVal = +value + 5 - remainder

            handleButtonClick(newVal)
          }}
        >
          +
        </button>
      </div>
      <p className="instructions">Click to specify your own amount</p>
    </div>
  )
}

export default CustomNumberInput

import triggerCustomGaEvent from "../../utilities/triggerCustomGaEvent";

export const SET_SELECTED_PROGRAM = 'SET_SELECTED_PROGRAM'

export const setActiveSelectionData = selection => dispatch => {
  const payload = selection || {}
  if(selection?.value === 'obc') {
    triggerCustomGaEvent('obc-purchaseAndManageCredits', null)
  }
  dispatch({
    type: SET_SELECTED_PROGRAM,
    payload
  })
}

export const getSelectedProgramData = state => state.selectedProgramData

export const getProgramDisclaimer = state =>
  state.selectedProgramData?.disclaimerCopy || null

import React, { useState } from 'react'
import { string } from 'yup'

import '../../styles/scss/elements/multi-email-input.scss'

const emailSchema = string()
  .email()
  .required()

const MultiEmailInput = ({ emails, onAddEmail, onBlur, handleRemoveEmail }) => {
  const [value, setValue] = useState('')
  const handleSubmit = clearOnError => {
    const formatted = value.trim().replace(',', '')
    try {
      emailSchema.validateSync(formatted)
      onAddEmail(formatted)
      setValue('')
      return formatted
    } catch (err) {
      setValue(clearOnError ? '' : formatted)
    }
  }

  return (
    <div className="multi-email">
      {emails.length
        ? emails.map((email, index) => (
            <div className="email" key={email + index}>
              <p>{email}</p>
              {handleRemoveEmail && (
                <button
                  onClick={e => {
                    e.stopPropagation()
                    handleRemoveEmail({ email, index })
                  }}
                  aria-label="remove email"
                >
                  x
                </button>
              )}
            </div>
          ))
        : null}
      <input
        type="text"
        value={value}
        onBlur={() => {
          handleSubmit(true)
          if (onBlur) {
            onBlur()
          }
        }}
        onKeyDown={e => {
          if (
            !value.length &&
            emails?.length &&
            handleRemoveEmail &&
            (e.key === 'Backspace' || e.keyCode === 8)
          ) {
            //   delete the last email.
            handleRemoveEmail({
              index: emails.length - 1,
              email: emails[emails.length - 1],
            })
          }
          if (e.key === 'Enter' || e.keyCode === 13 || e.keyCode === 32) {
            handleSubmit()
          }
        }}
        onChange={e => {
          //   if "," or "enter" validate and submit email
          //     else set the value
          const lastCharacter = e.target.value.charAt(e.target.value.length - 1)
          if ([','].some(character => character === lastCharacter)) {
            handleSubmit()
          } else {
            setValue(e.target.value.trim())
          }
        }}
      />
    </div>
  )
}

export default MultiEmailInput

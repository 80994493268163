import { SET_SESSION_ID } from '../actions/setSessionId'
import { RESET_BOOKING_FLOW } from '../actions/fetchCancellationType'
import generateSessionId from '../../utilities/generateSessionId'
export default function(state = null, action) {
  if (action.type === SET_SESSION_ID) {
    return action.payload
  }

  if (action.type === RESET_BOOKING_FLOW) {
    return generateSessionId()
  }
  return state
}

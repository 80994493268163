import {
  SET_ACTIVE_SEARCH_BRAND,
  SET_ACTIVE_CANCELLATION_TYPE,
  SET_PRE_SELECTED_PROGRAM,
  SET_ACTIVE_LANGUAGE
} from '../actions/setActiveSearchFilters'
import pullmanturConfig from '../../configuration/pullmanturConfig'
import { selectBrandsData } from './brand'
import { selectShipsData } from './ships'

const initialState = {
  brand: null,
  cancellationType: '',
  lang: null,
  preSelectedProgram: null
}

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_ACTIVE_SEARCH_BRAND:
      return Object.assign({}, state, {
        brand: action.payload
      })
    case SET_ACTIVE_CANCELLATION_TYPE:
      return Object.assign({}, state, {
        cancellationType: action.payload
      })
    case SET_PRE_SELECTED_PROGRAM:
      return Object.assign({}, state, {
        preSelectedProgram: action.payload
      })
    case SET_ACTIVE_LANGUAGE:
      return Object.assign({}, state, {
        lang: action.payload
      })
    default:
      return state
  }
}

export const getActiveBrandData = state => {
  const activeBrand = state.activeSearchFilters.brand
  if (!activeBrand) return null
  // Pullmantur does not come from IPS, it is hard coded for this app only.
  if (activeBrand === 'P') {
    return pullmanturConfig.brandData
  }
  const brandsData = selectBrandsData(state)

  return brandsData
    ? brandsData.find(brand => brand['brand_code'] === activeBrand)
    : null
}

export const getActiveBrandClassName = state => {
  const activeBrand = getActiveBrandData(state)

  return getActiveBrandData(state)
    ? activeBrand.name.split(' ')[0].toLowerCase()
    : 'default'
}

export const getActiveShipsData = state => {
  if (state.activeSearchFilters.brand === 'P') {
    return pullmanturConfig.ships
  }

  const ships = selectShipsData(state)

  const filteredShips = ships
    ? ships.filter(ship => Boolean(!ship['is_retired']))
    : null

  if (!state.activeSearchFilters.brand) return filteredShips

  return filteredShips
    ? filteredShips.filter(
        ship => ship['brand_code'] === state.activeSearchFilters.brand
      )
    : null
}

export const getIsSpanish = state => {
  return Boolean(
    state.activeSearchFilters.brand === 'P' &&
      state.activeSearchFilters.lang === 'es'
  )
}

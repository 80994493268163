import React, { useState } from 'react'
import calIcon from '../../assets/icons/calendar.svg'
import { ListItem } from '../reservationReview/ReviewItemsList'
import shipIcon from '../../assets/icons/ship.svg'
import editIcon from '../../assets/icons/edit.svg'
import ItineraryModal from '../modals/ItineraryModal'
import moment from 'moment'
import formats from '../../configuration/formats'
import formatPackageName from '../../utilities/formatPackageName'

const LiftAndShiftBookingDetails = ({
  editable,
  back,
  reservationData,
  bookingData,
  brandInfo,
  shipData
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const packageID =
    bookingData && bookingData.inclusivePackageOption
      ? bookingData.inclusivePackageOption.packageCode
      : null

  let stateRoomName, itineraryName

  if (reservationData && bookingData) {
    stateRoomName = reservationData.berthedCategoryName
      ? reservationData.berthedCategoryName
      : reservationData.berthedCategoryCode

    itineraryName =
      formatPackageName(reservationData, bookingData.isCruiseTour) ||
      'Itinerary'
  }

  const bookingLabel =
    bookingData && Number(bookingData.type) === 9 ? 'Group ID' : 'Booking ID'

  return (
    <>
      <div className="u-border-bottom lift-shift-form-heading">
        <h2 className="u-text-center u-bold u-remove-margin">
          Booking Information
        </h2>
      </div>
      <div className="lift-shift-form-booking-info">
        <div className="lift-shift-form-booking-info-section u-border-bottom">
          <div className="lift-and-shift-sub-heading">
            <div className={editable ? 'u-flex u-flex-space-between' : ''}>
              <div className="u-icon-label">
                <img src={calIcon} alt="" />
                <p className="u-bold u-brand-blue">Itinerary Details</p>
              </div>
              {editable && back ? (
                <button className="btn btn-link" onClick={back}>
                  <div className="u-icon-label u-icon-label-xs">
                    <img src={editIcon} alt={'back'} />
                    <p className="u-brand-blue">Edit</p>
                  </div>
                </button>
              ) : null}
            </div>
          </div>

          {brandInfo && brandInfo.fullName && (
            <ListItem
              item={{
                label: 'Brand',
                value: brandInfo.fullName
              }}
              borderBottom
            />
          )}
          {shipData && shipData.name && (
            <ListItem
              item={{
                label: 'Ship',
                value: shipData.name
              }}
              borderBottom
            />
          )}
          {reservationData && reservationData.sailDate && (
            <ListItem
              item={{
                label: 'Sailing Date',
                value: moment(reservationData.sailDate, formats.date)
                  .format(formats.displayDateMedium)
                  .toUpperCase()
              }}
              borderBottom
            />
          )}
          {itineraryName && (
            <ListItem
              item={{
                label: 'Itinerary',
                value: itineraryName
              }}
              btnClassName="u-text-left"
              clickHandler={packageID ? () => setModalIsOpen(true) : null}
              borderBottom
            />
          )}

          {reservationData &&
            (reservationData.departurePortName ||
              reservationData.departurePort) && (
              <ListItem
                item={{
                  label: 'Departure Port',
                  value:
                    reservationData.departurePortName ||
                    reservationData.departurePort
                }}
              />
            )}
        </div>
        <div className="lift-shift-form-booking-info-section u-border-bottom">
          <div className="lift-and-shift-sub-heading">
            <div className={editable ? 'u-flex u-flex-space-between' : ''}>
              <div className="u-icon-label">
                <img src={shipIcon} alt="" />
                <p className="u-bold u-brand-blue">Cruise Details</p>
              </div>
              {editable && back ? (
                <button className="btn btn-link" onClick={back}>
                  <div className="u-icon-label u-icon-label-xs">
                    <img src={editIcon} alt={'back'} />
                    <p className="u-brand-blue">Edit</p>
                  </div>
                </button>
              ) : null}
            </div>
          </div>

          {bookingData && bookingData.id && (
            <ListItem
              item={{
                label: bookingLabel,
                value: bookingData.id
              }}
              borderBottom
            />
          )}
          {stateRoomName && (
            <ListItem
              item={{
                label: 'Category',
                value: stateRoomName
              }}
              borderBottom
            />
          )}

          {reservationData &&
          reservationData.guests &&
          reservationData.guests.length
            ? reservationData.guests.map((guest, i) => (
                <ListItem
                  key={`guest-${i}-${guest.givenName} ${guest.surname}`}
                  item={{
                    label: `Guest ${formats.guestLabels[i]}`,
                    value: `${guest.givenName} ${guest.surname}`
                  }}
                  borderBottom
                />
              ))
            : null}
        </div>
      </div>
      {modalIsOpen && (
        <ItineraryModal
          isOpen={modalIsOpen}
          onRequestClose={() => setModalIsOpen(false)}
          currentItineraryDetails={{
            id: packageID,
            brand: brandInfo ? brandInfo.code : null,
            sailDate: reservationData ? reservationData.sailDate : null
          }}
        />
      )}
    </>
  )
}

export default LiftAndShiftBookingDetails

import { SET_EDITED_AGENT_EMAIL } from '../actions/setEditedAgentDetails'
import { RESET_BOOKING_FLOW } from '../actions/fetchCancellationType'

const initialState = {}

export default function(state = initialState, action) {
  if (action.type === SET_EDITED_AGENT_EMAIL) {
    return {
      ...state,
      email: action.payload
    }
  }

  if (action.type === RESET_BOOKING_FLOW) {
    return initialState
  }

  return state
}

import React from 'react'
import { useSelector } from 'react-redux'
import { getCancellationTypeData } from '../../redux/reducers/cancellationTypeData'

import '../../styles/scss/components/obc-confirmation-header.scss'

const ObcConfirmationHeader = () => {
  const confirmationData = useSelector(state => state.confirmationData?.data)
  const cancellationData = useSelector(getCancellationTypeData)
  const isRefund = confirmationData?.confirmationType === 'refund'

  return (
    <div className="obc-confirmation-header">
      <h1 className="u-text-center u-uppercase u-bold title">
        {isRefund ? 'OBC Modified' : 'Congratulations!'}
      </h1>

      {isRefund ? (
        <div className="summary">
          <p>
            {confirmationData?.item?.toName} {confirmationData?.item?.amount}{' '}
            {confirmationData?.item?.shipCurrency || ''}(
            {confirmationData?.item?.obcAmountInBookingCurrency}{' '}
            {confirmationData?.item?.bookingCurrency})
          </p>
          <p>A refund has been issued</p>
        </div>
      ) : (
        <div className="summary">
          {confirmationData?.purchases?.length
            ? confirmationData?.purchases.map(purchase => (
                <p key={purchase.guestId}>
                  An Onboard Credit in the amount of $
                  {purchase.amount?.toFixed(2) || ''} USD, has been applied for{' '}
                  {purchase.toName} on Booking ID: {cancellationData?.id}
                </p>
              ))
            : null}
        </div>
      )}

      <div className="copy">
        <p>
          You're all set! The onboard credit has been applied for the selected
          guest and they can now use their onboard credit to purchase pre-cruise
          products such as shore excursions, beverage packages, specialty
          dining, spa & fitness, and so much more while they're onboard.
        </p>
      </div>
    </div>
  )
}

export default ObcConfirmationHeader

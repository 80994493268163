import React from 'react'
import TermsAndConditionsTriggerAndModal from '../modals/TermsAndConditionsTriggerAndModal'
import '../../styles/scss/components/submit-container.scss'
import checkMark from '../../assets/icons/checkmark.png'

const SubmitContainer = ({
  onSubmit,
  onCancel,
  isChecked,
  setIsChecked,
  termsText,
  disabled,
  buttonText = 'Submit Request',
  cancelButtonText = 'Cancel Request',
  hideTerms
}) => {
  return (
    <div className="u-background-white u-text-center u-border submit-container">
      {!hideTerms && (
        <div className="u-custom-terms-checkbox-container">
          <label className="u-custom-terms-checkbox-label">
            <div className="u-custom-terms-checkbox-input-container">
              <input
                type="checkbox"
                checked={isChecked}
                onChange={e => setIsChecked(e.target.checked)}
                data-testid="submit-container-checkbox"
              />
              {isChecked && <img src={checkMark} alt="check" />}
            </div>
            <span>{termsText} </span>
          </label>
          <TermsAndConditionsTriggerAndModal />
        </div>
      )}
      <button
        className="btn btn-primary"
        disabled={!isChecked || disabled}
        onClick={onSubmit}
      >
        {buttonText}
      </button>
      <br />
      <button className="btn btn-link" onClick={onCancel}>
        {cancelButtonText}
      </button>
    </div>
  )
}

export default SubmitContainer

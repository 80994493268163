const routes = {
  cancellationForm: '/',
  review: '/review',
  confirmation: '/confirmation',
  change: '/change',
  purchase: '/purchase',
}
export default routes

export const steps = [
  {
    title: 'Step 1',
    description: 'Enter information',
    value: routes.cancellationForm,
  },
  {
    title: 'Step 2',
    description: 'Review request',
    value: routes.review,
    altDescriptions: {
      bpg: 'Review & Submit',
      cvc: 'Review & Submit',
      fcc: 'Review & Submit',
    },
  },
  {
    title: 'Step 3',
    description: 'Confirmation',
    value: routes.confirmation,
  },
]

export const bpgReviewOnlySteps = [
  {
    title: 'Step 1',
    description: 'Enter information',
    value: routes.cancellationForm,
  },
  {
    title: 'Step 2',
    description: 'Review',
    value: routes.review,
  },
]

export const liftAndShiftSteps = [
  {
    title: 'Step 1',
    description: 'Start Your Request',
    value: routes.cancellationForm,
  },
  {
    title: 'Step 2',
    description: 'Choose Preferences',
    value: routes.change,
  },
  {
    title: 'Step 3',
    description: 'Review And Submit',
    value: routes.review,
  },
  {
    title: 'Step 4',
    description: 'Confirmation',
    value: routes.confirmation,
  },
]

export const purchaseObcSteps = [
  {
    title: 'Step 1',
    description: 'Enter Information',
    value: routes.cancellationForm,
  },
  {
    title: 'Step 2',
    description: 'Purchase Onboard Credit',
    value: routes.purchase,
  },
  {
    title: 'Step 3',
    description: 'Confirmation',
    value: routes.confirmation,
  },
]

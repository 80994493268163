import React from 'react'

import '../../styles/scss/components/fcc-receipt.scss'

const FCCReceipt = ({
  guestName,
  amount,
  currency,
  bookingId,
  certificateNumber,
  expirationDate,
  sailByDate,
  shipBrand,
  typeDetail,
}) => (
  <div className="fcc-receipt">
    <div className="fcc-receipt-row top">
      <div className="fcc-receipt-item name">
        <p className="fcc-receipt-item-label">Guest Name</p>
        <p className="fcc-receipt-value u-brand-blue">{guestName}</p>
      </div>

      <div className="fcc-receipt-row total">
        <div className="fcc-receipt-item total-label">
          <p>Certificate Amount</p>
        </div>
        <div className="fcc-receipt-item total-value">
          <p data-testid="fcc-total-credit">
            {Number(amount).toLocaleString('en-US')}
            <span>{currency}</span>
          </p>
        </div>
      </div>
    </div>

    <div className="fcc-receipt-row bottom">
      <div className="fcc-receipt-row ids">
        <div className="fcc-receipt-item booking-id">
          <p className="fcc-receipt-item-label">Booking ID</p>
          <p className="fcc-receipt-value u-brand-blue">{bookingId}</p>
        </div>
        <div className="fcc-receipt-item cert-id">
          <p className="fcc-receipt-item-label">Certificate Number</p>
          <p className="fcc-receipt-value u-brand-blue">{certificateNumber}</p>
        </div>
        <div className="fcc-receipt-item cert-type">
          <p className="fcc-receipt-item-label">FCC Type</p>
          <p className="fcc-receipt-value u-brand-blue">{typeDetail}</p>
        </div>
      </div>

      <div className="fcc-receipt-item dates">
        <div className="date">
          <p className="date-label fcc-receipt-item-label">Brand:</p>
          <p className="date-value">{shipBrand}</p>
        </div>
        <div className="date">
          <p className="date-label fcc-receipt-item-label">Sail By Date:</p>
          <p className="date-value">{sailByDate}</p>
        </div>
        <div className="date">
          <p className="date-label fcc-receipt-item-label">Expiration Date:</p>
          <p className="date-value">{expirationDate}</p>
        </div>
      </div>
    </div>
  </div>
)

export default FCCReceipt

import { RESET_BOOKING_FLOW } from '../actions/fetchCancellationType'
import { SET_REQUEST_DATA } from '../actions/fetchCancellationType'

export default function(state = { data: null }, action) {
  if (action.type === SET_REQUEST_DATA) {
    return {
      ...state,
      data: action.payload
    }
  }

  if (action.type === RESET_BOOKING_FLOW) {
    return {
      data: null
    }
  }

  return state
}

import React from 'react'
import Big from 'big.js'
import { isFinite } from 'lodash'

import { convertToBookingCurrency, convert } from './utilities'

import trashIconWhite from '../../assets/icons/trash.svg'
import trashIcon from '../../assets/icons/trash-black.svg'
import editIcon from '../../assets/icons/edit-icon_2.svg'
import editIconWhite from '../../assets/icons/edit-icon_2_white.svg'
import '../../styles/scss/components/obc-table.scss'

const formatNumber = value => {
  const num = +value
  return num && isFinite(num) ? num.toFixed(2) : value
}

const ObcTable = ({
  purchases,
  applicableOnBoardCredit,
  handleDelete,
  deleteIcon,
  isRefund = false,
  handleEdit,
  creditTotalLabel = 'Total Purchased Onboard Credit Value',
  amountTotalLabel = 'Total Amount Paid',
}) => {
  const totals = purchases.length
    ? purchases.reduce(
        (obj, purchase) => {
          // Don't include cancelled purchases in totals.
          if (purchase.optionStatus === 'X') {
            return obj
          }

          const { amount } = purchase
          const total = new Big(obj.total).add(+amount).toNumber()

          const totalConverted = convert(amount, applicableOnBoardCredit)
            .add(obj.totalConverted)
            .round(2)

          return {
            total,
            totalConverted,
          }
        },
        {
          total: 0,
          totalConverted: 0,
        },
      )
    : null
  return (
    <>
      <div
        className={`obc-table ${
          handleDelete || handleEdit ? 'with-delete' : ''
        }`}
      >
        <div className="item header col-1">
          <p>GUEST</p>
        </div>
        <div className="item header">
          <p>ONBOARD CREDIT VALUE</p>
        </div>

        <div className="item header">
          <p>PURCHASED AMOUNT</p>
        </div>
        <div className="item header">
          <p>STATUS</p>
        </div>
        {(handleDelete || handleEdit) && <div className="item header" />}
        {purchases.map((purchase, index) => {
          const isEven = !(index % 2)
          return (
            <React.Fragment
              key={
                purchase.key ||
                `${purchase.guestId}-${purchase.transactionReferenceNumber ||
                  ''}`
              }
            >
              <div className={`col-1 item ${isEven ? 'even' : 'odd'}`}>
                <p>
                  <span className="mobile">Guest: </span>
                  {purchase.toName}
                </p>
              </div>
              <div className={`item ${isEven ? 'even' : 'odd'}`}>
                <p>
                  <span className="mobile">OBC Value: </span>$
                  {formatNumber(purchase.amount)}{' '}
                  {applicableOnBoardCredit?.shipCurrency || ''}
                </p>
              </div>

              <div className={`item ${isEven ? 'even' : 'odd'}`}>
                <p>
                  <span className="mobile">Purchases Amount: </span>$
                  {convertToBookingCurrency(
                    purchase.amount,
                    applicableOnBoardCredit,
                  )}
                </p>
              </div>
              <div className={`item ${isEven ? 'even' : 'odd'}`}>
                <p>
                  <span className="mobile">Status: </span>
                  {purchase.status || 'Pending'}
                </p>
              </div>
              {(handleDelete || handleEdit) && (
                <div className={`item delete ${isEven ? 'even' : 'odd'}`}>
                  {handleEdit && (
                    <button
                      className={`remove ${isEven ? 'even' : 'odd'}`}
                      onClick={() => {
                        handleEdit(purchase)
                      }}
                    >
                      <img src={isEven ? editIconWhite : editIcon} alt="edit" />
                    </button>
                  )}
                  {handleDelete && (
                    <button
                      className={`remove ${isEven ? 'even' : 'odd'}`}
                      onClick={() => {
                        handleDelete(purchase)
                      }}
                    >
                      <img
                        src={
                          deleteIcon
                            ? deleteIcon
                            : isEven
                            ? trashIconWhite
                            : trashIcon
                        }
                        alt="delete"
                      />
                    </button>
                  )}
                </div>
              )}
            </React.Fragment>
          )
        })}
      </div>
      <div className="obc-table-totals-summary">
        <p className="title">Your Onboard Credit Summary</p>
        <div className="totals-breakdown">
          <p className="item">
            {creditTotalLabel}:{' '}
            <span className="lg blue">${totals?.total?.toFixed(2) || ''}</span>{' '}
            {applicableOnBoardCredit?.shipCurrency || ''}
          </p>
          <p className="item">
            {amountTotalLabel} :{' '}
            <span className="">
              ${totals?.totalConverted?.toFixed(2) || ''}
            </span>{' '}
            {applicableOnBoardCredit?.bookingCurrency || ''}
          </p>
        </div>
      </div>
      <div className="obc-table-totals-footer">
        <p className="new-totals-label">
          {isRefund ? 'Refund Total' : 'Total'}:
          <span> ${totals?.totalConverted?.toFixed(2) || ''}</span>
          {applicableOnBoardCredit?.bookingCurrency || ''}
        </p>
      </div>
    </>
  )
}

export default ObcTable

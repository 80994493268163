import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BPGConfirmation from '../../components/confirmation/BPGConfirmation'
import { RESET_BOOKING_FLOW } from '../../redux/actions/fetchCancellationType'
import dispatchAnalyticsEvent from '../../redux/actions/dispatchAnalyticsEvent'
import routes from '../../configuration/routes'

import '../../styles/scss/views/confirmation.scss'
import LiftConfirmation from '../../components/confirmation/LiftConfirmation'
import CWCConfirmation from '../../components/confirmation/CWCConfirmation'
import { selectRequestType } from '../../redux/reducers/requestType'
import FCCConfirmation from '../../components/confirmation/FCCConfirmation'
import ObcConfirmation from './ObcConfirmation'
import selectionCardUtilities from '../../components/selectionCards/selectionCardUtilities'
import { setRequestType } from '../../redux/actions/setRequestType'
import { setActiveSelectionData } from '../../redux/actions/setSelectedProgramData'
import { getAgentDetails } from '../../redux/reducers/ssoData'
import releaseActiveBooking from '../../redux/actions/releaseActiveBooking'

const ConfirmationContainer = ({
  handleRouteChange,
  cancellationData,
  brandInfo,
  shipData,
  isAuthenticated,
  activeBrandCode,
}) => {
  const requestType = useSelector(selectRequestType)
  const agentData = useSelector(getAgentDetails)
  const dispatch = useDispatch()
  function resetFlow() {
    dispatch(
      dispatchAnalyticsEvent('submitAnotherBooking', {
        shipData,
      }),
    )

    dispatch({ type: RESET_BOOKING_FLOW })
    if (requestType === 'obc') {
      dispatch(releaseActiveBooking(cancellationData.id))

      const obcProgramData = selectionCardUtilities
        .renderAuthenticatedOptions({
          countryCode: agentData.country,
        })
        ?.find(program => program.value === 'obc')

      if (obcProgramData && obcProgramData.enabled) {
        dispatch(setRequestType(obcProgramData.value))
        dispatch(setActiveSelectionData(obcProgramData))
      }
    }
    handleRouteChange(routes.cancellationForm)
  }

  useEffect(() => {
    // this is analytics only, hence the // eslint-disable-line react-hooks/exhaustive-deps
    dispatch(
      dispatchAnalyticsEvent('cancellationFormStepThree', {
        shipData,
      }),
    )
    dispatch(
      dispatchAnalyticsEvent(
        isAuthenticated
          ? 'agentCancellationFormStepThree'
          : 'consumerCancellationFormStepThree',
        {
          shipData,
        },
      ),
    )
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  function renderComponent() {
    switch (requestType) {
      case 'bpg':
        return (
          <BPGConfirmation
            resetFlow={resetFlow}
            cancellationData={cancellationData}
          />
        )
      case 'las':
        return (
          <LiftConfirmation
            resetFlow={resetFlow}
            isAuthenticated={isAuthenticated}
            cancellationData={cancellationData}
            brandInfo={brandInfo}
            shipData={shipData}
            activeBrandCode={activeBrandCode}
          />
        )
      case 'fcc':
        return (
          <FCCConfirmation
            resetFlow={resetFlow}
            isAuthenticated={isAuthenticated}
          />
        )
      case 'obc':
        return (
          <ObcConfirmation
            resetFlow={resetFlow}
            isAuthenticated={isAuthenticated}
          />
        )
      case 'cwc':
      case 'cs':
      case 'cvc':
      default:
        return (
          <CWCConfirmation
            resetFlow={resetFlow}
            isAuthenticated={isAuthenticated}
          />
        )
    }
  }

  return <div className="confirmation-container">{renderComponent()}</div>
}

export default ConfirmationContainer

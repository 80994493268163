import { getAgencyId } from '../reducers/ssoData'
import { releaseBooking } from '../../api/api'

const releaseActiveBooking = id => (dispatch, getState) => {
  const state = getState()

  const sessionId = state.sessionId
  const agencyId = getAgencyId(state)
  const fetchQuery = new URLSearchParams()

  fetchQuery.append('sessionId', sessionId)
  fetchQuery.append('agencyId', agencyId)

  const params = `/${id}/release?${fetchQuery.toString()}`

  releaseBooking(params)
}

export default releaseActiveBooking

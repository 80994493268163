import queryString from 'query-string'
import { configuration } from '../../api/api'
import { fetchSso } from './ssoData'
import { setActiveSearchFilters } from './setActiveSearchFilters'
import { setSessionId } from './setSessionId'
import generateSessionId from '../../utilities/generateSessionId'
import { fetchBrands } from './brands'
import { fetchShips } from './ships'
import { fetchLabels } from './labels'
export const FETCH_CONFIG = 'FETCH_CONFIG'
export const RECEIVE_CONFIG = 'RECEIVE_CONFIG'
export const CONFIG_ERROR = 'CONFIG_ERROR'

export const fetchConfig = () => async dispatch => {
  dispatch({ type: FETCH_CONFIG })
  dispatch(setSessionId(generateSessionId()))
  const queryParams = queryString.parse(window.location.search)

  const { program } = queryParams
  let programParam
  if (program) {
    if (programQueryParamOptions[program]) {
      programParam = programQueryParamOptions[program]
    }
  }
  let userData
  return configuration()
    .then(async res => {
      if (res.data && res.data['hydra:member']) {
        const payload = res.data['hydra:member'].map(config => {
          const { id, options, labels } = config
          return {
            id,
            options,
            labels,
          }
        })

        // the app needs all this data to work properly so we will show the
        // loading spinner until we have it all.
        await dispatch(fetchSso(queryParams)).then(userRes => {
          userData = userRes
        })
        dispatch(setActiveSearchFilters(queryParams))
        sessionStorage.clear()
        dispatch(fetchBrands())
        dispatch(fetchShips())
        dispatch(fetchLabels())
        dispatch({
          type: RECEIVE_CONFIG,
          payload,
        })
        return { userData, programParam }
      } else {
        dispatch({
          type: CONFIG_ERROR,
          payload: 'unable to fetch configuration data',
        })
      }
    })
    .catch(err => {
      console.log('error: ', err)
      dispatch({ type: CONFIG_ERROR, payload: err })
    })
}

const programQueryParamOptions = {
  cancel: 'cwc',
  refund: 'cs',
  liftshift: 'las',
  obc: 'obc',
  bestprice: 'bpg',
  fcc: 'fcc',
}

import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import routes, {
  steps,
  liftAndShiftSteps,
  bpgReviewOnlySteps,
  purchaseObcSteps,
} from '../../configuration/routes'

import '../../styles/scss/structure/stepper.scss'
import { RESET_BOOKING_FLOW } from '../../redux/actions/fetchCancellationType'
import {
  getCancellationTypeData,
  isBpgReviewOnly,
} from '../../redux/reducers/cancellationTypeData'
import releaseActiveBooking from '../../redux/actions/releaseActiveBooking'
import arrowIcon from '../../assets/icons/arrow-right-white.svg'
import arrowBlue from '../../assets/icons/arrow-right-blue.svg'
import { getIsAuthenticated } from '../../redux/reducers/ssoData'

const Stepper = ({ handleRouteChange, baseClassName, darkArrows }) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const requestTypeCode = useSelector(state => state.requestType)
  const isBpgReview = useSelector(isBpgReviewOnly)
  const currentFlowSteps = isBpgReview
    ? bpgReviewOnlySteps
    : requestTypeCode === 'las'
    ? liftAndShiftSteps
    : requestTypeCode === 'obc'
    ? purchaseObcSteps
    : steps
  const cancellationData = useSelector(state => getCancellationTypeData(state))
  const isAuthenticated = useSelector(getIsAuthenticated)
  const trackRef = useRef(null)
  const stepperRef = useRef(null)
  useEffect(() => {
    function placeHighlight() {
      if (stepperRef?.current && trackRef?.current) {
        const activeStep = stepperRef.current.querySelector(
          '.btn.active .step-btn-title',
        )

        if (!stepperRef.current || !activeStep) {
          return
        }

        const stepperPosition = stepperRef.current?.getBoundingClientRect()
        const targetPosition = activeStep?.getBoundingClientRect()
        const styles = {
          left: `${targetPosition.left - stepperPosition.left - 8}px`,
          width: `${targetPosition.width + 16}px`,
        }
        Object.entries(styles).forEach(([style, value]) => {
          trackRef.current.style[style] = value
        })
      }
    }
    placeHighlight()

    window.addEventListener('resize', placeHighlight)

    return () => window.removeEventListener('resize', placeHighlight)
  }, [location, stepperRef, trackRef])

  function handleClick(step) {
    if (
      step === routes.cancellationForm &&
      location.pathname !== routes.cancellationForm
    ) {
      if (
        requestTypeCode === 'bpg' &&
        cancellationData &&
        cancellationData.id
      ) {
        dispatch(releaseActiveBooking(cancellationData.id))
      }
      if (isAuthenticated) {
        //reset flow
        dispatch({
          type: RESET_BOOKING_FLOW,
        })
      }
    }

    handleRouteChange(step)
  }

  function setDisabled(step, index) {
    if (requestTypeCode === 'las' && location.pathname === routes.review) {
      return !(step === routes.change || step === routes.cancellationForm)
    }

    if (!index) {
      return false
    }

    return step !== routes.cancellationForm
  }

  return (
    <div className={`stepper ${baseClassName || ''}`} ref={stepperRef}>
      {currentFlowSteps.map((step, index) => {
        const isActive = step.value
          .split(',')
          .some(path => path.trim() === location.pathname)
        const description =
          step?.altDescriptions?.[requestTypeCode] || step.description
        return (
          <React.Fragment key={step.value}>
            <button
              className={`step-btn btn ${isActive ? 'active' : ''}`}
              disabled={setDisabled(step.value, index)}
              value={step.value}
              onClick={() => handleClick(step.value)}
            >
              <div className="step-btn-inner">
                <p className="step-btn-title u-flex">
                  {step.title}
                  <span className="step-btn-title-description">
                    {' '}
                    : {description}
                  </span>
                </p>
              </div>
            </button>
            {index + 1 < currentFlowSteps?.length && (
              <img
                className=""
                src={darkArrows ? arrowBlue : arrowIcon}
                alt="->"
              />
            )}
          </React.Fragment>
        )
      })}
      <div className="track" ref={trackRef} />
    </div>
  )
}

Stepper.propTypes = {
  handleRouteChange: PropTypes.func.isRequired,
}

export default Stepper

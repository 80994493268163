import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'

import Logos from '../../components/structure/Logos'
import 'react-dates/initialize'

import { getConfigData } from '../../redux/reducers/config'
import { fetchConfig } from '../../redux/actions/fetchConfiguration'
import LoadingScreen from '../../components/elements/LoadingScreen'
import Views from '../views/Views'
import { getActiveBrandData } from '../../redux/reducers/activeSearchFilters'
import brands from '../../configuration/brands'
import { appDataIsLoading } from '../../redux/actions/brands'
import { getIsAuthenticated } from '../../redux/reducers/ssoData'
import Modal from '../../components/elements/Modal'
import InvalidPathModal from '../../components/modals/InvalidPathModal'
import routes from '../../configuration/routes'

import videoSrc from '../../assets/videos/default-bg-1.mp4'
import videoSrcPlaceholder from '../../assets/videos/default-bg-1-placeholder.jpg'
import ProgramSelection from '../views/ProgramSelection'
import { RESET_BOOKING_FLOW } from '../../redux/actions/fetchCancellationType'
import { getCancellationTypeData } from '../../redux/reducers/cancellationTypeData'
import releaseActiveBooking from '../../redux/actions/releaseActiveBooking'
import selectionCardUtilities from '../../components/selectionCards/selectionCardUtilities'
import { setRequestType } from '../../redux/actions/setRequestType'
import { setActiveSelectionData } from '../../redux/actions/setSelectedProgramData'

function App() {
  const configData = useSelector(state => getConfigData(state))
  const appIsLoading = useSelector(state => appDataIsLoading(state))
  const activeBrand = useSelector(state => getActiveBrandData(state))
  const isAuthenticated = useSelector(state => getIsAuthenticated(state))
  const activeBrandCode = useSelector(state => state.activeSearchFilters.brand)
  const selectedProgram = useSelector(state => state.requestType)
  const cancellationData = useSelector(getCancellationTypeData)

  const brandClassName = activeBrand
    ? activeBrand.name.split(' ')[0].toLowerCase()
    : 'default'

  const [invalidPathModalIsOpen, setInvalidPathModal] = useState(false)

  const dispatch = useDispatch()

  const location = useLocation()
  const history = useHistory()

  function handleRouteChange(target) {
    history.push(target + location.search)
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    if (!configData.data && !configData.loading && !configData.error) {
      dispatch(fetchConfig()).then(res => {
        if (res?.userData && res?.programParam) {
          const programOption = selectionCardUtilities
            .renderAuthenticatedOptions({
              countryCode: res?.userData?.agent?.country,
            })
            ?.find(program => program.value === res?.programParam)
          if (programOption && programOption.enabled) {
            dispatch(setRequestType(programOption.value))
            dispatch(setActiveSelectionData(programOption))
          }
        }
      })
    }
  }, [configData, dispatch])

  useEffect(() => {
    if (
      configData.data &&
      !appIsLoading &&
      !Boolean(activeBrandCode || isAuthenticated) &&
      !invalidPathModalIsOpen
    ) {
      setInvalidPathModal(true)
    }
  }, [
    configData.data,
    appIsLoading,
    activeBrandCode,
    isAuthenticated,
    invalidPathModalIsOpen,
  ])

  const resetFlow = () => {
    if (
      (selectedProgram === 'bpg' || selectedProgram === 'obc') &&
      cancellationData?.id
    ) {
      dispatch(releaseActiveBooking(cancellationData.id))
    }
    dispatch({ type: RESET_BOOKING_FLOW })
    handleRouteChange(routes.cancellationForm)
  }

  useEffect(() => {
    const handleUnload = () => {
      if (selectedProgram === 'obc' && cancellationData?.id) {
        resetFlow()
      }
    }

    window.addEventListener('beforeunload', handleUnload)

    return () => {
      window.removeEventListener('beforeunload', handleUnload)
    }
  })

  if (!configData.data || configData.loading || appIsLoading)
    return <LoadingScreen />

  function renderHelmet() {
    if (!activeBrand) {
      return (
        <Helmet>
          <title>Partner Express</title>
          <link rel="icon" href={brands.R.favicon} />
        </Helmet>
      )
    }

    if (activeBrand && activeBrand['brand_code'] === 'P') {
      return (
        <Helmet>
          <title>Pullmantur Refund Information Form</title>
          <link rel="icon" href={brands.R.favicon} />
        </Helmet>
      )
    }
    return (
      <Helmet>
        <title>
          {brands[activeBrand['brand_code']].fullName} Cruise With Confidence
        </title>
        <link rel="icon" href={brands[activeBrand['brand_code']].favicon} />
      </Helmet>
    )
  }

  const isNoLongerSupported = !!activeBrand
  return isNoLongerSupported ? (
    <h2>404 - this feature is no longer available</h2>
  ) : (
    <>
      <div className={`cancel-reservation video-bg ${brandClassName}`}>
        {renderHelmet()}
        <video
          playsInline
          autoPlay
          muted
          loop
          id="bg-vid"
          poster={videoSrcPlaceholder}
        >
          <source src={videoSrc} type="video/mp4" />
        </video>
        <div className="gradient-bg-overlay" />
        {isAuthenticated && (
          <ProgramSelection
            activeBrandCode={activeBrandCode}
            handleRouteChange={handleRouteChange}
            isActive={!selectedProgram}
            resetFlow={resetFlow}
            isAuthenticated={isAuthenticated}
          />
        )}

        <div className="cancel-reservation-content-container">
          <Logos
            activeBrand={activeBrand}
            placeAbsolute={location.pathname === routes.programSelection}
          />
          <div
            className="main-content-container"
            data-testid="main-content-container"
          >
            <Views
              handleRouteChange={handleRouteChange}
              activeBrandCode={activeBrandCode}
              selectedProgram={selectedProgram}
              resetFlow={resetFlow}
            />
          </div>
        </div>

        {invalidPathModalIsOpen && (
          <Modal onRequestClose={() => null} isOpen={invalidPathModalIsOpen}>
            <InvalidPathModal />
          </Modal>
        )}
      </div>
    </>
  )
}

export default App

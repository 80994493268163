import React from 'react'
import * as PropTypes from 'prop-types'
import Modal from '../elements/Modal'
import '../../styles/scss/components/error-modal.scss'
const ErrorModal = props => {
  return (
    <Modal
      onRequestClose={props.onRequestClose}
      isOpen={props.isOpen}
      className="error-modal"
      overlayClassName="error-modal-overlay"
    >
      <div className="error-modal-content u-text-center">
        <div className="error-modal-content-text-container">
          {props.message &&
            typeof props.message === 'object' &&
            Object.entries(props.message).map(([key, error], i) => {
              return <p key={`error-message-${key}-${i}`}>{error}</p>
            })}
        </div>
        <button className="btn btn-primary" onClick={props.onRequestClose}>
          Close
        </button>
      </div>
    </Modal>
  )
}
ErrorModal.defaultProps = {
  message: {
    1: 'Something went wrong'
  }
}
ErrorModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  message: PropTypes.shape({})
}
export default ErrorModal

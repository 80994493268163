import React from 'react'
import AzaTerms from './BrandTerms/AzaTerms'
import CelebTerms from './BrandTerms/CelebrityTerms'
import RoyalTerms from './BrandTerms/RoyalTerms'
import TriBrandTerms from './BrandTerms/TriBrandedTerms'

// Royal has split to Dual Terms + Azamara
// doing it this way to prevent circular dependencies
const CombinedTerms = () => (
  <>
    <TriBrandTerms />
    <div style={{ paddingTop: '80px' }}>
      <h2 className="u-text-center title">
        Azamara
        <br />
        Cruise With Confidence
        <br />
        Terms and Conditions
      </h2>
      <AzaTerms />
    </div>
  </>
)

const TermsAndConditions = ({ brandCode }) => {
  if (!brandCode || brandCode === 'D') {
    return <CombinedTerms />
  }

  if (brandCode === 'R') {
    return <RoyalTerms />
  }

  if (brandCode === 'C') {
    return <CelebTerms />
  }

  if (brandCode === 'Z') {
    return <AzaTerms />
  }

  return <CombinedTerms />
}

export const validBPGDate = '2022-04-30'

export default TermsAndConditions

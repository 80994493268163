import triggerCustomGaEvent from '../../utilities/triggerCustomGaEvent'
import { getCancellationTypeData } from '../reducers/cancellationTypeData'

const dispatchAnalyticsEvent = (eventName, additionalData = {}) => (
  dispatch,
  getState
) => {
  const state = getState()

  const brandKey = state.activeSearchFilters.brand
    ? state.activeSearchFilters.brand
    : 'D'

  const brandName = analyticsBrandNames[brandKey]
  // there are a few events that only take the event brand name.
  const singleArgumentEvents = [
    'cancellationFormLaunched',
    'consumerCancellationFormLaunched',
    'agentCancellationFormLaunched',
    'cruiseRefund',
    'cruiseRefundConsumer',
    'cruiseRefundAgent'
  ]
  if (singleArgumentEvents.some(name => name === eventName)) {
    return triggerCustomGaEvent(eventName, brandName)
  }

  const cancellationData = getCancellationTypeData(state)
  const requestType = state.requestType

  const cancellationProgramType = analyticProgramNames[requestType]
  const liftAndShiftSelections = state.liftAndShiftSelections

  triggerCustomGaEvent(eventName, {
    brandName,
    cancellationData,
    cancellationProgramType,
    liftAndShiftSelections,
    ...additionalData,
    ...state
  })
}

const analyticProgramNames = {
  las: 'Lift and Shift',
  cs: 'Cruise Suspension',
  cwc: 'Cruise With Confidence',
  bpg: 'Best Price Guarantee'
}

const analyticsBrandNames = {
  C: 'Celebrity',
  R: 'Royal Caribbean',
  Z: 'Azamara',
  P: 'Pullmantur Cruises',
  D: 'Tri-Branded'
}

export default dispatchAnalyticsEvent

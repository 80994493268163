import React, { useState } from 'react'
import LiftPreference from './LiftPreference'
import ItineraryModal from '../modals/ItineraryModal'

const LiftAndShiftPreferences = ({
  selections,
  setSelections,
  disabled,
  bookingData,
  disableModal
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [currentItineraryDetails, setCurrentItineraryDetails] = useState({})

  function setItinerary(id, brand, sailDate) {
    setCurrentItineraryDetails({
      id,
      brand,
      sailDate
    })
    setModalIsOpen(true)
  }

  if (!bookingData || !bookingData.liftAndShiftOptions) return null

  const numberOfOptions = Object.values(bookingData.liftAndShiftOptions).reduce(
    (num, shipOption) => {
      if (shipOption.dates && Object.keys(shipOption.dates).length) {
        Object.values(shipOption.dates).forEach(dateOption => {
          if (dateOption && Object.keys(dateOption).length) {
            num += Object.keys(dateOption).length
          }
        })
      }
      return num
    },
    0
  )

  return (
    <>
      <LiftPreference
        number={1}
        shipOptions={bookingData ? bookingData.liftAndShiftOptions : null}
        selections={selections}
        setSelections={setSelections}
        disabled={disabled}
        openItineraryModal={setItinerary}
        disableModal={disableModal}
        isCruiseTour={bookingData.isCruiseTour}
      />
      {numberOfOptions >= 2 && (
        <LiftPreference
          number={2}
          shipOptions={bookingData ? bookingData.liftAndShiftOptions : null}
          selections={selections}
          setSelections={setSelections}
          disabled={disabled}
          openItineraryModal={setItinerary}
          disableModal={disableModal}
          isCruiseTour={bookingData.isCruiseTour}
        />
      )}
      {numberOfOptions >= 3 && (
        <LiftPreference
          number={3}
          shipOptions={bookingData ? bookingData.liftAndShiftOptions : null}
          selections={selections}
          setSelections={setSelections}
          disabled={disabled}
          openItineraryModal={setItinerary}
          disableModal={disableModal}
          isCruiseTour={bookingData.isCruiseTour}
        />
      )}

      {modalIsOpen ? (
        <ItineraryModal
          currentItineraryDetails={currentItineraryDetails}
          isOpen={modalIsOpen}
          onRequestClose={() => setModalIsOpen(false)}
        />
      ) : null}
    </>
  )
}

export default LiftAndShiftPreferences

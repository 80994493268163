import React from 'react'

import '../../styles/scss/components/shared-terms-modal-styles.scss'

const BpgTermsAndConditions = ({ dismissSubmit, submit }) => {
  return (
    <div
      data-testid="bgp-modal-submit"
      className="bgp-modal-submit terms-modal"
    >
      <h1 className="bgp-modal-submit-title title u-text-center u-base-font">
        Royal Caribbean International & Celebrity Cruises
        <br />
        Best Price Guarantee
      </h1>
      <h2 className="bgp-modal-submit-sub-title u-text-center u-base-font u-margin-bottom-32">
        (Bookings created after the final payment due date, on or after April 1,
        2022)
      </h2>
      <div>
        <div>
          <p className="u-margin-bottom-32 u-brand-blue u-bold">
            Travel Advisor Terms & Conditions
          </p>
          <p className="u-margin-bottom-16">
            If you found an Eligible Lower Rate for your client’s Royal
            Caribbean International or Celebrity Cruises (each, a “Cruise Line”)
            cruise within 48 hours of booking and the booking was made after the
            final payment due date, you may submit this Best Price Guarantee
            Claim to request that the difference in price be applied to the
            booking as a non-refundable onboard credit. The sailing and
            stateroom category must still be open and have availability at the
            time of submitting this request to qualify.
          </p>
          <p className="u-margin-bottom-16">
            <u>Note:</u> Because it is past the final payment due date, the
            booking is not eligible for any price adjustments, refunds, or
            exchanges on any promotional offers.
          </p>
          <p className="u-margin-bottom-16">
            To qualify, this request must be submitted within 48 hours of
            booking. The Cruise Line’s determination as to eligibility shall be
            final and binding with respect to all matters related to the Best
            Price Guarantee.
          </p>
          <p className="u-margin-bottom-16">
            The Best Price Guarantee applies to cruise fare only and will not
            apply to any taxes/fees, or any fuel or occupancy supplements.
            “Eligible Lower Rate” means a lower rate advertised by that Cruise
            Line at the time of this request, as available to the general public
            in the market where the guest resides for the same ship, sail date,
            stateroom category and number of guests.
          </p>
          <p className="u-margin-bottom-16">
            An F.I.T. (individual) cruise is "booked" when the reservation is
            confirmed, guest names have been added and the initial deposit
            required by the Cruise Line has been received by the Cruise Line.
            For group bookings, a cruise is considered “booked” as specified by
            the Cruise Line’s policies, as the same may change from time to
            time. At a minimum, guest names must have been added and the initial
            deposits required by Cruise Line shall have been received by Cruise
            Line. An onboard “Cruise Later” booking is not considered “booked”
            until guest names have been added, the initial deposit required by
            the Cruise Line has been received by the Cruise Line, and the
            booking has been converted to a specific ship and sail date. Best
            Price Guarantee is not available to guests booked on chartered
            sailings or in contracted groups. Best Price Guarantee onboard
            credits are non-refundable, non-transferable and have no cash value.
            Any unused portion of the onboard credit will be forfeited at
            10:00pm on the last night of the cruise. The Best Price Guarantee is
            subject to change and may be discontinued at any time without
            notice.
          </p>
          <p className="u-margin-bottom-16">
            <strong>Travel Advisor Note:</strong> Best Price Guarantee onboard
            credits are non-commissionable, and the value of such onboard
            credits may be excluded from any other incentive program(s)
            available to travel advisors.
          </p>
          <p>
            ©2022 Royal Caribbean Cruises Ltd. Ships’ registry: Bahamas, Malta
            and Ecuador.
          </p>
        </div>
      </div>
      <div className="bpg-terms-footer">
        <div className="u-flex u-flex-align-center u-margin-bottom-16">
          <button
            onClick={submit}
            className="btn btn-primary btn-oval with-arrow u-uppercase"
          >
            I Agree
          </button>
          <p className="u-remove-margin u-margin-left-8">
            Continue to process the non-refundable Onboard Credit (OBC).
          </p>
        </div>
        <div className="u-flex u-flex-align-center">
          <button
            onClick={dismissSubmit}
            className="btn btn-oval btn-white with-arrow u-uppercase"
          >
            Cancel
          </button>
          <p className="u-remove-margin u-margin-left-8">
            I’ve changed my mind and would like to cancel the non-refundable
            Onboard Credit (OBC) request.
          </p>
        </div>
      </div>
    </div>
  )
}

export default BpgTermsAndConditions

import React, { useState } from 'react'

import generateFormData from './utils'
import ReservationForm from './ReservationForm'

const AgentReservationForm = ({ submitForm, selectedProgram, disabled }) => {
  const {
    initialValues,
    schema,
    fields,
    shouldDisableSubmit,
  } = generateFormData({ selectedProgram, isAuthenticated: true })

  const [formErrors, setFormErrors] = useState({})

  function handleSubmit(values) {
    if (selectedProgram === 'fcc') {
      return submitForm(values, null)
    }
    if (selectedProgram === 'cvc') {
      return submitForm(values, null)
    }
    // las, cwc, cs, bpg all use this.
    return defaultSubmit(values)
  }

  function defaultSubmit(values) {
    let formType = null
    let hasErrors = false

    const filteredValues = Object.entries(values).reduce((obj, [key, val]) => {
      if (val && val.length) {
        const formattedValue = val.toString().trim()

        // validate the value, first checks for only digits.
        if (!/^\d+$/.test(formattedValue) || formattedValue.length > 7) {
          hasErrors = true
          setFormErrors({
            ...formErrors,
            [key]: true,
          })
        }
        obj[key] = formattedValue
        formType = key
      }
      return obj
    }, {})
    if (!hasErrors) {
      submitForm(filteredValues, formType)
    }
  }

  return (
    <ReservationForm
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      schema={schema}
      shouldDisableSubmit={shouldDisableSubmit}
      selectedProgram={selectedProgram}
      testId="agent-form"
      fields={fields}
      disabled={disabled}
    />
  )
}

export default AgentReservationForm

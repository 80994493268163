import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Modal from '../elements/Modal'
import '../../styles/scss/components/terms-and-conditions-modal.scss'
import { getActiveBrandData } from '../../redux/reducers/activeSearchFilters'
import TermsAndConditions from '../../configuration/termsAndConditions'

const TermsAndConditionsTriggerAndModal = ({
  linkText = 'Cruise With Confidence Terms & Conditions',
  title = 'Royal Caribbean International and Celebrity Cruises - Cruise With Confidence Program - Terms & Conditions'
}) => {
  const [modalIsOpen, setModalVisibility] = useState(false)
  const brandData = useSelector(state => getActiveBrandData(state))
  const currentBrandCode = brandData ? brandData['brand_code'] : 'D'
  function handleOnClick(e) {
    e.preventDefault()
    setModalVisibility(true)
  }

  return (
    <>
      <button onClick={handleOnClick} className="btn btn-hollow btn-link">
        {linkText}
      </button>
      {modalIsOpen && (
        <Modal
          onRequestClose={() => setModalVisibility(false)}
          isOpen={modalIsOpen}
          className={'terms-and-conditions-modal'}
          hideCloseIcon
        >
          <>
            <div className={'terms-and-conditions-modal-text-content'}>
              <h1 className="terms-and-conditions-modal-title u-text-center u-base-font">
                {title}
              </h1>
              <TermsAndConditions brandCode={currentBrandCode} />
            </div>
            <div className="u-text-center">
              <button
                className="btn btn-primary "
                onClick={() => setModalVisibility(false)}
              >
                Return to Request Form
              </button>
            </div>
          </>
        </Modal>
      )}
    </>
  )
}

export default TermsAndConditionsTriggerAndModal

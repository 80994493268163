import React from 'react'

const CovidRoyalPolicy = () => (
  <>
    <div className="covid-cancellation-policy">
      <p className="u-margin-x-8 u-margin-bottom-32">
        The following information applies to residents of North America who book
        passage on Royal Caribbean International (“RCI” or the “cruise line”)
        sailings scheduled during the nationally declared Public Health
        Emergency involving COVID-19. Except as specifically stated below, or as
        otherwise provided in the Cruise/CruiseTour Ticket Contract (the “Ticket
        Contract”) for your cruise, the standard cancellation policies and
        penalties described in the Ticket Contract apply. The below updated
        policies are effective from October 1, 2022 (the “Effective Date”) and
        apply to all bookings of RCI cruises scheduled to sail between the
        Effective Date and April 30, 2023, inclusive.
      </p>
      <p>
        <strong>CRUISE CANCELLED BY RCI</strong>
      </p>
      <p className="u-margin-x-8 u-margin-bottom-32">
        If RCI cancels your cruise or boarding is delayed by three (3) days or
        more and you elect not to embark on the delayed cruise or a substitute
        cruise offered by RCI, you will be entitled to a refund of the cruise
        fare paid to RCI, or an optional Future Cruise Credit (“FCC”) if RCI
        offers you a FCC in lieu of a refund.
      </p>
      <p>
        <strong>CRUISE BOOKING CANCELLED BY GUEST*</strong>
      </p>
      <ul>
        <li className="u-margin-bottom-32">
          If you cancel a cruise booking due to you, or someone else in your
          Traveling Party, testing positive for COVID-19 within 10 days before
          embarkation, you, and anyone else in your Traveling Party who cancels,
          are eligible for a refund of the cruise fare paid to RCI. To qualify
          for a refund, if your COVID-19 test was administered by a provider
          other than one retained by RCI, you must present your verified
          positive test result in a form acceptable to RCI.
        </li>
        <li className="u-margin-bottom-32">
          If you had a confirmed close contact with a person who tested positive
          for COVID-19, or who is suspected of having COVID-19, within 10 days
          before embarkation and RCI deems you are unfit to travel, you are
          eligible for a refund of the cruise fare paid to RCI.
        </li>
        <li className="u-margin-bottom-32">
          If we determine that you may have been exposed to or are likely to
          have been infected by COVID-19 within 10 days before embarkation, we
          may require that you and others living with you in the same household
          do not travel to the departure port. This is in order to prevent the
          transmission of COVID-19. Anyone effectively denied boarding in these
          circumstances will be entitled to a refund for the cruise fare paid to
          RCI.
        </li>
      </ul>
      <p>
        <strong className="u-margin-bottom-32">
          DENIAL OF EMBARKATION OR REBOARDING; QUARANTINE AND/OR DISEMBARKATION
        </strong>
      </p>
      <ul>
        <li>
          If you are denied embarkation or reboarding, or quarantined or
          disembarked during your cruise, due to testing positive for COVID-19
          or being suspected of having COVID-19, you are entitled to a refund
          for the cruise fare paid to RCI in the event of denial at embarkation,
          or a pro-rated refund for the unused portion of your cruise fare in
          all other cases. If you are required to quarantine onboard the vessel,
          days spent in quarantine shall be treated as unused days of the
          cruise.
        </li>
      </ul>
      <p>
        <strong>
          OBLIGATION TO COMPLY WITH RCI COVID-19 POLICIES AND PROCEDURES
        </strong>
      </p>
      <p>
        Guests denied embarkation or reboarding, or who are disembarked or
        quarantined during the voyage, for failure to comply with the
        <strong>
          <u
            style={{ color: '#2a467b', marginLeft: '5px', marginRight: '5px' }}
          >
            RCI COVID-19 Policies and Procedures
          </u>
        </strong>
        in effect at the time of the cruise, shall not be entitled to a refund
        or FCC, compensation of any kind, or any of the assistance described in
        this Policy. Please refer to the Ticket Contract issued for your cruise
        for complete details.
      </p>
      <p>
        <strong className="u-margin-bottom-32">GENERAL</strong>
      </p>
      <p>
        For purposes of this Policy, your “Traveling Party” means your family
        members living with you in the same household and traveling companions
        assigned to your stateroom on the cruise.
      </p>
      <p>This Policy does not apply to guests booked on chartered sailings.</p>
      <p>
        The terms of this Policy are valid from the Effective Date below,
        subject to change, and will remain in full force until we choose, in our
        sole discretion, to update or modify all or part of it. Updates or the
        modifications may be made and shall be effective without publication,
        although we will endeavor to post any updates or changes in a timely
        manner to a publicly accessible forum such as the Royal Caribbean
        International website or mobile phone application.
      </p>
      <p>
        <i>
          * All refund requests must be made within six (6) months after the
          scheduled embarkation date.
        </i>
      </p>

      <p className="u-margin-x-8 u-margin-bottom-32">
        Effective Date: October 1, 2022
      </p>
    </div>
    <div>
      <div
        className="u-text-center title bold"
        style={{ marginTop: '65px', marginBottom: '20px' }}
      >
        <h1 className="u-remove-margin">
          <strong>ROYAL CARIBBEAN INTERNATIONAL</strong>
        </h1>
        <h1 className="u-remove-margin">
          <strong>REFUND AND CANCELLATION POLICY FOR COVID-19</strong>
        </h1>
        <h1 className="u-remove-margin">
          <strong> (OUTSIDE OF NORTH AMERICA) </strong>
        </h1>
      </div>
      <p>
        The following information applies to residents outside North America who
        book passage on Royal Caribbean International (“RCI” or the “cruise
        line”) sailings scheduled during a nationally declared public health
        emergency involving COVID-19. Except as specifically stated below, or as
        otherwise provided in the Cruise/CruiseTour Ticket Contract or Booking
        Conditions (the “Ticket Contract”) for your cruise, the standard
        cancellation policies and penalties described in the Ticket Contract
        apply. The below updated policies are effective from October 1, 2022
        (the “Effective Date”) and apply to all bookings of RCI cruises
        scheduled to sail between the Effective Date and April 30, 2023,
        inclusive.
      </p>
      <p>
        <strong>CRUISE CANCELLED BY RCI </strong>
      </p>
      <p>
        If RCI cancels your cruise or boarding is delayed by three (3) days or
        more and you elect not to embark on the delayed cruise or a substitute
        cruise offered by RCI, you will be entitled to a refund of the cruise
        fare paid to RCI, or an optional Future Cruise Credit (“FCC”) if RCI
        offers you a FCC in lieu of a refund
      </p>
      <p>
        <strong>CRUISE BOOKING CANCELLED BY GUEST*</strong>
      </p>
      <ul>
        <li className="u-margin-bottom-32">
          If you cancel a cruise booking due to you, or someone else in your
          Traveling Party, testing positive for COVID-19 within 10 days before
          embarkation, you, and anyone else in your Traveling Party who cancels,
          are eligible for a refund of the cruise fare paid to RCI. To qualify
          for a refund, if your COVID-19 test was administered by a provider
          other than one retained by RCI, you must present your verified
          positive test result in a form acceptable to RCI.
        </li>
        <li>
          If you had a confirmed close contact with a person who tested positive
          for COVID-19, or who is suspected of having COVID-19, within 10 days
          before embarkation and RCI deems you are unfit to travel, you are
          eligible for a refund of the cruise fare paid to RCI.
        </li>
        <li>
          If we determine that you may have been exposed to or are likely to
          have been infected by COVID-19 within 10 days before embarkation, we
          may require that you and others living with you in the same household
          do not travel to the departure port. This is in order to prevent the
          transmission of COVID-19. Anyone effectively denied boarding in these
          circumstances will be entitled to a refund for the cruise fare paid to
          RCI.
        </li>
      </ul>
      <p>
        <strong>
          DENIAL OF EMBARKATION OR REBOARDING; QUARANTINE AND/OR DISEMBARKATION
        </strong>
      </p>
      <ul>
        <li>
          If you are denied embarkation or reboarding, or quarantined or
          disembarked during your cruise, due to testing positive for COVID-19
          or being suspected of having COVID-19, you are entitled to a refund
          for the cruise fare paid to RCI in the event of denial at embarkation,
          or a pro-rated refund for the unused portion of your cruise fare in
          all other cases. If you are required to quarantine onboard the vessel,
          days spent in quarantine shall be treated as unused days of the
          cruise.
        </li>
      </ul>
      <p>
        <strong>
          OBLIGATION TO COMPLY WITH RCI COVID-19 POLICIES AND PROCEDURES
        </strong>
      </p>
      <p>
        Guests denied embarkation or reboarding, or who are disembarked or
        quarantined during the voyage, for failure to comply with the
        <strong>
          <u
            style={{ color: '#2a467b', marginLeft: '5px', marginRight: '5px' }}
          >
            RCI COVID-19 Policies and Procedures
          </u>
        </strong>
        in effect at the time of the cruise, shall not be entitled to a refund
        or FCC, compensation of any kind, or any of the assistance described in
        this Policy. Please refer to the Ticket Contract issued for your cruise
        for complete details.
      </p>
      <p>
        <strong>GENERAL</strong>
      </p>
      <p>
        For purposes of this Policy, your “Traveling Party” means your family
        members living with you in the same household and traveling companions
        assigned to your stateroom on the cruise.
      </p>
      <p>This Policy does not apply to guests booked on chartered sailings.</p>
      <p>
        The terms of this Policy are valid from the Effective Date below,
        subject to change, and will remain in full force until we choose, in our
        sole discretion, to update or modify all or part of it. Updates or the
        modifications may be made and shall be effective without publication,
        although we will endeavor to post any updates or changes in a timely
        manner to a publicly accessible forum such as the Royal Caribbean
        International website or mobile phone application.
      </p>
      <p>
        <i>
          * All refund requests must be made within six (6) months after the
          scheduled embarkation date.
        </i>
      </p>

      <p className="u-margin-bottom-32">Effective Date: October 1, 2022</p>
    </div>
  </>
)

export default CovidRoyalPolicy

import React, { useState } from 'react'
import formatPrice from '../../utilities/formatPrice'

import Modal from '../elements/Modal'
import BPGPriceBreakdownTable, { setIsOffer } from './BPGPriceBreakdownTable'
import moment from 'moment'

import checkMark from '../../assets/icons/checkmark-highlight.svg'
import calculateTotalDiscount from '../../utilities/calculateTotalDiscount'

/*
 * ONLY IFO0489 is considered a successful state - passed as prop
 * IFO0489 Aka Scenario 1 = Congratulations! Your clients are entitled to a Non-Refundable Onboard Credit <<amount>> USD OBC
 * IFO0490 Aka Scenario 2 = Congratulations! Your clients already have the Best Rate.
 * IFO0492 Aka Scenario 3 = Congratulations! Your clients already have the Best Value.
 * IFO0491 Aka Scenario 4 = Congratulations! Your clients already received the Non-Refundable Onboard Credit <<amount>> USD OBC
 * IFO0493 Aka Scenario 5 = Similar to IFO0491 with additional values
 * */

/*
 *  IFO0489 logic for displaying pricing breakdown
 *  code 4 (appliedCredit) + code 6 (newPromotionalOBCAmount) >= code 7 (currentPromotionalOBCAmount)
 *  display pricing breakdown
 * */

const BPGPricingInfo = ({
  pricing,
  isSuccess,
  guests,
  warningCode,
  suppressResultColumn,
}) => {
  const [pricingModalIsOpen, setPricingModalIsOpen] = useState(false)
  const [activePricingKey, setActivePricingKey] = useState(null)

  function formatPricingForModal(key) {
    setActivePricingKey(key)
    setPricingModalIsOpen(true)
  }

  const {
    currentRate, // code 1
    prevailingRate, // code 2
    // discount, // code 3
    creditCode,
    appliedCredit, // code 4
    creditDate,
    currency,
    newOBCAmount, // code 5
    newPromotionalOBCAmount, // code 6
    currentPromotionalOBCAmount, // code 7
    bestPriceWCOBC, // code 8
    // appliedPromoCreditAmount,
    // appliedBpgCreditAmount
  } = pricing

  const totalDiscount = calculateTotalDiscount(pricing)

  // code 6 + code 4
  const promotionalOBCTotal =
    Number(newPromotionalOBCAmount) + Number(appliedCredit)

  // this is for IFO0489 Aka Scenario 1 ONLY. The other scenarios' logic is in renderPriceBreakdown
  let shouldShowPricingBreakdown = false

  // (code 6 + code 4 = not 0 || code 7 is not 0 ) && ( (code 6 + code 4 ) >= code 7)
  if (
    (Number(promotionalOBCTotal) || Number(currentPromotionalOBCAmount)) &&
    Number(promotionalOBCTotal) >= Number(currentPromotionalOBCAmount)
  ) {
    shouldShowPricingBreakdown = true
  }

  function renderPriceBreakdown() {
    // if code 3 + code 4 = code 7 don't show 7
    // unless 8 exist
    if (
      Number(totalDiscount) === Number(currentPromotionalOBCAmount) &&
      !Number(bestPriceWCOBC)
    ) {
      return null
    }
    return (
      <>
        {Boolean(
          Number(currentPromotionalOBCAmount) > 0 || Number(bestPriceWCOBC),
        ) && (
          <div
            className="u-flex u-flex-align-center u-flex-justify-center bpg-success-breakdown u-border-top"
            data-testid="bpg-unsuccessful-pricing-breakdown"
          >
            {Boolean(Number(bestPriceWCOBC)) && (
              <div className="bpg-success-breakdown-item">
                <p className="amount" data-testid="bpg-unsuccessful-offer-OBC">
                  <strong className="u-bold u-brand-blue">
                    {formatPrice(bestPriceWCOBC)}{' '}
                  </strong>
                  <span className="currency">USD</span>
                </p>
                <p className="description u-bold u-remove-margin">
                  Onboard Credit
                </p>
              </div>
            )}
            {Boolean(
              Number(currentPromotionalOBCAmount) > 0 && Number(bestPriceWCOBC),
            ) && <div className="bpg-success-breakdown-plus" />}

            {Boolean(Number(currentPromotionalOBCAmount) > 0) && (
              <div className="bpg-success-breakdown-item">
                <p
                  className="amount u-remove-margin"
                  data-testid="bpg-unsuccessful-OBC"
                >
                  <strong className="u-bold u-brand-blue">
                    {formatPrice(currentPromotionalOBCAmount)}{' '}
                  </strong>
                  <span className="currency">USD</span>
                </p>
                <p className="description u-bold u-remove-margin">
                  Promotional OBC
                </p>
              </div>
            )}
          </div>
        )}
      </>
    )
  }

  function renderErrorMessage() {
    // IFO0491 Aka Scenario 4
    if (warningCode === 'IFO0491') {
      return (
        <>
          <p
            className="bpg-review-rate-success-message"
            style={{ marginBottom: '16px' }}
          >
            Your clients already received the
            <br />
            <strong>Non-Refundable Onboard Credit</strong>
          </p>
          {creditCode && (
            <p className="u-color-black u-bold">
              Option Code:{' '}
              <strong className="u-brand-blue">{creditCode}</strong>
            </p>
          )}
          <p className="bpg-review-rate-amount highlight">
            {formatPrice(
              totalDiscount,
              true,
              <sup className="disclaimer-indicator">†</sup>,
            )}
            <span className="bpg-review-rate-amount-currency">
              USD <strong>OBC</strong>
            </span>
          </p>

          {creditDate && (
            <p>
              Applied:{' '}
              <span className="u-bold u-brand-blue">
                {moment(creditDate).format('MMMM DD, YYYY')}
              </span>
            </p>
          )}
        </>
      )
    }
    // IFO0493 Aka Scenario 5
    if (warningCode === 'IFO0493') {
      return (
        <>
          <p
            className="bpg-review-rate-success-message"
            style={{ marginBottom: '16px' }}
          >
            Your clients already received the
            <br />
            <strong>Non-Refundable Onboard Credit</strong>
          </p>
          <p
            className="bpg-review-rate-amount highlight"
            style={{ marginBottom: creditDate ? 0 : '8px' }}
          >
            $
            {formatPrice(
              totalDiscount,
              true,
              <sup className="disclaimer-indicator">†</sup>,
            )}
            <span className="bpg-review-rate-amount-currency">
              {' '}
              USD <strong>OBC</strong> <br />
            </span>
          </p>
          {creditDate && (
            <p style={{ marginBottom: '8px' }}>
              Applied:{' '}
              <span className="u-bold u-brand-blue">
                {moment(creditDate).format('MMMM DD, YYYY')}
              </span>
            </p>
          )}
          {renderPriceBreakdown()}
        </>
      )
    }
    // IFO0492 Aka Scenario 3
    if (warningCode === 'IFO0492') {
      let offerTotal = 0
      if (currentRate && currentRate.items) {
        for (const guest of currentRate.items) {
          for (const item of guest) {
            if (setIsOffer(item)) {
              offerTotal += Number(item.amount)
            }
          }
        }
      }
      return (
        <div style={{ paddingTop: '32px' }}>
          <p>Your clients already have the </p>
          <p className="u-bold u-color-black u-margin-bottom-8">Best Value!</p>
          {Number(offerTotal) ? (
            <div className="bpg-success-breakdown u-border-top">
              <div className="bpg-success-breakdown-item ">
                <p className="amount u-remove-margin">
                  <strong className="u-bold u-brand-blue">
                    ${formatPrice(offerTotal)}{' '}
                  </strong>
                  <span className="currency">USD</span>
                </p>
                <p className="description u-bold u-remove-margin">
                  Offer Onboard Credit
                </p>
              </div>
            </div>
          ) : null}
          {creditDate && (
            <p style={{ marginBottom: '8px' }}>
              Applied:{' '}
              <span className="u-bold u-brand-blue">
                {moment(creditDate).format('MMMM DD, YYYY')}
              </span>
            </p>
          )}
          {renderPriceBreakdown()}
        </div>
      )
    }

    // IFO0490 Aka Scenario 2
    return (
      <div style={{ paddingTop: '32px' }}>
        <p>Your clients already have the </p>
        <p className="u-bold u-color-black">Best Rate!</p>
        {creditDate && (
          <p style={{ marginBottom: '8px' }}>
            Applied:{' '}
            <span className="u-bold u-brand-blue">
              {moment(creditDate).format('MMMM DD, YYYY')}
            </span>
          </p>
        )}
        {renderPriceBreakdown()}
      </div>
    )
  }

  return (
    <>
      <div className="bpg-review-rates">
        <div className="bpg-review-rate u-flex u-flex-column u-flex-justify-center">
          <p className="bpg-review-rate-title">Current Rate</p>
          {currentRate && currentRate.total ? (
            <div>
              <>
                <p className="bpg-review-rate-amount">
                  {formatPrice(
                    currentRate.total,
                    true,
                    <sup className="disclaimer-indicator">*</sup>,
                  )}

                  <span className="bpg-review-rate-amount-currency">
                    {currency ? currency : 'USD'}
                  </span>
                </p>
                <button
                  className="btn btn-link"
                  onClick={() => formatPricingForModal('currentRate')}
                >
                  View Details
                </button>
              </>
            </div>
          ) : null}
        </div>

        <div className="bpg-review-rate u-flex u-flex-column u-flex-justify-center">
          {!suppressResultColumn && (
            <div className="rate-separator">
              <div className="minus symbol" />
            </div>
          )}

          <p className="bpg-review-rate-title">Prevailing Rate</p>
          <div>
            {prevailingRate && prevailingRate.total ? (
              <>
                <p className="bpg-review-rate-amount">
                  {formatPrice(
                    prevailingRate.total,
                    true,
                    <sup className="disclaimer-indicator">**</sup>,
                  )}

                  <span className="bpg-review-rate-amount-currency">
                    {currency ? currency : 'USD'}
                  </span>
                </p>
                <button
                  className="btn btn-link"
                  onClick={() => formatPricingForModal('prevailingRate')}
                >
                  View Details
                </button>
              </>
            ) : null}
          </div>
        </div>
        {suppressResultColumn ? null : (
          <div
            className={`bpg-review-rate ${
              isSuccess ? 'success' : 'unavailable'
            }`}
          >
            <div className="rate-separator totals">
              <div className="equals symbol" />
            </div>
            {isSuccess ? (
              <>
                <div className="u-text-center">
                  <img src={checkMark} alt="success" />
                </div>
                <p className="bpg-review-rate-success">Congratulations!</p>
                <p className="bpg-review-rate-success-message">
                  Your clients are entitled to a <br />
                  <strong>Non-Refundable Onboard Credit</strong>
                </p>
                <p
                  className="bpg-review-rate-amount highlight"
                  data-testid="bpg-review-rate-amount-success"
                >
                  $
                  {formatPrice(
                    totalDiscount,
                    true,
                    <sup className="disclaimer-indicator">†</sup>,
                  )}
                  <span className="bpg-review-rate-amount-currency">
                    {' '}
                    USD <strong>OBC</strong> <br />
                  </span>
                </p>
                {shouldShowPricingBreakdown ? (
                  <div
                    className="u-flex u-flex-align-center u-flex-justify-center bpg-success-breakdown u-border-top"
                    data-testid="bpg-success-pricing-breakdown"
                  >
                    {Number(newOBCAmount) ? (
                      <div className="bpg-success-breakdown-item">
                        <p
                          className="amount u-remove-margin"
                          data-testid="bpg-review-rate-obc-amount-success"
                        >
                          <strong className="u-bold u-brand-blue">
                            {formatPrice(newOBCAmount)}{' '}
                          </strong>
                          <span className="currency">USD</span>
                        </p>
                        <p className="description u-bold u-remove-margin">
                          Onboard Credit
                        </p>
                      </div>
                    ) : null}
                    {Number(newOBCAmount) && Number(promotionalOBCTotal) ? (
                      <div className="bpg-success-breakdown-plus" />
                    ) : null}
                    {Number(promotionalOBCTotal) ? (
                      <div className="bpg-success-breakdown-item">
                        <p
                          className="amount"
                          data-testid="bpg-review-rate-promotional-obc-amount-success"
                        >
                          <strong className="u-bold u-brand-blue">
                            {formatPrice(promotionalOBCTotal)}{' '}
                          </strong>
                          <span className="currency">USD</span>
                        </p>
                        <p className="description u-bold u-remove-margin">
                          Promotional OBC
                        </p>
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </>
            ) : (
              <>
                <p className="bpg-review-rate-success">Congratulations!</p>
                {renderErrorMessage()}
              </>
            )}
          </div>
        )}
      </div>
      {pricingModalIsOpen && (
        <Modal
          onRequestClose={() => setPricingModalIsOpen(false)}
          isOpen={pricingModalIsOpen}
          className="bpg-pricing-modal"
        >
          <>
            <div className="u-flex u-flex-space-between bpg-pricing-modal-header">
              <h3 className="u-remove-margin">
                Price Information ({currency ? currency : 'USD'})
              </h3>
              <button
                className="btn btn-hollow u-brand-blue"
                onClick={() => setPricingModalIsOpen(false)}
              >
                Close X
              </button>
            </div>
            <BPGPriceBreakdownTable
              pricingArray={
                pricing &&
                pricing[activePricingKey] &&
                pricing[activePricingKey].items
                  ? pricing[activePricingKey].items
                  : []
              }
              bestPriceWCOBC={bestPriceWCOBC}
              isCurrentRate={activePricingKey === 'currentRate'}
              guests={guests}
              total={
                pricing &&
                pricing[activePricingKey] &&
                pricing[activePricingKey].total
                  ? pricing[activePricingKey].total
                  : null
              }
              currency={currency}
            />
          </>
        </Modal>
      )}
    </>
  )
}

export default BPGPricingInfo

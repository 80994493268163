import React from 'react'

const AzaTerms = () => (
  <div>
    <p className="u-margin-bottom-32">
      Azamara’s “Cruise with Confidence” program provides individual guests and
      guests in non-contracted groups who booked a cruise on or before June 30,
      2022, on sailings between June 12, 2020 and December 31, 2022 with the
      option to either: (i) cancel their cruise reservation up to 48 hours prior
      to the vacation start date and elect to receive a Future Cruise Credit
      under the Cruise with Confidence program (a “CWC FCC”) equal to the amount
      of the cruise fare the guest paid to Azamara for their cruise, or (ii) opt
      in for our Cruise with Confidence “best price guarantee” on their current
      sailing up to 48 hours prior to the vacation start date. If the guest
      cancels an eligible booking before the 48-hour cut-off and elects to
      receive a CWC FCC:
    </p>

    <ul style={{ listStyle: 'none' }}>
      <li className="u-margin-bottom-32">
        (a) before the final payment due date of the booking (the“Final Payment
        due date”), if the guest paid a non-refundable deposit (“NRD”) to
        Azamara on the booking, the guest will receive an FCC equal to the
        amount of the NRD paid. Azamara will refund any other payments it
        received toward the guest’s cruise fare.
      </li>
      <li className="u-margin-bottom-32">
        (b) after the Final Payment due date, the guest will receive a CWC FCC
        equal to 100% of the cruise fare paid to Azamara.
      </li>
    </ul>

    <p className="u-margin-bottom-32">
      “Vacation start date” means the date of the earlier of: (i) the scheduled
      embarkation of the cruise; or (ii) any pre-cruise hotel stay or Cruisetour
      package associated with the cruise and booked through Azamara.
    </p>

    <p className="u-margin-bottom-32">
      “Cruise with Confidence” is a voluntary program designed to provide guests
      with different options and additional flexibility when booking a cruise.
      If a guest does not opt-in to receive a CWC FCC, the cancellation policy
      tied to the guest’s reservation at booking will continue to apply.
      However, if a guest opts-in to receive a CWC FCC at a time when the
      booking is subject to cancellation charges that would otherwise have been
      less than 100% of total cruise fare, by opting in to receive a CWC FCC the
      guest agrees to become immediately subject to cancellation charges equal
      to 100% of the cruise fare paid to Azamara. By requesting a CWC FCC, the
      guest has cancelled his or her reservation and waives any right to receive
      any refund of the cruise fare paid, even if the sailing is later canceled
      (note: depending on when the guest cancelled, an exception may apply for
      cancelled cruises originally scheduled to embark after October 31, 2020).
      If a guest elects to receive a CWC FCC, or otherwise cancels his or her
      reservation, cancellation charges incurred by the guest on the reservation
      will not be waived or refunded for any reason, except as otherwise
      provided herein or required by applicable law.
    </p>

    <p className="u-margin-bottom-32">
      The Future Cruise Credit offered under the “Cruise with Confidence”
      program is available only to bookings for named guests for which cruise
      fare deposits have been paid. The CWC FCC is non-transferrable,
      non-refundable and may not be redeemed for cash. The CWC FCC’s value may
      be applied on or before June 30, 2023 only toward the cruise fare due on a
      new booking or to satisfy the remaining cruise fare balance due for an
      existing booking, with Azamara, for a sailing scheduled to depart on or
      before June 30, 2023, or one year from the originally scheduled
      embarkation date of the guest’s cancelled cruise reservation, whichever is
      later. If not used on or before June 30, 2023, the CWC FCC will
      automatically expire and have no value. The CWC FCCs cannot be used to
      obtain a refund. All bookings are subject to availability at the time of
      the request. Restrictions may apply on stateroom category and applicable
      pricing and promotions.  Guest will remain responsible for paying the
      applicable deposit amounts, the difference in the cruise fare on the
      booking if it exceeds the value of the CWC FCC, taxes/fees, port expenses
      and gratuities (if applicable) for the booking.
    </p>
    <p className="u-margin-bottom-32">
      The CWC “best price guarantee” may be applied only if RCCL is currently
      advertising a lower, publicly available, rate for staterooms of the same
      category on the same sailing and, at the time of the request, those
      staterooms are not sold out. If the CWC “best price guarantee” results in
      a decrease in the guest’s cruise fare after the Final Payment due date,
      then the difference will be provided to guests in the form of a
      non-refundable onboard credit for use on the guest’s cruise. If the CWC
      “best price guarantee” results in a decrease in the guest’s cruise fare
      before the Final Payment due date, the difference will be provided in a
      rate adjustment. The CWC “best price guarantee” does not apply, and any
      rate adjustment or onboard credit will be adjusted accordingly or
      forfeited, if the guest: (i) the guest changes the ship or sail date; (ii)
      the guest does not qualify for the promotional fare; (iii) the stateroom
      category is sold out; or (iv) the guest books at a reduced single
      supplement rate and later converts to double occupancy by adding a second
      guest. If the guest booked under a combined cruise with promotional air
      offer, the CWC best price guarantee applies only to other combined cruise
      with promotional air offers made by Azamara.
    </p>
    <p className="u-margin-bottom-32">
      Azamara’s “Cruise with Confidence” program is not available to bookings
      made after June 30, 2022. Program is not available to guests booked on
      chartered sailings or in contracted groups and CWC FCCs may not be used on
      chartered sailings or in contracted groups.
    </p>
    <p className="u-margin-bottom-32">
      Azamara reserves the right to correct any errors, inaccuracies or
      omissions at any time without prior notice or liability. Offer can be
      withdrawn or modified at any time without notice.
    </p>
    <p className="u-margin-bottom-32">©2022 Azamara. Ships’ registry: Malta.</p>
  </div>
)

export default AzaTerms

import React, { useState, useEffect } from 'react'

import '../../styles/scss/components/lift-shift-form.scss'
import { RESET_BOOKING_FLOW } from '../../redux/actions/fetchCancellationType'
import routes from '../../configuration/routes'
import { useDispatch, useSelector } from 'react-redux'

import SubmitContainer from '../../components/reservationForms/SubmitContainer'

import LiftAndShiftBookingDetails from '../../components/liftAndShiftComponents/LiftAndShiftBookingDetails'
import LiftAndShiftPreferences from '../../components/liftAndShiftComponents/LiftAndShiftPrefrences'
import LiftAndShiftCurrentDetails from '../../components/liftAndShiftComponents/LiftAndShiftCurrentDetails'
import ContactContainer from '../../components/contact/ContactContainer'
import { setLiftAndShiftPreferences } from '../../redux/actions/setLiftAndShiftSelections'
import { getAgentDetails } from '../../redux/reducers/ssoData'
import { setEditedAgentEmail } from '../../redux/actions/setEditedAgentDetails'
import dispatchAnalyticsEvent from '../../redux/actions/dispatchAnalyticsEvent'
const LiftAndShiftReservationForm = ({
  handleRouteChange,
  reservationData,
  cancellationData,
  brandInfo,
  shipData,
  isAuthenticated
}) => {
  const dispatch = useDispatch()
  useEffect(() => {
    // this is analytics only, hence the // eslint-disable-line react-hooks/exhaustive-deps
    dispatch(dispatchAnalyticsEvent('cancellationFormStepTwo-liftAndShift'))
    const eventName = isAuthenticated
      ? 'cancellationFormStepTwo-liftAndShift-agent'
      : 'cancellationFormStepTwo-liftAndShift-consumer'
    dispatch(dispatchAnalyticsEvent(eventName))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const cancelRequest = () => {
    dispatch({ type: RESET_BOOKING_FLOW })
    handleRouteChange(routes.cancellationForm)
  }

  const initialLiftAndShiftSelections = useSelector(
    state => state.liftAndShiftSelections
  )

  const [selections, setSelections] = useState(
    initialLiftAndShiftSelections && initialLiftAndShiftSelections.length
      ? initialLiftAndShiftSelections.reduce((obj, selection, i) => {
          obj[i + 1] = {
            ship: selection.shipCode,
            date: selection.startDate,
            package: selection.packageCode
          }

          return obj
        }, {})
      : {}
  )

  const agentDetails = useSelector(state => getAgentDetails(state))
  const editedAgentEmail = useSelector(state =>
    state.editedAgentDetails && state.editedAgentDetails.email
      ? state.editedAgentDetails.email
      : null
  )

  const [agentEmail, setAgentEmail] = useState(
    editedAgentEmail
      ? editedAgentEmail
      : agentDetails && agentDetails.email
      ? agentDetails.email
      : null
  )
  const [agentEmailError, setAgentEmailError] = useState(null)
  const submit = () => {
    const formattedOptions = []

    if (selections) {
      Object.values(selections).forEach(selection => {
        const shipDetails = cancellationData.liftAndShiftOptions[selection.ship]

        const packageDetails =
          shipDetails &&
          shipDetails.dates &&
          shipDetails.dates[selection.date] &&
          shipDetails.dates[selection.date][selection.package]
            ? shipDetails.dates[selection.date][selection.package]
            : null

        if (packageDetails) {
          formattedOptions.push({
            startDate: packageDetails.startDate,
            shipCode: packageDetails.shipCode,
            shipName:
              shipDetails && shipDetails.shipDetails
                ? shipDetails.shipDetails.name
                : '',
            departurePort: packageDetails.departurePort,
            departurePortName: packageDetails.departurePortName,
            packageCode:
              packageDetails.inclusivePackageOptions &&
              packageDetails.inclusivePackageOptions.packageCode,
            itineraryCode:
              packageDetails.inclusivePackageOptions &&
              packageDetails.inclusivePackageOptions.itineraryCode,
            itineraryName: packageDetails.itineraryName
          })
        }
      })
    }

    if (agentDetails) {
      //validate email
      const regex = /\S+@\S+\.\S+/
      // eslint-disable-next-line
      if (!regex.test(`${agentEmail}`)) {
        return setAgentEmailError(true)
      }
      dispatch(setEditedAgentEmail(agentEmail))
    }

    dispatch(setLiftAndShiftPreferences(formattedOptions))

    handleRouteChange(routes.review)
  }

  const formIsValid = Object.values(selections).some(selection =>
    Boolean(
      selection.ship &&
        selection.ship.length &&
        selection.date &&
        selection.date.length &&
        selection.package &&
        selection.package.length
    )
  )

  return (
    <div className="u-background-white-transparent lift-shift-form-container">
      <div className="u-max-medium-container">
        <h4 className="u-bold u-text-center">Choose Your Preferences</h4>
        <div className="u-margin-bottom-32">
          {isAuthenticated ? (
            <p className="u-text-center">
              Select up to three preferred sailings to Lift & Shift your
              clients' current booking and click <strong>CONTINUE</strong> at
              the bottom of the page to review your selections.
            </p>
          ) : (
            <p className="u-text-center">
              Select up to three preferred sailings to Lift & Shift your current
              booking and click <strong>CONTINUE</strong> at the bottom of the
              page to review your selections.
            </p>
          )}
        </div>
      </div>
      <div className="u-background-white u-border">
        <div className="u-flex-md-up">
          <div className="lift-shift-form-column">
            <LiftAndShiftBookingDetails
              reservationData={reservationData}
              bookingData={cancellationData}
              shipData={shipData}
              brandInfo={brandInfo}
            />
            <div className="u-background-white lift-shift-form-section">
              <div className="lift-shift-form-booking-info-section">
                <ContactContainer
                  agentEmail={agentEmail}
                  setAgentEmail={setAgentEmail}
                  agentEmailError={agentEmailError}
                  setAgentEmailError={setAgentEmailError}
                />
              </div>
            </div>
          </div>

          <div className="lift-shift-form-column">
            <div className="u-border-bottom lift-shift-form-heading">
              <h2 className="u-text-center u-bold u-remove-margin">
                Choose Preferred Sailings
              </h2>
            </div>
            <LiftAndShiftCurrentDetails reservationData={reservationData} />
            <div className="lift-shift-form-booking-info-section">
              <div className="lift-shift-form-preferences u-background-light u-border">
                <LiftAndShiftPreferences
                  selections={selections}
                  setSelections={setSelections}
                  disabled={false}
                  bookingData={cancellationData}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <SubmitContainer
        hideTerms={true}
        isChecked={true}
        onCancel={cancelRequest}
        termsText="I accept the"
        buttonText="Continue"
        onSubmit={submit}
        disabled={!formIsValid || (agentDetails && !agentEmail)}
      />
    </div>
  )
}

export default LiftAndShiftReservationForm

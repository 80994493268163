import { SET_SELECTED_PROGRAM } from '../actions/setSelectedProgramData'
import { RESET_BOOKING_FLOW } from '../actions/fetchCancellationType'

export default function(state = null, action) {
  if (action.type === SET_SELECTED_PROGRAM) {
    return action.payload
  }

  if (action.type === RESET_BOOKING_FLOW) {
    return null
  }

  return state
}

import React from 'react'
import bestPrice from '../../assets/icons/best-price.svg'
import cwcIcon from '../../assets/icons/ship.svg'
import covidCancelIcon from '../../assets/icons/covid-cancel-icon.svg'
import fccIcon from '../../assets/icons/fcc-icon.svg'
import purchaseIcon from '../../assets/icons/purchase.svg'

const agentPrograms =
  process.env.REACT_APP_ENABLED_AGENT_PROGRAMS?.split(',') || []
const consumerPrograms =
  process.env.REACT_APP_ENABLED_CONSUMER_PROGRAMS?.split(',') || []

/*
 *   There are a bunch of options here.
 *     title: appears on selections cards and page intros
 *         - in page intros it can be over-written with pageTitle
 *   description,
 *   pageTitle - displays at the top of the ViewWrapper (will fallback to title)
 *   pageSubTitle - displays at the top of the ViewWrapper,
 *   value - required and unique
 *   programCode - required and unique
 *   icon - icon
 *   enabled - can be brand or flow (agent v consumer) related
 *   pageIntroParagraphs - displays at the top of the ViewWrapper
 *   disclaimerCopy - show on the reservation form
 *   subTitle - shows on selection card in two spots
 *      in the tooltip it will be over written by pageSubTitle
 *
 * */

const fccDefaults = {
  title: 'Transfer Future Cruise Credit',
  value: 'fcc',
  programCode: 'fcc',
  icon: fccIcon,
  enabled: consumerPrograms.some(code => code === 'fcc'),
  pageIntroParagraphs: [
    `Do you want to gift your Future Cruise Credit to someone else? Use our quick and easy form to easily transfer* your FCC to a friend or family member. To get started, just enter the FCC number to be transferred, along with the first and last name of the original recipient of the certificate, and click Begin Request.`,
  ],
  disclaimerCopy: `*Once the transfer is completed the original recipient of the certificate relinquishes all rights to the value represented by the FCC.  The recipient of a transferred FCC may not request that it be transferred again. Only one transfer per FCC will be allowed. `,
}

const covidAssistDefaults = {
  title: 'Cancel My Cruise',
  subTitle: 'COVID-19',
  description: `We've got your peace of mind covered.  With our COVID Assistance policy, if you or any of your traveling party test positive for COVID-19 within 10 days prior to your cruise*, or at the boarding terminal, you can cancel your reservation and 100% of your cruise fare will be refunded.  *For cruises departing on, or before, April 30, 2023.`,
  value: 'cvc',
  programCode: 'cc',
  icon: covidCancelIcon,
  enabled: consumerPrograms.some(code => code === 'cvc'),
  pageIntroParagraphs: [
    `We've got your peace of mind covered.  With our COVID Assistance policy, if you or any of your traveling party test positive for COVID-19 within 10 days prior to your cruise*, or at the boarding terminal, you can cancel your reservation and 100% of your cruise fare will be refunded.  To get started , just enter the booking number of the guest who has tested positive and click Begin Request.`,
  ],
}

const cancelDefaults = {
  title: 'Cancel My Cruise',
  subTitle: 'Cruise With Confidence',
  description: `Cancel any cruise (booked by March 31, 2022) that sets sail from now through September 30, 2022, and you’re covered. If you cancel at least 48 hours before the sail date by choosing the Cruise with Confidence Future Cruise Credit option, you will receive a Future Cruise Credit for 100% of the cruise fare paid to us that can be used for a sailing scheduled to depart on or before one year from the originally scheduled embarkation date of the guest’s cancelled cruise reservation whichever is later. The CWC FCCs otherwise have no cash value and cannot be used to obtain a refund.`,
  value: 'cwc',
  programCode: 'VB',
  icon: cwcIcon,
  enabled: consumerPrograms.some(code => code === 'cwc'),
}

const shouldDisableCovidCancellationProgram = countryCode =>
  ['AUS', 'NZL', 'FJI', 'FSM', 'NCL', 'VUT'].some(
    code => code === countryCode?.toUpperCase(),
  )

const shouldEnableObcPurchase = countryCode =>
  ['USA'].some(code => code === countryCode)

function renderAuthenticatedOptions({ countryCode }) {
  return [
    {
      ...fccDefaults,
      description: `Do your clients want to gift their Future Cruise Credit to someone else? Original recipients of a FCC may transfer their FCC to a friend or family member.  Our FCC Transfer Request form makes it quick and easy. Once submitted, the original recipient of the FCC relinquishes all rights to the value represented by the FCC.  The recipient of a transferred FCC may not request that it be transferred again. Only one transfer per FCC will be allowed.`,
      enabled: agentPrograms.some(code => code === 'fcc'),
      pageIntroParagraphs: [
        `Does your client want to gift their Future Cruise Credit to someone else? Use our quick and easy form to easily transfer* your client's FCC to their friend or family member.   To get started, just enter the certificate number of the FCC to be transferred, along with the first and last name of your client as reflected on the original FCC and click Begin Request.`,
      ],
      disclaimerCopy: `*Upon submitting this request on behalf of your client, your client will have relinquished all of his/her rights to the value represented by the FCC.  The recipient of a transferred FCC may not request that it be transferred again. Only one transfer per FCC will be allowed.`,
    },
    {
      title: 'Best Price Guarantee',
      pageTitle: 'Best Price Guarantee',
      description: `Wondering if your clients have the best rate or promotion for their upcoming cruise? Use our Best Price Guarantee tool to quickly check whether a better rate or promotion is available. To determine whether a better rate or promotion applies, bookings must be made on, or after, April 1, 2022 and must be checked through the Best Price Guarantee tool within 48-hours of the time the booking was created.`,
      value: 'bpg',
      programCode: 'BR',
      icon: bestPrice,
      enabled: agentPrograms.some(code => code === 'bpg'),
      pageIntroParagraphs: [
        <>
          Wondering if your clients have the best rate or promotion for their
          upcoming cruise? Use our Best Price Guarantee tool to quickly check
          whether a better rate or promotion is available. To determine whether
          a better rate or promotion applies, bookings must be made on, or
          after, April 1, 2022 and must be checked through the Best Price
          Guarantee tool within 48-hours of the time the booking was created.
        </>,
      ],
    },
    {
      ...covidAssistDefaults,
      pageTitle: 'Cancel My Cruise - COVID-19',
      pageSubTitle: '(Pre-Embarkation Day)',
      description: `For Royal Caribbean International and Celebrity Cruises sailings departing on or before April 30, 2023, we’ve got your client’s peace of mind covered.\n Under our COVID-19 Cancellation and Refund Policies, if your client tests positive for COVID-19 within 10 days of the scheduled day of embarkation, you can use this tool, prior to the scheduled day of embarkation, to cancel your client’s booking.  Assuming you upload the necessary proof of a verified positive COVID-19 test result in a form acceptable to the cruise line and submit the request, your client will receive a full refund of the cruise fare paid.\nProvided the sailing is scheduled to depart on or before the above date, this eligibility for a refund extends to the COVID-19 positive guest, and all other guests in the “Traveling Party” of the COVID-19 positive guest.   This means the guest’s family members living in the same household and traveling companions assigned to his/her stateroom on the cruise.`,
      enabled:
        agentPrograms.some(code => code === 'cvc') &&
        !shouldDisableCovidCancellationProgram(countryCode),
      pageIntroParagraphs: [
        <>
          For Royal Caribbean International and Celebrity Cruises sailings
          departing on or before April 30, 2023, if your client tests positive
          for COVID-19 within 10 days of the scheduled day of embarkation, you
          can use this tool to cancel your client’s booking. Assuming you upload
          the necessary positive COVID-19 test result and submit the request
          prior to the scheduled embarkation date, your client will receive a
          full refund of the cruise fare paid.<sup>1</sup>
        </>,
        <>
          This eligibility for a refund extends to all guests in the “Traveling
          Party” of the COVID-19 positive guest. This means the positive guest
          and his/her family members living in the same household, and traveling
          companions assigned to his/her stateroom on the cruise.
        </>,
        <>
          To get started, enter the booking number of the guest who tested
          positive and click Begin Request.
        </>,
      ],
      disclaimerCopy: (
        <>
          <sup>1</sup>Excludes residents of Australia, New Zealand and certain
          South Pacific islands; however, those guests may be eligible for a
          future cruise credit instead. Please refer to the relevant cruise
          line’s COVID-19 Refund and Cancellation Policy for International
          markets.
        </>
      ),
    },
    {
      title: 'Purchase and Manage Onboard Credits',
      pageTitle: 'Purchase and Manage Onboard Credits',
      description: `Purchasing onboard credits is now easy and convenient!  These credits can be redeemed to purchase pre-cruise products such as shore excursions, beverage packages, specialty dining, spa & fitness, and so much more. To proceed, the client’s reservation must be at least eight (8) days prior to the sail date and must be on booked (BK) status. Paid onboard credits purchased through this tool reflect the value of credit in USD and may be refundable if unused.`,
      value: 'obc',
      programCode: '',
      icon: purchaseIcon,
      enabled:
        agentPrograms.some(code => code === 'obc') &&
        shouldEnableObcPurchase(countryCode),
      centerIntro: true,
      pageIntroParagraphs: [
        <>
          Are your clients, or their family and friends, looking to gift a
          credit to guests on an upcoming sailing? With our new tool, purchasing
          onboard credits is easy and convenient! These credits can be redeemed
          to purchase pre-cruise products such as shore excursions, beverage
          packages, specialty dining, spa & fitness, and so much more. To get
          started, the client’s reservation must be at least eight (8) days
          prior to the sail date and must be on booked (BK) status. Paid onboard
          credits purchased through this tool reflect the value of credit in USD
          and may be refundable.
        </>,
      ],
      disclaimerCopy: (
        <>
          Paid onboard credits purchased through this tool reflect the value of
          credit in USD. If the onboard credit is redeemed for a booking with a
          currency other than USD, the onboard credit amount will be shown in
          the currency of the booking, based on the conversion rate used at the
          time of purchase. If the onboard credit is partially used prior to the
          sailing, any remaining balance will be transferred to the guest’s
          onboard account for use during their cruise. Onboard credits cannot be
          cancelled if redeemed or partially redeemed.
        </>
      ),
    },
    // RETIRED - temp staying for Azamara
    // {
    //   ...cancelDefaults,
    //   description: `Cancel any cruise (booked by March 31, 2022) that sets sail from now through September 30, 2022 and your clients are covered. If you cancel at least 48 hours before the sail date, by choosing the Cruise with Confidence Future Cruise Credit ("CwC FCC") option, your client will receive a Future Cruise Credit for 100% of the cruise fare paid to us that can be used for a sailing scheduled to depart on, or before, one year from the originally scheduled embarkation date of the guest's cancelled cruise reservation whichever is later. The CWC FCCs otherwise have no cash value and cannot be used to obtain a refund.`,
    //   enabled: agentPrograms.some(code => code === 'cwc'),
    // },
  ]
}

// ROYAL
function renderDefaultOptions() {
  return [
    {
      ...fccDefaults,
    },
    { ...covidAssistDefaults },
    {
      ...cancelDefaults,
    },
  ]
}

function renderCelebrityOptions() {
  return [
    { ...fccDefaults },
    { ...covidAssistDefaults },
    {
      ...cancelDefaults,
      description: `Cancel any cruise (booked by March 31, 2022) that sets sail from now through September 30, 2022 and you’re covered. If you cancel at least 48 hours before the sail date by choosing the Cruise with Confidence Future Cruise Credit option, you will receive a Future Cruise Credit for 100% of the cruise fare paid to us that can be used for a sailing scheduled to depart on or before one year from the originally scheduled embarkation date of the guest’s cancelled cruise reservation whichever is later. The CWC FCCs otherwise have no cash value and cannot be used to obtain a refund.`,
    },
  ]
}

function renderAzamaraOptions() {
  return [
    { ...fccDefaults },
    { ...covidAssistDefaults },
    {
      ...cancelDefaults,
      description: `Cancel any cruise (booked by June 30, 2022) that sets sail from now through December 31, 2022 and you’re covered.  If you cancel at least 48 hours before the sail date by choosing the Cruise with Confidence Future Cruise Credit option, you will receive a Future Cruise Credit for 100% of the cruise fare paid to us that can be used on any Azamara cruise scheduled to depart on or before December 31, 2022, or one year from the originally scheduled embarkation date of the guest’s cancelled cruise reservation, whichever is later.`,
    },
  ]
}

export const renderOptionsByBrandCode = brandCode => {
  if (brandCode.toUpperCase() === 'R') {
    return renderDefaultOptions()
  }
  if (brandCode.toUpperCase() === 'C') {
    return renderCelebrityOptions()
  }
  if (brandCode.toUpperCase() === 'Z') {
    return renderAzamaraOptions()
  }
  return null
}

export default {
  renderAuthenticatedOptions,
  renderDefaultOptions,
  renderCelebrityOptions,
  renderAzamaraOptions,
}

/* Retired programs
* {
     title: 'Request a Refund',
     testId: 'agent-request-refund-card',
     description: `If your clients are booked on a sailing we cancelled due to global suspension of cruising, they will automatically receive a 125% Future Cruise Credit. If your client wishes to decline this higher value Cruise Credit and receive a lower value 100% refund of their cruise fare, please select this option.`,
     value: 'cs',
     programCode: 'GQ',
     icon: cwcIcon,
   },
   {
     title: 'Lift & Shift',
     description: `If your clients simply wish to move their existing booking to an alternate sail date in the following year, we're happy to protect the original cruise fare and/or promotion if the alternate sailing is the same itinerary, length, and stateroom category as their existing booking. The alternate sailing date must be within 4 weeks (before or after) of the original sail date, but for the following year.`,
     value: 'las',
     programCode: 'LI',
     icon: lift,
   },
* */

import { getAgencyId, getAgentUserId } from '../reducers/ssoData'
import { getCovidCancellation } from '../../api/api'

import {
  FETCH_CANCELLATION_TYPE,
  RECEIVE_CANCELLATION_TYPE,
} from './fetchCancellationType'

export const fetchCovidCancellation = values => async (dispatch, getState) => {
  const { bookingID, lastName, sailDate, ship } = values

  const state = getState()
  const agencyId = getAgencyId(state)
  const userId = getAgentUserId(state)
  const sessionId = state.sessionId

  const params = new URLSearchParams()

  if (agencyId) {
    params.append('agencyId', agencyId)
  }

  if (userId) {
    params.append('userId', userId)
  }

  if (sessionId) {
    params.append('sessionId', sessionId)
  }

  if (lastName) {
    params.append('lastName', lastName)
  }
  if (sailDate) {
    params.append('sailDate', sailDate)
  }

  if (ship) {
    params.append('ship', ship)
  }

  const url = `${bookingID}?${params.toString()}`

  dispatch({
    type: FETCH_CANCELLATION_TYPE,
  })

  try {
    const { data } = await getCovidCancellation(url)
    if (!data) {
      console.warn('No response')
      return null
    }

    dispatch({
      type: RECEIVE_CANCELLATION_TYPE,
      payload: data,
    })
    return data
  } catch (error) {
    throw new Error(
      error?.response?.data?.['hydra:description'] ||
        error?.message ||
        'Request error',
    )
  }
}

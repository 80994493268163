import React from 'react'
import * as Yup from 'yup'

const BOOKING_ID_ERROR =
  'The reservation number entered is not valid. Please enter a valid Individual or Group reservation number and try again.'

const generateFormData = ({ selectedProgram, isAuthenticated, options }) => {
  if (selectedProgram === 'fcc') {
    return {
      initialValues: {
        fccNumber: '',
        firstName: '',
        lastName: '',
      },
      schema: Yup.object().shape({
        fccNumber: Yup.string().required('Please enter Certificate Number'),
        firstName: Yup.string()
          .matches()
          .required('Please enter First Name'),
        lastName: Yup.string().required('Please enter Last Name'),
      }),
      fields: [
        {
          name: 'fccNumber',
          label: 'Certificate Number',
          className: 'third',
        },
        {
          name: 'firstName',
          label: 'First Name',
          className: 'third',
        },
        {
          name: 'lastName',
          label: 'Last Name',
          className: 'third',
        },
      ],
    }
  }

  if (!isAuthenticated) {
    return {
      initialValues: {
        bookingID: '',
        lastName: '',
        sailDate: null,
        ship: '',
      },
      schema: Yup.object().shape({
        bookingID: Yup.string()
          .required('')
          .matches(/^[0-9]+$/, BOOKING_ID_ERROR)
          .max(7, BOOKING_ID_ERROR),
        lastName: Yup.string()
          .matches(
            /^([^0-9]*)$/,
            'The guest last name entered is not valid. Please verify and try again.',
          )
          .required('Please enter a last name'),
        sailDate: Yup.string().required('Please enter a sail date'),
        ship: Yup.string().required('Please select a ship'),
      }),
      fields: [
        {
          name: 'bookingID',
          label: (
            <>
              Booking ID <span className="normal">(Individual or Group)</span>
            </>
          ),
          className: 'half',
        },
        {
          name: 'ship',
          label: 'Ship',
          type: 'select',
          options: options?.ships?.length
            ? options.ships.map(ship => ({
                value: ship.ship_code,
                label: ship.name,
              }))
            : [],
          className: 'half',
        },
        {
          name: 'sailDate',
          label: 'Sail Date (MM/DD/YYYY)',
          type: 'date',
          className: 'half',
        },
        { name: 'lastName', label: 'Guest Last Name', className: 'half' },
      ],
      shouldDisableSubmit: values => !Object.values(values).every(val => !!val),
    }
  }

  if (selectedProgram === 'cwc' || selectedProgram === 'cs') {
    return {
      initialValues: {
        bookingID: '',
        shellID: '',
      },
      schema: Yup.object().shape(
        {
          bookingID: Yup.string().when('shellID', {
            is: val => !val?.length,
            then: Yup.string()
              .required('Please enter a shell or booking id')
              .matches(/^[0-9]+$/, BOOKING_ID_ERROR)
              .max(7, BOOKING_ID_ERROR),
            otherwise: Yup.string(),
          }),
          shellID: Yup.string().when('bookingID', {
            is: val => !val?.length,
            then: Yup.string()
              .required('Please enter a shell or booking id')
              .matches(/^[0-9]+$/, BOOKING_ID_ERROR)
              .max(7, BOOKING_ID_ERROR),
          }),
        },
        ['bookingID', 'shellID'],
      ),
      fields: [
        {
          name: 'bookingID',
          label: (
            <>
              Booking ID <span className="normal">(Individual or Group)</span>
            </>
          ),
          renderDisabled: values => !!values['shellID']?.length,
          className: 'half',
        },
        {
          type: 'displayElement',
          element: (
            <p className="separator-text u-text-center u-brand-light">- or -</p>
          ),
        },
        {
          name: 'shellID',
          label: 'Group Shell Booking ID',
          renderDisabled: values => !!values['bookingID']?.length,
          className: 'half',
        },
      ],
      shouldDisableSubmit: values => !Object.values(values).some(val => !!val),
    }
  }
  return {
    initialValues: {
      bookingID: '',
    },
    schema: Yup.object().shape({
      bookingID: Yup.string()
        .required(
          'A reservation number is required. Please enter a valid Individual or Group reservation number and try again.',
        )
        .matches(/^[0-9]+$/, BOOKING_ID_ERROR)
        .max(7, BOOKING_ID_ERROR),
    }),
    fields: [
      {
        name: 'bookingID',
        label: (
          <>
            Booking ID <span className="normal">(Individual or Group)</span>
          </>
        ),
      },
    ],
    shouldDisableSubmit: values => !Object.values(values).some(val => !!val),
  }
}

export default generateFormData

import React from 'react'
import '../../styles/scss/components/cwc-review-reservation.scss'
import CovidCancellationForm from '../covidCancellationForm/CovidCancellationForm'

const CovidCancellationReview = ({
  reservationData,
  onSubmitSuccess,
  resetFlow,
}) => {
  return (
    <div
      className="review-reservation u-background-white-transparent"
      data-testid="cwc-cs-review-container"
    >
      <CovidCancellationForm
        resetFlow={resetFlow}
        reservationData={reservationData}
        onSubmitSuccess={onSubmitSuccess}
      />
    </div>
  )
}

export default CovidCancellationReview

import React from 'react'
import FileUploader from '../FileUploader'
import deleteIcon from '../../assets/icons/delete-close.svg'

import '../../styles/scss/components/covid-cancellation-documents.scss'

const PassengerDocuments = ({
  abilityToBrowseFile,
  guestDetails,
  handleAddUpdateDocuments,
  currentFiles,
  renderError,
  handleRemoveImage,
}) => (
  <div>
    <div className="image-upload">
      <div className="u-flex u-flex-space-around u-margin-left-8">
        <FileUploader
          name={`${guestDetails?.id}`}
          onChange={handleAddUpdateDocuments}
          files={currentFiles}
          abilityToBrowseFile={abilityToBrowseFile}
        />

        {currentFiles?.length > 0 && (
          <div className="u-flex ">
            {currentFiles.map(file => (
              <div key={file.preview} className="u-flex image-box">
                <div>
                  {file.type === 'application/pdf' ? (
                    <div className="pdf-wrapper">
                      <object data={file.preview} width="100%">
                        <p>Your browser does not support pdf preview.</p>
                      </object>
                    </div>
                  ) : (
                    <div>
                      <img
                        className="file-image"
                        style={{
                          width: '70px',
                          height: 'auto',
                          objectFit: 'contain',
                        }}
                        src={file.preview}
                        alt={file.name}
                        key={file.preview}
                      />
                    </div>
                  )}
                </div>
                <div className="link-box">
                  <a
                    className="file-link"
                    href={file.preview}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {file.name}
                  </a>
                  <button
                    className="delete-button"
                    onClick={() => handleRemoveImage(file?.preview)}
                  >
                    Remove{' '}
                    <img className="d-icon" src={deleteIcon} alt="Remove" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
    {renderError && <div className="u-text-center">{renderError()}</div>}
  </div>
)

export default PassengerDocuments

import React from 'react'
import { useSelector } from 'react-redux'
import { getAgentDetails } from '../../redux/reducers/ssoData'
import AgencyDetails from './AgencyDetails'
import ContactDetails from './ContactDetails'
import '../../styles/scss/components/contact-details.scss'
import {
  getCancellationTypeData,
  getFirstGuest
} from '../../redux/reducers/cancellationTypeData'
import { getIsSpanish } from '../../redux/reducers/activeSearchFilters'

const ContactContainer = ({
  agentEmail,
  setAgentEmail,
  agentEmailError,
  setAgentEmailError,
  isFlex,
  showAsItems
}) => {
  const isSpanish = useSelector(state => getIsSpanish(state))
  const agentDetails = useSelector(state => getAgentDetails(state))
  const cancellationData = useSelector(state => getCancellationTypeData(state))

  const isDirectBooking =
    cancellationData && cancellationData.directBooking === 'Y'
  const firstGuest = useSelector(state => getFirstGuest(state))

  const guestEmail =
    firstGuest && firstGuest.email && firstGuest.email.length
      ? firstGuest.email
      : null

  const guestPhone = firstGuest && firstGuest.phone ? firstGuest.phone : null

  return (
    <div className="contact-details-container">
      {agentDetails ? (
        <AgencyDetails
          agentDetails={agentDetails}
          cancellationData={cancellationData}
          agentEmail={agentEmail}
          setAgentEmail={setAgentEmail}
          agentEmailError={agentEmailError}
          setAgentEmailError={setAgentEmailError}
          isDirectBooking={isDirectBooking}
          guestPhone={guestPhone}
          guestEmail={guestEmail}
          isFlex={isFlex}
          showAsItems={showAsItems}
          isSpanish={isSpanish}
        />
      ) : (
        <ContactDetails
          guestPhone={guestPhone}
          guestEmail={guestEmail}
          cancellationData={cancellationData}
          isDirectBooking={isDirectBooking}
          isFlex={isFlex}
          isSpanish={isSpanish}
        />
      )}
    </div>
  )
}

export default ContactContainer

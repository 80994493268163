import {
  FETCH_CONFIG,
  RECEIVE_CONFIG,
  CONFIG_ERROR
} from '../actions/fetchConfiguration'

const initialState = {
  loading: false,
  error: null,
  data: null
}

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_CONFIG:
      return Object.assign({}, state, {
        loading: true
      })
    case RECEIVE_CONFIG:
      return Object.assign({}, state, {
        loading: false,
        data: action.payload
      })
    case CONFIG_ERROR:
      return Object.assign({}, state, {
        loading: false,
        error: action.payload
      })
    default:
      return state
  }
}

export const getConfigData = state => state.config

import formatImages from './formatImages'

export default function(brands) {
  return brands
    .sort((a, b) => (a.name < b.name ? -1 : 1))
    .map(brand => {
      return {
        ...brand,
        image: formatImages(brand.image, ['details-hero']),
        badge_dark: formatImages(
          [brand['badge_dark']],
          ['thumbnail', 'medium']
        ),
        badge_light: formatImages(
          [brand['badge_light']],
          ['thumbnail', 'medium']
        ),
        email_image: formatImages([brand['email_image']])
      }
    })
}

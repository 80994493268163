import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { getSelectedProgramData } from '../../redux/actions/setSelectedProgramData'

import Stepper from '../../components/structure/Stepper'
import '../../styles/scss/views/view-wrapper.scss'
import CancelFooter from '../../components/structure/CancelFooter'

const DEFAULT_TITLE = 'Partner Express'

const ViewWrapper = ({
  children,
  handleRouteChange,
  hidden,
  showIntroCopy,
  baseClassName = '',
  darkArrows = false,
  renderHeader = null,
  paragraphOverrides = null,
}) => {
  const selectedProgram = useSelector(getSelectedProgramData)

  const paragraphs =
    paragraphOverrides || selectedProgram?.pageIntroParagraphs || []

  return (
    <div
      className={`u-background-blue-transparent view ${
        hidden ? 'hide' : ''
      } ${baseClassName || ''}`}
    >
      <div className="u-max-medium-container view-content-container">
        <div className="view-header u-no-print">
          {renderHeader ? (
            renderHeader()
          ) : (
            <div className="view-copy">
              <h3 className="view-title u-text-center ">
                {selectedProgram?.pageTitle ||
                  selectedProgram?.title ||
                  DEFAULT_TITLE}
              </h3>
              {selectedProgram?.pageSubTitle && (
                <h4 className="view-sub-title u-text-center ">
                  {selectedProgram?.pageSubTitle}
                </h4>
              )}
              {showIntroCopy &&
                paragraphs?.length > 0 &&
                paragraphs.map((paragraph, i) => (
                  <p
                    key={i}
                    className={`view-intro ${
                      selectedProgram?.centerIntro ? 'centered' : ''
                    }`}
                  >
                    {paragraph}
                  </p>
                ))}
            </div>
          )}

          <Stepper
            handleRouteChange={handleRouteChange}
            baseClassName={baseClassName}
            darkArrows={darkArrows}
          />
        </div>
        <div className="view-content">{children}</div>
        <div className="view-footer">
          <CancelFooter showProgramTerms />
        </div>
      </div>
    </div>
  )
}

ViewWrapper.propTypes = {
  handleRouteChange: PropTypes.func.isRequired,
}

export default ViewWrapper

import { RECEIVE_ITINERARY, FETCH_ITINERARY } from '../actions/itineraries'

const initialState = {
  isLoading: false,
  items: {}
}

export default function itineraries(state = initialState, action) {
  if (action.type === FETCH_ITINERARY) {
    return {
      ...state,
      isLoading: true
    }
  }

  if (action.type === RECEIVE_ITINERARY) {
    return {
      ...state,
      isLoading: false,
      items: {
        ...state.items,
        [action.payload.key]: action.payload.itinerary
      }
    }
  }

  return state
}

export const getItinerary = (state, id, sailDate) => {
  const key = `${id}-${sailDate}`
  return state.itineraries.items[key]
}

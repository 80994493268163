import React from 'react'

import '../../styles/scss/components/fcc-terms.scss'
import '../../styles/scss/components/shared-terms-modal-styles.scss'

import FFTermsCWCVersion from '../../configuration/FCCTermsCWCVersion'
import FccTermsGSVersion from '../../configuration/FccTermsGSVersion'

/*
 * issueReason: "Cruise With Confidence"
 * issueReason: "Global Suspension"
 * */

const FccTermsAndConditions = ({ dismissSubmit, submit, issueReason }) => {
  const isCWC =
    issueReason && issueReason.toLowerCase() === 'cruise with confidence'

  return (
    <div className="fcc-terms terms-modal" data-testid="fcc-terms">
      <div className="fcc-terms-header">
        <h1 className="title">
          {isCWC ? (
            <>
              Request for Transfer of
              <br />
              Future Cruise Credit (“FCC”)
              <br />
              Cruise with Confidence Terms and Conditions
            </>
          ) : (
            <>
              Request for Transfer of <br />
              Future Cruise Credit (“FCC”)
              <br />
              Global Suspension Terms and Conditions
            </>
          )}
        </h1>
      </div>
      <div className="fcc-terms-body">
        {isCWC ? <FFTermsCWCVersion /> : <FccTermsGSVersion />}
      </div>
      <div className="fcc-terms-footer">
        <div className="u-flex u-flex-align-center u-margin-bottom-16">
          <button
            onClick={submit}
            className="btn btn-primary btn-oval with-arrow u-uppercase"
          >
            I Agree
          </button>
          <p className="u-remove-margin">
            Continue to submit the FCC transfer request
          </p>
        </div>
        <div className="u-flex u-flex-align-center">
          <button
            onClick={dismissSubmit}
            className="btn btn-oval btn-white with-arrow u-uppercase"
          >
            Cancel
          </button>
          <p className="u-remove-margin">
            I've changed my mind and would like to cancel the FCC transfer
            request.
          </p>
        </div>
      </div>
    </div>
  )
}

export default FccTermsAndConditions

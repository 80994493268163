import React, { useState } from 'react'
import '../../styles/scss/components/cwc-review-reservation.scss'
import BookingDetails from './BookingDetails'
import GuestDetails from './GuestDetails'

import ContactContainer from '../contact/ContactContainer'
import checkMark from '../../assets/icons/checkmark.png'
import TermsAndConditionsTriggerAndModal from '../modals/TermsAndConditionsTriggerAndModal'

const CWCReview = ({
  agentDetails,
  agentEmail,
  agentEmailError,
  cancelRequest,
  cancellationData,
  isAuthenticated,
  reservationData,
  setAgentEmail,
  setAgentEmailError,
  submit
}) => {
  const guests =
    reservationData && reservationData.guests && reservationData.guests.length
      ? reservationData.guests
      : null

  const [termsChecked, setTermsChecked] = useState(false)

  const requiredCheckBox = cancellationData && cancellationData.code === 'VB'

  return (
    <div
      className="review-reservation u-white-box"
      data-testid="cwc-cs-review-container"
    >
      <>
        <div className="review-reservation-details">
          <div className="review-reservation-details-header u-max-medium-container">
            <h1 className="u-text-center u-title-x-large">
              Review Your Booking Information
            </h1>
            <p>
              Before you submit your request, please make sure you review all of
              the information below and verify that everything is correct. Once
              you’ve completed your review, click the SUBMIT button at the
              bottom of the page.
            </p>
          </div>

          <div className="u-max-container review-reservation-sections">
            <div className="u-flex-md-up u-flex-wrap">
              <div
                className={`u-flex-half review-reservation-section ${
                  guests ? 'border-right' : 'max-width'
                }`}
              >
                <BookingDetails
                  reservationData={reservationData}
                  isAuthenticated={isAuthenticated}
                  cancellationData={cancellationData}
                />
              </div>
              {guests ? (
                <div className="u-flex-half">
                  <GuestDetails guests={guests} />
                </div>
              ) : null}
            </div>
            <div className="review-reservation-details-detail-section u-border-top">
              <ContactContainer
                agentEmail={agentEmail}
                setAgentEmail={setAgentEmail}
                agentEmailError={agentEmailError}
                setAgentEmailError={setAgentEmailError}
                isFlex
              />
            </div>
          </div>
        </div>
        <div className="u-max-medium-container u-text-center button-container">
          <>
            {requiredCheckBox ? (
              <div className="u-custom-terms-checkbox-container">
                <label
                  className="u-custom-terms-checkbox-label "
                  style={{
                    marginBottom: '16px'
                  }}
                >
                  <div className="u-custom-terms-checkbox-input-container">
                    <div className="u-custom-terms-checkbox-input-container">
                      <input
                        type="checkbox"
                        checked={termsChecked}
                        data-testid="cwc-terms-checkbox"
                        onChange={e => setTermsChecked(e.target.checked)}
                      />
                      {termsChecked && <img src={checkMark} alt="check" />}
                    </div>
                  </div>

                  <span>
                    I agree to the <TermsAndConditionsTriggerAndModal />
                  </span>
                </label>
              </div>
            ) : null}

            <div className="review-reservation-footer">
              <div>
                <button
                  className="btn btn-oval with-arrow"
                  onClick={submit}
                  disabled={
                    (agentDetails && !agentEmail) ||
                    (requiredCheckBox && !termsChecked)
                  }
                >
                  Submit
                </button>
              </div>
              <div>
                <button className="btn btn-link" onClick={cancelRequest}>
                  Cancel Request
                </button>
              </div>
            </div>
          </>
        </div>
      </>
    </div>
  )
}

export default CWCReview

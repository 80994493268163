import React from 'react'
import checkListIcon from '../../assets/icons/checklist.svg'
import { ListItem } from '../reservationReview/ReviewItemsList'

const LiftAndShiftCurrentDetails = ({ reservationData }) => {
  let stateRoomName
  if (reservationData) {
    stateRoomName = reservationData.berthedCategoryName
      ? reservationData.berthedCategoryName
      : reservationData.berthedCategoryCode
  }
  return (
    <div className="lift-shift-form-booking-info-section">
      <div className="lift-and-shift-sub-heading">
        <div className="u-icon-label lift-shift-form-section-title ">
          <img src={checkListIcon} alt=" " />
          <p className="u-bold u-brand-blue">Itinerary Details</p>
        </div>
      </div>
      <div className="lift-shift-form-current-selection ">
        {stateRoomName && (
          <ListItem
            item={{
              label: 'Category',
              value: stateRoomName
            }}
            borderBottom
          />
        )}

        <ListItem
          item={{
            label: 'Stateroom',
            value: 'Best Available*'
          }}
          subText={
            '*We will select the “Best Available” stateroom based on your booked category.'
          }
        />
      </div>
    </div>
  )
}

export default LiftAndShiftCurrentDetails

import React, { useEffect } from 'react'
import Modal from '../elements/Modal'
import { useDispatch, useSelector } from 'react-redux'
import { fetchItinerary } from '../../redux/actions/itineraries'
import LoadingScreen from '../elements/LoadingScreen'
import moment from 'moment'

import '../../styles/scss/components/itinerary-modal.scss'
import closeIcon from '../../assets/icons/close.svg'
import formats from '../../configuration/formats'
const ItineraryModal = ({
  isOpen,
  onRequestClose,
  currentItineraryDetails
}) => {
  const { id, brand, sailDate } = currentItineraryDetails
  const itinerariesData = useSelector(state => state.itineraries)

  const itineraryIsLoading = itinerariesData.isLoading

  const currentItinerary = itinerariesData.items[`${id}-${sailDate}`]
  const dispatch = useDispatch()

  const hasRequiredInfo = Boolean(
    currentItineraryDetails && id && brand && sailDate
  )

  useEffect(() => {
    if (hasRequiredInfo && !currentItinerary && !itineraryIsLoading) {
      dispatch(
        fetchItinerary({
          id,
          brand,
          sailDate
        })
      )
    }
  }, [
    itineraryIsLoading,
    currentItinerary,
    dispatch,
    id,
    brand,
    sailDate,
    hasRequiredInfo
  ])

  function renderTable(itinerary, title, className) {
    const isSailingDays = className === 'sailing'
    return (
      <>
        <h2 className="u-bold u-brand-blue u-text-center">{title}</h2>

        <div className={`itinerary-table-container ${className} u-md-up`}>
          <table className="u-table">
            <thead>
              <tr>
                <th>Day</th>
                <th style={{ width: isSailingDays ? '340px' : '440px' }}>
                  Port City
                </th>
                <th>Activity</th>
                {isSailingDays && (
                  <>
                    <th>Arrival</th>
                    <th>Departure</th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {itinerary.map((day, i) => {
                return (
                  <tr key={day.day + i}>
                    <td>
                      {moment(day.displayDate, formats.date).format(
                        formats.itineraryDisplayDate
                      )}
                    </td>
                    <td dangerouslySetInnerHTML={{ __html: day.name }} />
                    <td>{day.activity ? day.activity : day.name}</td>
                    {isSailingDays && (
                      <>
                        <td>
                          {day.arrivalTime
                            ? moment(day.arrivalTime, 'hh:mm:ss').format(
                                'h:mm a'
                              )
                            : '-'}
                        </td>
                        <td>
                          {day.departureTime
                            ? moment(day.departureTime, 'hh:mm:ss').format(
                                'h:mm a'
                              )
                            : '-'}
                        </td>
                      </>
                    )}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        <div className="u-md-max itinerary-mobile-view">
          {itinerary.map((day, i) => {
            return (
              <div
                className="u-border-bottom itinerary-mobile-day"
                key={day.day + i}
              >
                <div className="u-flex u-flex-space-between itinerary-mobile-details">
                  <div>
                    <p className="itinerary-mobile-date u-bold">
                      {' '}
                      {moment(day.displayDate, formats.date).format(
                        formats.itineraryDisplayDate
                      )}
                    </p>
                    <p dangerouslySetInnerHTML={{ __html: day.name }} />
                    <p>{day.activity ? day.activity : day.name}</p>
                  </div>
                  {isSailingDays && (
                    <div className="u-text-right u-flex-align-self-end">
                      <p className="itinerary-mobile-time">
                        Arrival:{' '}
                        {day.arrivalTime
                          ? moment(day.arrivalTime, 'hh:mm:ss').format('h:mm a')
                          : '-'}
                      </p>
                      <p className="itinerary-mobile-time">
                        Departure:{' '}
                        {day.departureTime
                          ? moment(day.departureTime, 'hh:mm:ss').format(
                              'h:mm a'
                            )
                          : '-'}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            )
          })}
        </div>
      </>
    )
  }

  return (
    <Modal onRequestClose={onRequestClose} isOpen={isOpen}>
      <div className="itinerary-modal-content">
        <button className="btn btn-hollow" onClick={onRequestClose}>
          <div className="u-flex u-flex-align-center">
            <p>Close</p>
            <img src={closeIcon} alt="Close" style={{ width: '32px' }} />
          </div>
        </button>
        {itineraryIsLoading ? (
          <LoadingScreen />
        ) : (
          <>
            {hasRequiredInfo &&
            currentItinerary &&
            !currentItinerary.hasError &&
            Object.keys(currentItinerary).length ? (
              <>
                {Boolean(
                  currentItinerary.preCruiseDays &&
                    currentItinerary.preCruiseDays.length
                ) &&
                  renderTable(
                    currentItinerary.preCruiseDays,
                    'Pre-Sailing Tour',
                    'pre-sailing'
                  )}
                {currentItinerary.sailingDays &&
                  renderTable(
                    currentItinerary.sailingDays,
                    'Sailing Itinerary',
                    'sailing'
                  )}
                {Boolean(
                  currentItinerary.postCruiseDays &&
                    currentItinerary.postCruiseDays.length
                ) &&
                  renderTable(
                    currentItinerary.postCruiseDays,
                    'Post-Sailing Tour',
                    'post-sailing'
                  )}
              </>
            ) : (
              <p>Unable to fetch itinerary</p>
            )}
          </>
        )}
      </div>
    </Modal>
  )
}

export default ItineraryModal

import brands from '../configuration/brands'
const brandCodes = Object.keys(brands)

export default function(images, files) {
  /*
   * images = image array attached to the response
   * files, optional = an array of file names in addition to stateroom and stateroom_ + r,z,c you'd like returned
   * */
  if (!images || !images.length) return []
  return images.map(image => {
    if (!image) return null

    const file = {
      file: image.file,
      width: image.width,
      height: image.height,
      sizes: {}
    }
    const sizes = image ? image.sizes : null
    // what images are actually used
    // staterooms_${brand} && staterooms
    if (sizes && sizes.staterooms) {
      file.sizes.staterooms = {
        src: sizes.staterooms,
        width: sizes['stateroom-width'],
        height: sizes['stateroom-height']
      }
    }

    for (const brandCode of brandCodes) {
      if (sizes && sizes[`stateroom_${brandCode.toLowerCase()}`]) {
        file.sizes[`stateroom_${brandCode.toLowerCase()}`] = {
          src: sizes[`stateroom_${brandCode.toLowerCase()}`],
          width: sizes[`stateroom_${brandCode.toLowerCase()}-width`],
          height: sizes[`stateroom_${brandCode.toLowerCase()}-height`]
        }
      }
    }

    if (files && files.length) {
      for (const fileName of files) {
        if (sizes && sizes[fileName]) {
          file.sizes[fileName] = {
            src: sizes[fileName],
            width: sizes[`${fileName}-width`],
            height: sizes[`${fileName}-height`]
          }
        }
      }
    }

    return file
  })
}

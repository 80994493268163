import React from 'react'
import { Formik } from 'formik'
import DatePicker from './DatePicker'
import moment from 'moment'

const ReservationForm = ({
  handleSubmit,
  initialValues,
  schema,
  shouldDisableSubmit,
  selectedProgram,
  testId,
  fields,
  disabled,
}) => (
  <Formik
    onSubmit={handleSubmit}
    initialValues={initialValues}
    validationSchema={schema}
  >
    {({
      // dirty,
      errors,
      handleChange,
      handleSubmit,
      touched,
      values,
      setFieldValue,
    }) => {
      const isSingleFieldForm = fields?.length === 1
      const disableSubmit = shouldDisableSubmit
        ? shouldDisableSubmit(values)
        : false
      return (
        <form
          data-testid={testId}
          onSubmit={handleSubmit}
          className={`${selectedProgram} ${testId} ${
            isSingleFieldForm ? 'single-input-form' : ''
          }`}
        >
          <>
            {fields.map(
              ({
                name,
                label,
                type = 'text',
                element,
                renderDisabled,
                className = '',
                options,
              }) => {
                const error = touched[name] && errors[name]
                const isDisabled = renderDisabled
                  ? renderDisabled(values)
                  : false

                if (type === 'displayElement') {
                  return (
                    <React.Fragment key={element}>{element}</React.Fragment>
                  )
                }

                const renderField = () => {
                  if (type === 'select' && options) {
                    return (
                      <select
                        name={name}
                        onChange={handleChange}
                        disabled={isDisabled}
                      >
                        <option value="" />
                        {options?.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    )
                  }
                  if (name === 'sailDate') {
                    return (
                      <DatePicker
                        date={values.sailDate ? moment(values.sailDate) : null}
                        handleDateChange={date => {
                          setFieldValue(
                            'sailDate',
                            date ? date.format('YYYY-MM-DD') : null,
                          )
                        }}
                        disabled={disabled}
                      />
                    )
                  }
                  return (
                    <input
                      name={name}
                      type={type}
                      onChange={handleChange}
                      disabled={isDisabled}
                    />
                  )
                }

                return (
                  <div
                    className={`form-input-container ${className}`}
                    key={name}
                  >
                    <label>
                      <p>{label}</p>
                      {renderField()}
                      {error && <p className="error-text">{error}</p>}
                    </label>
                  </div>
                )
              },
            )}
          </>

          <div className="button-container">
            <button
              className="btn btn-oval with-arrow"
              type="submit"
              disabled={disabled || disableSubmit}
            >
              {selectedProgram === 'obc' ? 'Start Purchase' : 'Begin Request'}
            </button>
          </div>
        </form>
      )
    }}
  </Formik>
)

export default ReservationForm

import { REQUEST_LABELS, RECEIVE_LABELS, LABELS_ERROR } from '../actions/labels'

const initialState = {
  loading: false,
  error: false,
  data: null
}

export default function(state = initialState, action) {
  switch (action.type) {
    case REQUEST_LABELS:
      return Object.assign({}, state, {
        loading: true
      })
    case RECEIVE_LABELS:
      return Object.assign({}, state, {
        loading: false,
        error: false,
        data: action.payload
      })
    case LABELS_ERROR:
      return Object.assign({}, state, {
        loading: false,
        error: true
      })
    default:
      return state
  }
}

export const selectLabelsData = state => state?.labels?.data

import React from 'react'
import PropTypes from 'prop-types'
import '../../styles/scss/components/cwc-confirmation.scss'
import { useSelector } from 'react-redux'
import { getIsSpanish } from '../../redux/reducers/activeSearchFilters'
import { getCancellationTypeData } from '../../redux/reducers/cancellationTypeData'
import CWCConfirmationParagraphs from './CWCParagraphs'
import CVCConfirmationParagraphs from './CVCParagraphs'
import ConfirmationCTAs from './ConfirmationCTAs'
import moment from 'moment'
import { selectShipsData } from '../../redux/reducers/ships'
import { selectRequestType } from '../../redux/reducers/requestType'
import { selectConfirmationData } from '../../redux/reducers/confirmationData'

const CWCConfirmation = ({ resetFlow, isAuthenticated }) => {
  const cancellationData = useSelector(state => getCancellationTypeData(state))
  const confirmationData = useSelector(selectConfirmationData)
  const cancellationCode = cancellationData ? cancellationData.code : ''
  const state = useSelector(state => state)
  const requestType = useSelector(selectRequestType)
  const sailDate = cancellationData
    ? moment(cancellationData.booking?.sailDate).format('MMM. DD, YYYY')
    : ''
  const shipCode = cancellationData ? cancellationData?.booking?.shipCode : ''
  const linkedBookings = confirmationData
    ? confirmationData?.linkedBookings
    : []
  const ships = useSelector(selectShipsData)
  const shipData = ships
    ? ships.find(ship => ship['ship_code'] === shipCode)
    : ''

  const isSpanish = getIsSpanish(state)
  const activeBrandCode = useSelector(state => state.activeSearchFilters.brand)

  function renderTitle() {
    if (activeBrandCode && activeBrandCode === 'P') {
      return 'YOU’RE ALL SET!'
    }
    return "You're All Set"
  }

  return (
    <div className="confirmation u-white-box" data-testid="cwc-confirmation">
      <div className="u-max-text-container">
        <div className="u-text-center">
          <div className="confirmation-header">
            <h1 className="u-title-x-large">{renderTitle()}</h1>
          </div>
        </div>
        {requestType === 'cvc' ? (
          <CVCConfirmationParagraphs
            isAuthenticated={isAuthenticated}
            activeBrandCode={activeBrandCode}
            cancellationCode={cancellationCode}
            bookingID={cancellationData ? cancellationData?.booking?.id : ''}
            sailDate={sailDate}
            shipCode={shipData ? shipData?.name : ''}
            linkedBookings={linkedBookings}
          />
        ) : (
          <CWCConfirmationParagraphs
            isAuthenticated={isAuthenticated}
            isSpanish={isSpanish}
            activeBrandCode={activeBrandCode}
            cancellationCode={cancellationCode}
            bookingID={cancellationData ? cancellationData.id : ''}
          />
        )}
      </div>
      <ConfirmationCTAs
        isSpanish={isSpanish}
        resetFlow={resetFlow}
        buttonText={
          requestType === 'cvc'
            ? 'SUBMIT ANOTHER COVID-19 CANCELLATION REQUEST'
            : 'Submit Another Booking'
        }
      />
    </div>
  )
}

CWCConfirmation.defaultProps = {
  isAuthenticated: false,
}

CWCConfirmation.propTypes = {
  resetFlow: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
}

export default CWCConfirmation

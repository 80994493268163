import {
  FETCH_CANCELLATION_TYPE,
  RECEIVE_CANCELLATION_TYPE,
  CANCELLATION_TYPE_ERROR,
  RESET_BOOKING_FLOW,
} from '../actions/fetchCancellationType'

const initialState = {
  loading: false,
  error: null,
  data: null,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_CANCELLATION_TYPE:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case RECEIVE_CANCELLATION_TYPE:
      return {
        loading: false,
        error: null,
        data: action.payload,
      }
    case CANCELLATION_TYPE_ERROR:
      return {
        loading: false,
        error: action.payload,
        data: null,
      }
    case RESET_BOOKING_FLOW:
      return initialState
    default:
      return state
  }
}

export const getCancellationTypeData = state => state.cancellationType.data

export const getFirstGuest = state => {
  const cancellationTypeData = getCancellationTypeData(state)

  const reservationData = cancellationTypeData
    ? cancellationTypeData.booking
    : null

  return (
    reservationData &&
    reservationData.guests &&
    reservationData.guests.length &&
    reservationData.guests[0]
  )
}

export const cancellationTypeDataIsLoading = state =>
  state.cancellationType.loading

// https://jira.verbinteractive.com/browse/RCL202-529
export const isBpgReviewOnly = state => {
  const cancellationData = getCancellationTypeData(state)
  const requestTypeCode = state.requestType
  return (
    requestTypeCode === 'bpg' &&
    cancellationData?.withinFinalPayment?.toLowerCase() === 'no'
  )
}

import api, { sso as fetchSSO } from '../../api/api'
export const FETCH_SSO_DATA = 'FETCH_SSO_DATA'
export const RECEIVE_SSO_DATA = 'RECEIVE_SSO_DATA'
export const SSO_DATA_ERROR = 'SSO_DATA_ERROR'

export const fetchSso = queryParams => dispatch => {
  const { sso, z, cptok } = queryParams

  const agentId = cptok || z || sso
  if (agentId) {
    dispatch({
      type: FETCH_SSO_DATA,
    })
    return fetchSSO(`${api.sso}${agentId}?renewData=${new Date().getTime()}`)
      .then(res => {
        if (res.data && res.data.data) {
          dispatch({
            type: RECEIVE_SSO_DATA,
            payload: res.data.data,
          })
          return res.data.data
        } else {
          dispatch({
            type: SSO_DATA_ERROR,
            payload: 'Something Went Wrong',
          })
          return null
        }
      })
      .catch(err => {
        dispatch({
          type: SSO_DATA_ERROR,
          payload: err.message,
        })
      })
  } else {
    return Promise.resolve(null)
  }
}

import { capitalize } from 'lodash'

const formatGuestName = guest => {
  const nameParts = [
    guest.givenName || '',
    guest.middleName || '',
    guest.surname || '',
  ]
  return nameParts
    .filter(name => !!name)
    .map(name => capitalize(name))
    .join(' ')
}

export default formatGuestName

import React from 'react'

import CPLogo from '../../assets/images/CP.jpeg'
import '../../styles/scss/components/invalid-path-modal.scss'

const InvalidPathModal = () => {
  return (
    <div className="invalid-path-modal">
      <div className="partners">
        <p className="u-margin-bottom-32">
          Please log-in to CruisingPower.com, and click on ‘Partner Express’
          from our main navigation, under the ‘Brand Programs & News’ section.
        </p>
        <p className="u-bold u-margin-bottom-32 title">For Travel Advisors:</p>
        <a href={process.env.REACT_APP_CRUISING_POWER_LOGIN}>
          <img src={CPLogo} alt={'www.cruisingpower.com'} />
        </a>
      </div>
    </div>
  )
}

export default InvalidPathModal

import { getCancellationType, getLiftAndShiftOptions } from '../../api/api'
import { getAgencyId, getAgentUserId } from '../reducers/ssoData'
import {
  getActiveBrandData,
  getIsSpanish,
} from '../reducers/activeSearchFilters'
import { getSelectedProgramData } from './setSelectedProgramData'

export const FETCH_CANCELLATION_TYPE = 'FETCH_CANCELLATION_TYPE'
export const RECEIVE_CANCELLATION_TYPE = 'RECEIVE_CANCELLATION_TYPE'
export const CANCELLATION_TYPE_ERROR = 'CANCELLATION_TYPE_ERROR'

export const RESET_BOOKING_FLOW = 'RESET_BOOKING_FLOW'

export const SET_REQUEST_DATA = 'SET_REQUEST_DATA'

// this is misleading. initially we fetched the cancellation type first
// then the reservation data. That got moved into one call.
export const fetchCancellationType = (formValues, formType) => (
  dispatch,
  getState,
) => {
  dispatch({
    type: FETCH_CANCELLATION_TYPE,
  })
  const state = getState()
  let url, type, requestType, id
  const fetchQuery = new URLSearchParams()
  const sessionId = state.sessionId
  fetchQuery.append('sessionId', sessionId)

  const selectedProgramData = getSelectedProgramData(state)

  const activeBrand = getActiveBrandData(state)

  const isPullmantur = activeBrand && activeBrand['brand_code'] === 'P'

  if (formType === 'direct') {
    id = formValues.bookingID
    // Fetch Query string method
    fetchQuery.append('sailDate', formValues.sailDate)
    fetchQuery.append('shipCode', formValues.ship)
    fetchQuery.append('surname', formValues.lastName)
    fetchQuery.append('type', '14')
    type = '14'
    requestType = 'consumer'
  } else {
    const agencyId = getAgencyId(state)
    fetchQuery.append('agencyId', agencyId)
    const userId = getAgentUserId(state)

    if (userId) {
      fetchQuery.append('userId', userId)
    }

    if (formType === 'bookingID') {
      fetchQuery.append('type', '14')
      id = formValues.bookingID
      type = '14'
      requestType = 'individual'
    } else if (formType === 'shellID') {
      fetchQuery.append('type', '9')
      id = formValues.shellID
      type = '9'
      requestType = 'shell'
    }
  }

  let programCode = selectedProgramData ? selectedProgramData.programCode : null

  if (!programCode && isPullmantur) {
    programCode = 'MQ'
  }

  if (programCode) {
    fetchQuery.append('programCode', programCode)
  }

  fetchQuery.append('lang', getIsSpanish(state) ? 'es-es' : 'en-us')

  url = `${id}?${fetchQuery.toString()}`

  dispatch({
    type: SET_REQUEST_DATA,
    payload: {
      requestType,
      type,
      formValues,
      formType,
    },
  })

  return getCancellationType(url)
}

export function fetchLiftAndShiftOptions(id, sessionId, type) {
  const fetchQuery = new URLSearchParams()
  fetchQuery.append('sessionId', sessionId)
  fetchQuery.append('type', type)
  return getLiftAndShiftOptions(id, fetchQuery.toString())
}

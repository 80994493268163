import React from 'react'

export default function CWCConfirmationParagraphs({
  bookingID,
  isSpanish,
  activeBrandCode,
  cancellationCode,
  isAuthenticated,
}) {
  if (isSpanish) {
    return (
      <>
        <p className="confirmation-disclaimers">
          Se ha enviado la información para obtener un reembolso de RCL
          correspondiente a la{' '}
          <strong>identificación de la reserva: {bookingID}.&nbsp;</strong>
        </p>
        <p className="confirmation-disclaimers">
          No es necesario que hagas nada más. En breve recibirás una copia del
          correo electrónico como confirmación de la recepción.
        </p>
        <p className="confirmation-disclaimers">
          Gracias por tu apoyo y fidelidad.
        </p>
      </>
    )
  }

  if (activeBrandCode === 'P') {
    return (
      <>
        <p className="confirmation-disclaimers">
          Your RCL Refund information has been submitted for{' '}
          <strong>Booking ID: {bookingID}.&nbsp;</strong>
        </p>
        <p className="confirmation-disclaimers">
          No additional action is needed on your part. You’ll receive an email
          copy of your request as a confirmation of receipt shortly.
        </p>
        <p className="confirmation-disclaimers">
          Thank you for your support and loyalty.
        </p>
      </>
    )
  }

  if (isAuthenticated) {
    // now only VB or GQ
    return cancellationCode === 'VB' ? (
      <>
        <p className="confirmation-disclaimers">
          Your request has been submitted for{' '}
          <strong>
            Booking ID:
            {bookingID}.&nbsp;
          </strong>
          No additional action is needed on your part. You'll receive an
          e-mailed copy of your request as a confirmation of receipt shortly.
        </p>
        <p className="confirmation-disclaimers">
          After confirming that the reservation is eligible for Cruise With
          Confidence, we will issue and send the Future Cruise Certificate
          within 30 days.
        </p>
        <p className="confirmation-disclaimers">
          We look forward to welcoming your clients onboard in the near future!
          Thank you for your support and loyalty.
        </p>
      </>
    ) : (
      <>
        <p className="confirmation-disclaimers">
          Your request has been submitted for{' '}
          <strong>Booking ID: {bookingID}.&nbsp;</strong>
          No additional action is needed on your part. You'll receive an
          e-mailed copy of your request as a confirmation of receipt shortly.
        </p>
        <p className="confirmation-disclaimers">
          We will go ahead and inactivate your clients’ 125% Future Cruise
          Certificates and provide a refund instead. If your clients’ sailing
          did not qualify for the 125% FCC offer, no actions will be taken.
        </p>
        <p className="confirmation-disclaimers">
          Your clients can expect to see their refund to the original form of
          payment on file within 30-45 days from your request date.
        </p>
        <p className="confirmation-disclaimers">
          We look forward to welcoming your clients onboard in the near future!
          Thank you for your support and loyalty.
        </p>
      </>
    )
  }

  if (activeBrandCode === 'C') {
    return cancellationCode === 'VB' ? (
      <>
        <p className="confirmation-disclaimers">
          Your request has been submitted for{' '}
          <strong>
            Booking ID:
            {bookingID}
          </strong>
          {'. '}No additional action is needed on your part. You’ll receive an
          email copy of your request as a confirmation of receipt shortly.
        </p>
        <p className="confirmation-disclaimers">
          After confirming that the reservation is eligible for Cruise with
          Confidence, we will issue and send the Cruise Credit within 30 days.
        </p>
        <p className="confirmation-disclaimers">
          We look forward to welcoming you onboard in the near future. Thank you
          for your support and loyalty.
        </p>
      </>
    ) : (
      <>
        <p className="confirmation-disclaimers">
          Your request has been submitted for{' '}
          <strong>Booking ID: {bookingID}</strong>.&nbsp; No additional action
          is needed on your part. You’ll receive an email copy of your request
          as a confirmation of receipt shortly.
        </p>
        <p className="confirmation-disclaimers">
          We will go ahead and inactivate your 125% Cruise Credits and provide a
          refund instead. If your sailing did not qualify for the 125% Cruise
          Credit offer, no actions will be taken.
        </p>
        <p className="confirmation-disclaimers">
          You can expect to see your refund to the original form of payment on
          file within 30-45 days from your request date.
        </p>
        <p className="confirmation-disclaimers">
          We look forward to welcoming you onboard in the near future. Thank you
          for your support and loyalty.
        </p>
      </>
    )
  }

  // only two consumer VB and GQ left
  return cancellationCode === 'VB' ? (
    <>
      <p className="confirmation-disclaimers">
        Your request has been submitted for{' '}
        <strong>Booking ID: {bookingID}.&nbsp;</strong>
        No additional action is needed on your part. You'll receive an e-mailed
        copy of your request as a confirmation of receipt shortly.
      </p>
      <p className="confirmation-disclaimers">
        After confirming that the reservation is eligible for Cruise With
        Confidence, we will issue and send the Future Cruise Certificate within
        30 days.
      </p>
      <p className="confirmation-disclaimers">
        We look forward to welcoming you onboard in the near future! Thank you
        for your support and loyalty.
      </p>
    </>
  ) : (
    <>
      <p className="confirmation-disclaimers">
        Your request has been submitted for{' '}
        <strong>Booking ID: {bookingID}.&nbsp;</strong>
        No additional action is needed on your part. You'll receive an e-mailed
        copy of your request as a confirmation of receipt shortly.
      </p>
      <p className="confirmation-disclaimers">
        We will go ahead and inactivate your 125% Future Cruise Certificates and
        provide a refund instead. If your sailing did not qualify for the 125%
        FCC offer, no actions will be taken.
      </p>
      <p className="confirmation-disclaimers">
        You can expect to see your refund to the original form of payment on
        file within 30-45 days from your request date.
      </p>
      <p className="confirmation-disclaimers">
        We look forward to welcoming you onboard in the near future! Thank you
        for your support and loyalty.
      </p>
    </>
  )
}

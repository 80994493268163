import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CWCReview from '../../components/reservationReview/CWCReview'
import BPGReview from '../../components/reservationReview/BPGReview'
import LiftReview from '../../components/reservationReview/LiftReview'
import CovidCancellationReview from '../../components/reservationReview/CovidCancellationReview'
import routes from '../../configuration/routes'
import { getAgentDetails } from '../../redux/reducers/ssoData'
import ErrorModal from '../../components/modals/ErrorModal'
import {
  CANCELLATION_ERROR,
  cancelReservation,
  RECEIVE_CANCELLATION_CONFIRMATION,
} from '../../redux/actions/cancelResevation'
import { RESET_BOOKING_FLOW } from '../../redux/actions/fetchCancellationType'
import LoadingScreen from '../../components/elements/LoadingScreen'

import dispatchAnalyticsEvent from '../../redux/actions/dispatchAnalyticsEvent'
import FCCReview from '../../components/reservationReview/FCCReview'

const ReviewReservationContainer = ({
  handleRouteChange,
  cancellationData,
  reservationData,
  shipData,
  brandInfo,
  isAuthenticated,
  activeBrandCode,
  resetFlow,
}) => {
  const isLoading = useSelector(state => state.confirmationData.loading)
  const selectedRequestType = useSelector(state => state.requestType)
  const agentDetails = useSelector(state => getAgentDetails(state))

  // this for lift and shift, the editable agent email is on a different step
  // for that program.
  const editedAgentEmail = useSelector(state =>
    state.editedAgentDetails && state.editedAgentDetails.email
      ? state.editedAgentDetails.email
      : null,
  )

  const [agentEmail, setAgentEmail] = useState(
    editedAgentEmail
      ? editedAgentEmail
      : agentDetails && agentDetails.email
      ? agentDetails.email
      : null,
  )
  const [agentEmailError, setAgentEmailError] = useState(false)
  const [errorModalIsOpen, setErrorModalIsOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const dispatch = useDispatch()

  const liftAndShiftSelections = useSelector(
    state => state.liftAndShiftSelections,
  )

  useEffect(() => {
    if (!Boolean(selectedRequestType) && activeBrandCode !== 'P') {
      handleRouteChange(routes.cancellationForm)
    }
    // this is analytics only, hence the // eslint-disable-line react-hooks/exhaustive-deps
    dispatch(dispatchAnalyticsEvent('cancellationFormStepTwo'))

    const formName = isAuthenticated
      ? 'agentCancellationFormStepTwo'
      : 'consumerCancellationFormStepTwo'

    dispatch(dispatchAnalyticsEvent(formName))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const submit = () => {
    if (agentDetails && agentEmail) {
      //validate email
      const regex = /\S+@\S+\.\S+/
      // eslint-disable-next-line
      if (!regex.test(`${agentEmail}`)) {
        return setAgentEmailError(true)
      }
    }

    dispatch(cancelReservation(agentEmail))
      .then(res => {
        if (res && res.data && res.data.data) {
          dispatch({
            type: RECEIVE_CANCELLATION_CONFIRMATION,
            payload: res.data.data,
          })
          handleRouteChange(routes.confirmation)
        } else {
          const errorMessage =
            res && res.data && res.data.error
              ? res.data.error
              : { 1: 'Something Went Wrong' }

          setErrorMessage(errorMessage)
          setErrorModalIsOpen(true)

          dispatch({
            type: CANCELLATION_ERROR,
          })
        }
      })
      .catch(() => {
        setErrorMessage({ 1: 'Something Went Wrong' })
        setErrorModalIsOpen(true)
      })
  }

  const cancelRequest = () => {
    dispatch({ type: RESET_BOOKING_FLOW })
    handleRouteChange(routes.cancellationForm)
  }

  function renderView() {
    if (isLoading) {
      return (
        <div
          className="u-background-white-transparent"
          style={{ padding: '64px 0' }}
        >
          <LoadingScreen />
        </div>
      )
    }

    switch (selectedRequestType) {
      case 'cvc':
        return (
          <CovidCancellationReview
            resetFlow={resetFlow}
            reservationData={
              reservationData
                ? {
                    ...reservationData,
                    linkedBookings: cancellationData?.linkedBookings || [],
                  }
                : {}
            }
            onSubmitSuccess={() => {
              handleRouteChange(routes.confirmation)
            }}
          />
        )
      case 'bpg':
        return (
          <BPGReview
            handleRouteChange={handleRouteChange}
            reservationData={reservationData}
            cancellationData={cancellationData}
            agentEmail={agentEmail}
            setAgentEmail={setAgentEmail}
            agentEmailError={agentEmailError}
            setAgentEmailError={setAgentEmailError}
            submit={submit}
            brandInfo={brandInfo}
          />
        )
      case 'las':
        return (
          <LiftReview
            handleRouteChange={handleRouteChange}
            editedAgentEmail={editedAgentEmail}
            reservationData={reservationData}
            cancellationData={cancellationData}
            brandInfo={brandInfo}
            shipData={shipData}
            submit={submit}
            cancelRequest={cancelRequest}
            isAuthenticated={isAuthenticated}
            liftAndShiftSelections={liftAndShiftSelections}
          />
        )
      case 'fcc':
        return (
          <FCCReview
            data={cancellationData}
            resetFlow={resetFlow}
            agentEmail={agentEmail}
            onSubmitSuccess={() => {
              handleRouteChange(routes.confirmation)
            }}
          />
        )
      case 'cwc':
      case 'cs':
      default:
        return (
          <CWCReview
            agentDetails={agentDetails}
            agentEmail={agentEmail}
            agentEmailError={agentEmailError}
            cancelRequest={cancelRequest}
            cancellationData={cancellationData}
            isAuthenticated={isAuthenticated}
            reservationData={reservationData}
            setAgentEmail={setAgentEmail}
            setAgentEmailError={setAgentEmailError}
            submit={submit}
          />
        )
    }
  }

  return (
    <>
      {renderView()}
      {errorModalIsOpen && (
        <ErrorModal
          onRequestClose={() => setErrorModalIsOpen(false)}
          isOpen={errorModalIsOpen}
          message={errorMessage}
        />
      )}
    </>
  )
}

export default ReviewReservationContainer
